import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';

const localeMap = {
  en: { locale: enLocale, format: 'MM/dd/yyyy' },
  fr: { locale: frLocale, format: 'dd/MM/yyyy' },
};

function getLocaleFromI18n(i18n) {
  const language = i18n.language.split('-')[0];
  return localeMap[language] || localeMap['en'];
}

export function DatePicker(props) {
  const { t, i18n } = useTranslation();
  const { locale, format } = getLocaleFromI18n(i18n);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      <KeyboardDatePicker
        format={format}
        invalidDateMessage={t('date.invalidDateMessage')}
        minDateMessage={t('date.minDateMessage')}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePicker.propTypes = {
  ...KeyboardDatePicker.propTypes
};
