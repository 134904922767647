import { createMuiTheme } from '@material-ui/core';
import { frFR } from '@material-ui/core/locale';
import { grey, red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: red[500],
    },
    secondary: {
      main: red[900],
    },
    text: {
      primary: grey[700],
    },
  },
}, frFR);

export default theme;
