import firebase from 'firebase/app';
import 'firebase/auth';
import isEmail from 'validator/lib/isEmail';
import { normalizeEmailAddress } from '../utils/formUtils';

export function signUp(profile) {
  const emailNormalized = normalizeEmailAddress(profile.email);

  if (!isEmail(emailNormalized)) {
    throw new Error('Invalid email address');
  }

  profile.created = profile.updated = new Date();

  return firebase.firestore().collection('newUsers')
    .doc(emailNormalized).set({...profile, emailNormalized});
}

export async function isNewEmail(email) {
  try {
    const emailNormalized = normalizeEmailAddress(email);
    const result = await firebase.auth().fetchSignInMethodsForEmail(emailNormalized);
    return result.length === 0;
  } catch (e) {
    console.error(e);
    return false;
  }
}
