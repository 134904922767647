import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export function useSetting(name) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    firebase.firestore().collection('settings').doc(name).get()
      .then(doc => {
        if (doc.exists) {
          setData(doc.data());
        }
      })
      .catch(error => setError(error))
      .finally(_ => setLoading(false));
  }, [name]);

  const save = () => {
    return firebase.firestore().collection('settings').doc(name).update(data);
  };

  return {
    loading,
    data,
    error,
    setData,
    save,
  }
}

export function setting(WrappedComponent, setting) {
  return function(props) {
    let selectors = {};
    if (setting) {
      const { t } = useTranslation('admin');
      const { loading, data, error, setData, save } = useSetting(setting);
      const { enqueueSnackbar } = useSnackbar();
      const [saving, setSaving] = React.useState(false);
    
      const onSave = () => {
        setSaving(true);
        save()
          .then(() => enqueueSnackbar(t('action.saveSuccess'), { variant: "success" }))
          .catch(error => enqueueSnackbar(error.message, { variant: "error" }))
          .finally(() => setSaving(false));
      };

      selectors = { loading, data, error, setData, saving, onSave };
    }

    return <WrappedComponent {...selectors} {...props}/>
  }
}
