import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
}));

function DynamicList({ Component, values, onChange, onNewRow, onDeleteRow, max }) {
  const classes = useStyles();
  
  const handleChange = (index) => (value) => {
    onChange(value, index);
  };

  return (
    <div className={classes.container}>
      { values.map((value, index) => (
        <span key={index} className={classes.row}>
          <Component value={value} onChange={handleChange(index)} />
          <IconButton color="primary" onClick={() => onDeleteRow(index)}><RemoveCircleOutline /></IconButton>
        </span>
      )) }
      { (!max || values.length < max) &&
        <IconButton color="primary" onClick={onNewRow}><AddCircleOutline /></IconButton>
      }
    </div>
  );
}

DynamicList.propTypes = {
  Component: PropTypes.any.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onNewRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
  max: PropTypes.number,
};

export default DynamicList;
