import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, Typography, TextField, Slider } from '@material-ui/core';
import CheckboxGroup from '../commons/CheckboxGroup';
import { CollectionTags, CollectionSelect } from '../Collection';
import RadioboxGroup from '../commons/RadioboxGroup';
import Fade from 'react-reveal/Fade';
import { DatePicker } from '../commons/DatePicker';
import { useForm, connectToForm } from '../../contexts/FormContext';
import { useTranslation } from 'react-i18next';
import ProposeInput from './ProposeInput';
import { sortByName } from '../../utils/formUtils';

const useStyles = makeStyles((theme) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: theme.spacing(2, 2, 6),
    padding: theme.spacing(6, 0),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3),
  },
  checkboxes: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(3)
  },
  numberContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  number: {
    maxWidth: '50px',
    paddingRight: '10px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
}));

export const FormSection = React.memo(({ id, title, children, error }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const helperText = error ? t('form.required') : undefined;

  return (
    <Fade bottom fraction={0.5}>
      <Box id={id} className={classes.section}>
        <div className={classes.header}>
          <Typography variant="h6" gutterBottom color={error ? 'error' : 'textPrimary'}>{title}</Typography>
          { helperText && <Typography variant="caption" color={error ? 'error' : 'textPrimary'}>{helperText}</Typography> }
        </div>
        { children }
      </Box>
    </Fade>
  );
});

FormSection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  error: PropTypes.bool,
};

export function Visible({ children, when }) {
  const { state } = useForm();
  const hidden = when(state.form);

  return (
    <React.Fragment>
      {hidden ? children : undefined}
    </React.Fragment>
  );
}

export const TextInput = connectToForm(React.memo(({ name, label, multiline, type, inputProps, max, rows, ...others}) => {
  const { value, error, dispatch } = others;
  
  const handleChange = (event) => {
    const text = event.target.value;
    if (!max || text.length <= max) {
      dispatch({ type: 'change', value: text, name });
    }
  };

  return (
    <FormSection id={name} error={error} {...others}>
      <TextField
        variant="outlined"
        type={type}
        label={label}
        value={value}
        fullWidth
        onChange={handleChange}
        multiline={multiline}
        rows={rows}
        {...inputProps}
      />
    </FormSection>
  );
}));

export const NumberInput = connectToForm(React.memo(({ name, suffix, min, max, inputProps, ...others }) => {
  const classes = useStyles();
  const { value, error, dispatch } = others;
  
  const handleChange = (event) => {
    const n = event.target.value;
    if ((min === undefined || n >= min) && (max === undefined || n <= max)) {
      dispatch({ type: 'change', value: n, name });
    }
  };

  return (
    <FormSection id={name} error={error} {...others}>
      <div className={classes.numberContainer}>
        <TextField
          value={value}
          onChange={handleChange}
          type="number"
          size="small"
          className={classes.number}
          {...inputProps}
        />
        <Typography>{suffix}</Typography>
      </div>
    </FormSection>
  );
}));

NumberInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  inputProps: PropTypes.shape(TextField.propTypes),
};

export const TagInput = connectToForm(React.memo(({ name, collection, inputProps, propose, tooltip, ...others }) => {
  const classes = useStyles();
  const { value, error, dispatch } = others;
  
  const handleChange = (value) => {
    dispatch({ type: 'change', value, name });
  };

  return (
    <FormSection id={name} error={error} {...others}>
      <div className={classes.rowContainer}>
        <CollectionTags
          id={`${collection}-collection`}
          placeholder
          collection={collection}
          sort={sortByName}
          value={value}
          onChange={handleChange}
          error={error}
          moreData={propose && propose.name}
          {...inputProps}
        />
        { propose && <ProposeInput id={`${collection}-propose`} {...propose} /> }
      </div>
    </FormSection>
  );
}));

TagInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  propose: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired
  }),
  inputProps: PropTypes.shape(CollectionTags.propTypes),
};

export const CheckboxInput = connectToForm(React.memo(({ name, getFields, inputProps, ...others }) => {
  const classes = useStyles();
  const fields = React.useMemo(getFields, [getFields]);
  const { value, error, dispatch } = others;
  
  const handleChange = (value) => {
    dispatch({ type: 'change', value, name });
  };

  return (
    <FormSection id={name} error={error} {...others}>
      <CheckboxGroup
        fields={fields}
        className={classes.checkboxes}
        value={value}
        onChange={handleChange}
        error={error}
        {...inputProps}
      />
    </FormSection>
  );
}));

CheckboxInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  getFields: PropTypes.func.isRequired,
  inputProps: PropTypes.shape(CheckboxGroup.propTypes),
};

export const RadioInput = connectToForm(React.memo(({ name, getFields, inputProps, onChange, ...others }) => {
  const classes = useStyles();
  const fields = React.useMemo(getFields, [getFields]);
  const { value, error, dispatch } = others;

  const handleChange = (value) => {
    dispatch({ type: 'change', value, name });
    if (onChange) {
      onChange(value, dispatch);
    }
  };

  return (
    <FormSection id={name} error={error} {...others}>
      <RadioboxGroup
        fields={fields}
        className={classes.checkboxes}
        onChange={handleChange}
        value={value}
        error={error}
        {...inputProps}
      />
    </FormSection>
  );
}));

RadioInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  getFields: PropTypes.func.isRequired,
  inputProps: PropTypes.shape(RadioboxGroup.propTypes),
};

export const SelectInput = connectToForm(React.memo(({ name, collection, inputProps, label, ...others }) => {
  const { value, error, dispatch } = others;
  
  const handleChange = (value) => {
    dispatch({ type: 'change', value, name });
  };

  return (
    <FormSection id={name} error={error} {...others}>
      <CollectionSelect
        value={value}
        onChange={handleChange}
        error={error}
        collection={collection}
        sort={sortByName}
        fullWidth
        label={label}
        {...inputProps}
      />
    </FormSection>
  );
}));

SelectInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  inputProps: PropTypes.any,
};

function getLegend(legend, marks, value) {
  if (legend) {
    if (typeof value === 'object' && value.length === 2) {
      const legends = [];
      value.forEach((val) => {
        const text = getLegend(legend, marks, val);
        if (text && (legends.length === 0 || text !== legends[legends.length - 1])) {
          legends.push(text);
        }
      });
      return legends.join(' - ');
    } else {
      let item;
      switch (legend) {
        case 'lower':
          item = marks.find((mark, index) =>
            mark.legend && (
              (index === 0 && value === mark.value) ||
              (index === (marks.length - 1) && value === mark.value) ||
              (value < mark.value)
            )
          );
          break ;
        case 'exact':
        default:
          item = marks.find(mark => mark.legend && mark.value === value);
          break ;
      }
      if (item) {
        return item.legend;
      }
    }
  }
}

export const SliderInput = connectToForm(React.memo(({ name, legend, marks, min, max, inputProps, ...others }) => {
  const { value, error, dispatch } = others;
  const [internalValue, setInternalValue] = React.useState(value);
  
  const handleChange = (event, newValue) => {
    dispatch({ type: 'change', value: newValue, name });
  };

  let legendText;

  if (marks) {
    if (min === undefined) {
      min = marks[0].value;
    }
    if (max === undefined) {
      max = marks[marks.length - 1].value;
    }
    legendText = getLegend(legend, marks, internalValue);
  }

  return (
    <FormSection id={name} error={error} {...others}>
      <Typography align="center" variant="body1" color="primary">{legendText}</Typography>
      <Slider
        value={internalValue}
        marks={marks}
        onChange={(e, v) => setInternalValue(v)}
        onChangeCommitted={handleChange}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        {...inputProps}
      />
    </FormSection>
  );
}));

SliderInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string.isRequired,
  legend: PropTypes.oneOf(['exact', 'lower']),
  inputProps: PropTypes.shape(Slider.propTypes),
};

export const DateInput = connectToForm(React.memo(({ name, inputProps, label, disablePast, ...others }) => {
  const { value, error, dispatch } = others;
  
  const handleChange = (value) => {
    dispatch({ type: 'change', value, name });
  };

  return (
    <FormSection id={name} error={error} {...others}>
      <DatePicker
        autoOk
        disablePast={disablePast}
        variant="inline"
        label={label}
        value={value}
        onChange={handleChange}
      />
    </FormSection>
  );
}));

DateInput.propTypes = {
  title: PropTypes.string,
  error: PropTypes.bool,
  name: PropTypes.string,
  inputProps: PropTypes.any,
  disablePast: PropTypes.bool,
};

// export function PlaceInput({ value, onChange, hidden, title, label, error, ...others }) {
//   const handlePlace = (city, id) => {
//     console.log('handlePlace', city, ' id =', id);
//     onChange(id ? city : '');
//   };

//   return (
//     <FormSection title={title} error={error} hidden={hidden}>
//       <InputPlace label={label} value={value} onChange={handlePlace} error={error} {...others} />
//     </FormSection>
//   );
// }
// PlaceInput.propTypes = {
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   hidden: PropTypes.bool,
//   title: PropTypes.string,
//   label: PropTypes.string,
//   error: PropTypes.bool,
//   helperText: PropTypes.string,
// };
