import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from './ContactForm';
import contactImage from '../resources/contact.jpg';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '0 !important',
    justifyContent: 'flex-start',
    flexDirection: 'row !important',
  },
  titleContainer: {
    flexGrow: '1',
    color: theme.palette.common.white,
    width: '400px',
    height: '360px',
    whiteSpace: 'pre',
    padding: theme.spacing(3),
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), no-repeat center/cover url(${contactImage})`,
  },
  title: {
    width: '100%',
    textAlign: 'center',
    fontSize: '2em',
    fontWeight: 'bold',
    margin: theme.spacing(4, 0, 6),
    whiteSpace: 'break-spaces',
  },
  contact: {
    flexGrow: '1',
    width: '400px',
    padding: theme.spacing(6, 4, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
}));

function ContactSection({ className, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation('home');
  
  return (
    <section className={clsx([classes.root, className])} {...props}>
      <div className={classes.titleContainer}>
        <Typography className={classes.title} variant="h2" gutterBottom>
          {t('contact.text')}
        </Typography>
      </div>
      <ContactForm className={classes.contact} />
    </section>
  );
}

export default ContactSection;
