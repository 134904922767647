import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, isWidthDown, List, ListItem, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography, withWidth } from '@material-ui/core';
import { useUser } from '../../../api/users';
import { useTranslation } from 'react-i18next';
import { computeMatchDetails, findCommonIndexes, formatData, useMatchColorStyles } from './matchUtils';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

const ROWS = [
  'contract',
  'recruiter',
  'roles',
  'place',
  'sector',
  'start',
  'duration',
  'jobType',
  'experience',
  'degree',
  'expert',
  'proficient',
  'novice',
  'softs',
  'tools',
  'languages',
  'lastJobs',
  'salary',
  'adr',
  'company',
  'freeText',
  'linkedIn',
  'website'
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'auto',
  },
  loading: {
    margin: theme.spacing(5, 'calc(50% - 20px)'),
  },
  cell: {
    tableLayout: 'fixed',
    width: '50%',
    overflowWrap: 'anywhere',
    verticalAlign: 'top'
  },
  dense: {
    padding: 0,
  }
}));

function alignToJustify(align) {
  switch (align) {
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
    case 'left':
      return 'flex-start';
    default:
      return null;
  }
}

function AsyncValue({ name, data, t, highlight, highlightClass, align }) {
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const doSetValue = val => val instanceof Array ? setValue(val) : setValue([val]);
    const result = formatData(name, data, t);
    if (result instanceof Promise) {
      setLoading(true);
      result.then(doSetValue).finally(() => setLoading(false))
    } else {
      doSetValue(result);
    }
  }, [name, data, t]);

  if (loading) {
    return <Skeleton />;
  }
  if (!value && value !== 0) {
    return null;
  }

  const style = align ? { textAlign: align, justifyContent: alignToJustify(align) } : null;

  return (
    <List dense disablePadding>
      {value.map((item, index) =>
        <ListItem
          key={index}
          className={highlight?.includes(index) ? highlightClass : null}
          style={style}
        >
          {item}
        </ListItem>
      )}
    </List>
  );
}

const MatchDetailsContent = withWidth()(({ candidate, recruiter, details, width }) => {
  const { t } = useTranslation('admin');
  const matchClasses = useMatchColorStyles();
  const classes = useStyles();
  const isSmall = isWidthDown('xs', width);
  const cellClassName = clsx([classes.cell, isSmall && classes.dense]);

  return (
    <Table size={isSmall ? "small" : "medium"}>
      <TableHead>
        <TableRow>
          <TableCell align="right" className={cellClassName}>
            <div>{t('candidate')}</div>
            <Typography variant="h6">{`${candidate.firstname} ${candidate.lastname}`}</Typography>
          </TableCell>
          <TableCell />
          <TableCell align="left" className={cellClassName}>
            <div>{t('recruiter')}</div>
            <Typography variant="h6">{`${recruiter.firstname} ${recruiter.lastname}`}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {details && ROWS.map(name => {
          const value = details[name];
          const [candidateIndexes, recruiterIndexes] = findCommonIndexes(name, candidate, recruiter);
          return (
            <TableRow key={name}>
              <TableCell align="right" className={cellClassName}>
                <AsyncValue
                  name={name}
                  data={candidate}
                  t={t}
                  highlight={candidateIndexes}
                  highlightClass={matchClasses.fullmatch_text}
                  align="right"
                />
              </TableCell>
              <TableCell align="center" variant="head" className={matchClasses.coloredText(value)}>
                {t(`match.${name}`)}
              </TableCell>
              <TableCell className={cellClassName}>
                <AsyncValue
                  name={name}
                  data={recruiter}
                  t={t}
                  highlight={recruiterIndexes}
                  highlightClass={matchClasses.fullmatch_text}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
});

function MatchDetails({ match }) {
  const classes = useStyles();
  const candidate = useUser({ uid: match?.candidate, cache: true });
  const recruiter = useUser({ uid: match?.recruiter, job: match?.job, cache: true });
  const details = useMemo(() => {
    if (match && match.context && match.profile) {
      const result = {};
      [...computeMatchDetails(match.context), ...computeMatchDetails(match.profile)]
        .forEach(item => { result[item.name] = item.value; });
      return result;
    }
    return null;
  }, [match]);

  function renderContent() {
    if (candidate.loading || recruiter.loading) {
      return <CircularProgress className={classes.loading} />;
    } else if (candidate.error) {
      return candidate.error;
    } else if (recruiter.error) {
      return recruiter.error;
    }
    return <MatchDetailsContent
      candidate={candidate.user}
      recruiter={recruiter.user}
      details={details}
    />;
  }

  return (
    <div className={classes.root}>
      {renderContent()}
    </div>
  );
}

export default MatchDetails;
