import React from 'react';
import { BottomNavigation, BottomNavigationAction, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto',
    overflowY: 'hidden',
    marginTop: 'auto',
  },
  menu: {
    justifyContent: 'flex-start',
  },
}));

function BottomMenu({ items, onClick, selected }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BottomNavigation
        value={selected}
        onChange={(event, newValue) => {
          onClick(items[newValue]);
        }}
        showLabels
        classes={{ root: classes.menu }}
      >
        { items.map(item => 
          !item.divider && <BottomNavigationAction key={item.key} label={item.text} icon={item.icon} />
        )}
      </BottomNavigation>
    </div>
  );
}

export default BottomMenu;
