import React from 'react';
import Typography from '@material-ui/core/Typography';
import Markdown from 'react-markdown';
import { makeStyles, Link, Container } from '@material-ui/core';
import { useLocationHash } from '../utils/location';
import LegalFR from '../resources/legal-fr.md';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 15),
    backgroundColor: theme.palette.background.paper,
    '& h1': {
      margin: theme.spacing(10, 0, 5),
    },
    '& p': {
      marginBottom: theme.spacing(1),
    },
    '& a': {
      whiteSpace: "nowrap",
    }
  },
}));

const HeadingMapping = {
  'notice': ['MENTIONS LEGALES'],
  'terms': ['CONDITIONS GENERALES D’UTILISATION'],
  'privacy': ['CHARTE DE PROTECTION DES DONNÉES PERSONNELLES'],
};

function Heading({ level, children }) {
  const getId = () => {
    if (level === 1) {
      const title = children[0].props.value;
      const id = Object.keys(HeadingMapping).find((id) => HeadingMapping[id].includes(title));
      return id;
    }
  };
  const Tag = `h${level}`;
  return (
    <Tag id={getId()}>
      {children}
    </Tag>
  );
}

function Legal() {
  const classes = useStyles();
  const [content, setContent] = React.useState(null);
  const { hash } = useLocationHash();

  React.useEffect(() => {
    fetch(LegalFR)
      .then(response => response.text())
      .then(text => setContent(text));
  }, []);

  React.useEffect(() => {
    if (content && hash) {
      const el = document.getElementById(hash);
      if (el) {
        el.scrollIntoView();
      }
    }
  }, [content, hash]);

  return (
    <main className={classes.root}>
      <Container maxWidth="lg">
        <Markdown
          source={content}
          renderers={{
            paragraph: Typography,
            link: Link,
            heading: Heading,
            listItem: ({children}) => <Typography component="li">{children}</Typography>
          }}
        />
      </Container>
    </main>
  );
}

export default Legal;
