const { useState, useCallback, useRef, useEffect } = require("react");

export function useMountedState() {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true; // Mounted
    return () => mountedRef.current = false; // Unmounted
  }, []);

  return useCallback(() => mountedRef.current === true, []);
}

export function useCancelablePromise() {
  const isMounted = useMountedState();

  return useCallback((promise, onCancel) => {
    return new Promise((resolve, reject) => {
      promise
        .then(result => isMounted() && resolve(result))
        .catch(error => isMounted() && reject(error))
        .finally(() => !isMounted() && onCancel && onCancel());
    });
  }, [isMounted]);
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useSessionState(key, initialValue) {
  const [state, setState] = useState(readStorage(sessionStorage, key) || initialValue);

  const setSessionState = useCallback((value) => {
    writeStorage(sessionStorage, key, value);
    setState(value);
  }, [key]);

  return [state, setSessionState];
}

export function useLocalState(key, initialValue) {
  const [state, setState] = useState(readStorage(localStorage, key) || initialValue);

  const setSessionState = useCallback((value) => {
    writeStorage(localStorage, key, value);
    setState(value);
  }, [key]);

  return [state, setSessionState];
}

function readStorage(storage, key) {
  const item = storage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
}

function writeStorage(storage, key, value) {
  storage.setItem(key, JSON.stringify(value));
}
