import React from 'react';
import PropTypes from 'prop-types';
import { Card, makeStyles, CardContent, Typography, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    // minWidth: '300px',
    //maxWidth: '700px',
    flexGrow: 1,
    height: '100%',
    // overflow: 'auto',
    // margin: theme.spacing(2),
  },
  header: {
    padding : theme.spacing(1),
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
    // overflowX: 'auto',
  },
  loading: {
    margin: 'auto'
  }
}));

export default function Widget({ title, loading, children, error }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {title && <Typography className={classes.header}>{title}</Typography>}
      <CardContent className={classes.content}>
        { error
        ? <Typography color="error">{error.message}</Typography>
        : loading
        ? <CircularProgress className={classes.loading} />
        : children
        }
      </CardContent>
    </Card>
  ); 
}

Widget.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};
