export function getContractList(t, short) {
  const key = short ? 'contract-short' : 'contract';
  return [
    { id: 'cdi', name: t(`${key}.cdi`) },
    { id: 'cdd', name: t(`${key}.cdd`) },
    { id: 'freelance', name: t(`${key}.freelance`) },
    { id: 'intership', name: t(`${key}.intership`) },
    { id: 'apprenticeship', name: t(`${key}.apprenticeship`) },
    { id: 'volunteer', name: t(`${key}.volunteer`) },
  ];
}

export function getPeriodList(t) {
  return [
    { id: 0, name: t('period.week') },
    { id: 1, name: t('period.month') },
    { id: 2, name: t('period.months', { value: 2 }) },
    { id: 3, name: t('period.months', { value: 3 }) },
    { id: 4, name: t('period.months', { value: 4 }) },
    { id: 5, name: t('period.months', { value: 5 }) },
    { id: 6, name: t('period.6months') },
  ];
}

export function getPeriodMarks(t) {
  return [
    { value: 0, legend: t('period.week') },
    { value: 1, legend: t('period.month') },
    { value: 2, legend: t('period.months', { value: 2 }) },
    { value: 3, legend: t('period.months', { value: 3 }) },
    { value: 4, legend: t('period.months', { value: 4 }) },
    { value: 5, legend: t('period.months', { value: 5 }) },
    { value: 6, legend: t('period.6months') },
  ];
}

export function getRecruiterList(t) {
  return [
    { id: 'large', name: t('recruiterType.large') },
    { id: 'medium', name: t('recruiterType.medium') },
    { id: 'small', name: t('recruiterType.small') },
    { id: 'startup', name: t('recruiterType.startup') },
    { id: 'public', name: t('recruiterType.public') },
    { id: 'association', name: t('recruiterType.association') },
    { id: 'individual', name: t('recruiterType.individual') },
  ];
}

export function getJobTypes(t) {
  return [
    { id: 'onsite', name: t('jobType.onsite') },
    { id: 'remote', name: t('jobType.remote') },
    { id: 'flexible', name: t('jobType.flexible') },
  ];
}

export function getExperienceMarks(t) {
  return [
    { value: 0, label: t('experience.student'), legend: t('experience.student') },
    { value: 1 },
    { value: 3, label: t('experience.3years'), legend: t('experience.novice') },
    { value: 5, label: t('experience.5years'), legend: t('experience.confirmed') },
    { value: 7, label: t('experience.7years'), legend: t('experience.senior') },
    { value: 10, label: t('experience.10years'), legend: t('experience.expert') },
  ];
}

export function getDegreeList(t) {
  return [
    { id: 'student', name: t('degrees.student') },
    { id: 'bachelor', name: t('degrees.bachelor') },
    { id: 'master', name: t('degrees.master') },
    { id: 'phd', name: t('degrees.phd') },
  ];
}

export function getYesNo(t) {
  return [
    { id: 'yes', name: t('form.yes') },
    { id: 'no', name: t('form.no') },
  ];
}
