import React from 'react';
import { Paper, TextField, makeStyles, Grid, Typography, CircularProgress } from '@material-ui/core';
import AsyncButton from '../../commons/AsyncButton';
import { useTranslation } from 'react-i18next';
import CodeEditor from '../CodeEditor';
import UserInput from './UserInput';
import HTMLPreview from './HTMLPreview';
import { useFetch } from '../../../api/fetch';

const useStyles = makeStyles((theme) => ({
  preview: {
    padding: theme.spacing(1),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  progress: {
    margin: theme.spacing(1),
  }
}));

function getTemplateInfos(templateName) {
  if (!templateName) {
    return {};
  }
  const infos = {};
  const details = templateName.split('-');
  const last = details[details.length - 1];

  infos.user = details[0];
  if (last === 'match') {
    infos.profile = infos.user === 'candidate' ? 'recruiter' : 'candidate';
  }
  return infos;
}

const FETCH_OPTIONS = {
  useCache: true,
  authenticated: true
};

export default function TemplateEditor({ template, onChange, onSave }) {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const [saving, setSaving] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState(null);
  const templateId = template ? template.id : null;
  const templateInfo = React.useMemo(() => getTemplateInfos(templateId), [templateId]);
  const { loading, data } = useFetch(profile ? `/profile/${profile.id}` + (user ? `?user=${user.id}` : null) : null, FETCH_OPTIONS);

  React.useEffect(() => {
    setUser(null);
    setProfile(null);
  }, [templateInfo.user])
  
  if (!template) {
    return null;
  }

  const handleUserChange = (newUser) => {
    setUser(newUser);
  };
  const handleProfileChange = (newProfile) => {
    setProfile(newProfile);
  };

  const handleChange = (prop) => (event) => {
    if (template && onChange) {
      onChange({ ...template, [prop]: event.target.value });
    }
  };

  const handleCodeChange = (code) => {
    if (template && onChange) {
      onChange({ ...template, html: code });
    }
  };

  const handleSave = onSave ? () => {
    setSaving(true);
    onSave().finally(() => { setSaving(false); });
  } : undefined;

  return (
    <Grid container spacing={2} direction="column">
      <Grid item><Typography variant="h6">{t(`email.${template.id}`)}</Typography></Grid>
      <Grid item>
        <TextField
          label={t('email.subject')}
          variant="outlined"
          fullWidth
          value={template.subject}
          onChange={handleChange('subject')}
        />
      </Grid>
      <Grid item>
        <CodeEditor
          value={template.html}
          onChange={handleCodeChange}
        />
      </Grid>
      <Grid item>
        <AsyncButton onClick={handleSave} loading={saving}>{t('action.save')}</AsyncButton>
      </Grid>
      <Grid item container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="body1" gutterBottom>{t('email.preview')}</Typography>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <UserInput
              label={t('email.searchUser')}
              types={templateInfo ? [templateInfo.user] : null}
              value={user}
              onChange={handleUserChange}
            />
          </Grid>
          { templateInfo.profile &&
            <Grid item className={classes.row}>
              <UserInput
                label={t(`email.${templateInfo.profile}`)}
                types={[templateInfo.profile]}
                value={profile}
                onChange={handleProfileChange}
                disabled={loading}
              />
              { loading && <CircularProgress className={classes.progress} size={24} /> }
            </Grid>
          }
        </Grid>
      </Grid>
      <Grid item>
        <Paper variant="outlined" className={classes.preview}>
          <HTMLPreview html={template.html} user={user} data={data} />
        </Paper>
      </Grid>
    </Grid>
  );
}
