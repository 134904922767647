import firebase from 'firebase/app';
import 'firebase/firestore';
import dataConverter from '../dataConverter';

export const MatchStatus = {
  new: 'new',
  validated: 'validated',
  canceled: 'canceled',
  pending: 'pending',
  declined: 'declined',
  discussion: 'discussion',
  succeeded: 'succeeded',
  failed: 'failed',
};

export function deleteMatch(id) {
  return firebase.firestore().collection('match').doc(id).delete();
}

export function updateMatch(matchId, status) {
  const match = {
    status,
    updated: new Date(),
  };
  return firebase.firestore().collection('match').doc(matchId).withConverter(dataConverter).update(match);
}
