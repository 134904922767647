import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useParams } from 'react-router-dom';
import { getUserData } from './users';

const initialSession = {
  user: null,
  profile: null,
  claims: null,
  initialized: false,
  token: null,
};

const SessionContext = React.createContext(initialSession);

export const SessionProvider = ({ children, session }) => {
  const [profile, setProfile] = useState(session.profile);
  const [synchronizing, setSynchronizing] = useState(false);
  const uid = session.user ? session.user.uid : null;

  const synchronize = useCallback((id) => {
    let targetUser = uid;
    if (id && session.claims && session.claims.admin === true) {
      targetUser = id;
    }
    if (targetUser) {
      setSynchronizing(true);
      return getUserData(targetUser).then((data) => {
        setProfile(data);
      })
      .finally(() => setSynchronizing(false));
    }
    return Promise.resolve();
  }, [session.claims, uid]);

  React.useEffect(() => {
    synchronize();
  }, [synchronize]);

  React.useEffect(() => {
    setProfile(session.profile);
  }, [session.profile]);

  return (
    <SessionContext.Provider value={{...session, profile, synchronize, synchronizing}}>
      {children}
    </SessionContext.Provider>
  );
};

export const useProfile = () => {
  const { profile, synchronize, synchronizing } = React.useContext(SessionContext);
  const { id } = useParams();
  
  const synchronizeProfile = useCallback(() => {
    if (synchronize) {
      return synchronize(id);
    }
  }, [id, synchronize]);

  useEffect(() => {
    if (profile && id && id !== profile.id) {
      synchronizeProfile();
    }
  }, [id, profile, synchronizeProfile]);

  return {
    profile,
    synchronize: synchronizeProfile,
    synchronizing
  };
};

export const useSession = () => {
  return React.useContext(SessionContext);
};

export const useAuth = () => {
  const [ state, setState ] = React.useState(() => {
    const user = firebase.auth().currentUser;
    return {
      user,
      initialized: user ? true : false,
    };
  });

  function onChange(user) {
    if (user && user.emailVerified) {
      const session = {
        ...initialSession,
        user,
        initialized: true,
      };
      getUserData(user.uid)
        .then(function (data) {
          session.profile = data;
          return user.getIdTokenResult();
        })
        .then(function (idTokenResult) {
          session.claims = idTokenResult.claims;
          session.token = idTokenResult.token;
        })
        .finally(function () {
          setState(session);
        });
    } else {
      setState({ ...initialSession, initialized: true });
    }
  }

  React.useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    return () => unsubscribe();
  }, []);

  const refreshToken = React.useCallback(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      return currentUser.getIdTokenResult(true)
        .then(idTokenResult => {
          const { claims, token } = idTokenResult;
          setState({ ...state, claims, token });
          return token;
        });
    }
    return Promise.reject(new Error('Not authenticated'));
  }, [state]);

  return {...state, refreshToken };
};

export function hasPermission(session, permission) {
  if (session && session.claims) {
    return session.claims[permission];
  }
  return false;
}
