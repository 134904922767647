import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from '../contexts/FormContext';
import { useTranslation } from 'react-i18next';
import { Paper, Icon, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  }
}));

function IconLine({ children }) {
  const classes = useStyles();
  const regex = /\$[a-z_]+/i;
  const components = [];
  let text = children;
  let icons;
  let index = 0;
  
  while ((icons = regex.exec(text)) !== null) {
    index = icons.index;
    const length = icons[0].length;
    if (icons.index > 0) {
      components.push(text.substr(0, index))
    }
    components.push(<Icon className={classes.icon} color="primary" key={index}>{text.substr(index + 1, length - 1)}</Icon>)
    text = text.substr(index + length + 1);
  }
  if (text.length > 0) {
    components.push(text);
  }
  return <p>{components}</p>;
}

IconLine.propTypes = {
  children: PropTypes.string.isRequired,
};

export function Helper({ title, text, className, header }) {
  const { t } = useTranslation('helpers');
  const content = t(text);

  if (!content || text === content) {
    return null;
  }

  const lines = content.split('\n');

  return (
    <div className={className}>
      {header}
      <Paper>
      {title && <Typography color="primary" variant="h6">{t(title)}</Typography>}
      { lines.map((line, index) =>
        <IconLine key={index}>{line}</IconLine>
      )}
      </Paper>
    </div>
  );
}

Helper.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.any,
  header: PropTypes.node,
};

function FormHelper({ step, textKey, className, header }) {
  const i18nKey = `${textKey}${step}`;
  const title = step === 0 ? `${textKey}_title` : null;

  return <Helper title={title} text={i18nKey} className={className} header={header} />;
}

FormHelper.propTypes = {
  step: PropTypes.number.isRequired,
  textKey: PropTypes.string.isRequired,
  className: PropTypes.any,
  header: PropTypes.node,
};

function connectToStep(WrappedComponent) {
  return function(props) {
    const { state } = useForm();
    const { step } = state;
    return <WrappedComponent step={step} {...props}/>
  }
}

export default connectToStep(FormHelper);
