import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import Button from '@material-ui/core/Button';
import { makeStyles, StepButton, StepLabel, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm } from '../contexts/FormContext';
import AsyncButton from './commons/AsyncButton';
import { useMountedState } from '../api/states';

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  stepper: {
    padding: theme.spacing(4, 0),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(6, 3, 12),
  },
  text: {
    whiteSpace: 'pre-line',
  },
}));

function FormStepper({ stepLabels, children, complete, onComplete }) {
  const classes = useStyles();
  const [ loading, setLoading ] = React.useState(false);
  const { state, submit, dispatch } = useForm();
  const { step } = state;
  const { t } = useTranslation();
  const isMounted = useMountedState();

  const handleStep = (index) => {
    if (index !== step) {
      dispatch({ type: 'step', value: index });
    }
  };

  const handleNext = (e) => {
    e.preventDefault();
    setLoading(true);
    submit(step)
      .then(({ success, errors }) => {
        if (success) {
          if ((step + 1) < children.length) {
            return true;
          }
          return onComplete(state.form);
        } else if (errors) {
          const error = Object.entries(errors).find(([key, value]) => value);
          if (error) {
            const el = document.getElementById(error[0]);
            if (el) {
              el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        }
        return false;
      })
      .then((next) => {
        if (next && isMounted()) {
          window.scrollTo(0, 0);
          handleStep(step + 1);
        }
      })
      .finally(() => isMounted() && setLoading(false));
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    handleStep(step - 1);
  };

  const getLabel = (index) => stepLabels && index < stepLabels.length ? stepLabels[index] : '';

  return (
    <div className={classes.root}>
      <Stepper activeStep={complete ? children.length : step} orientation="horizontal" alternativeLabel className={classes.stepper}>
        { children.map((child, index) => (
          <Step key={index}>
            { complete
              ? <StepLabel>{getLabel(index)}</StepLabel>
              : <StepButton onClick={() => handleStep(index)}>{getLabel(index)}</StepButton>
            }
          </Step>
        )) }
      </Stepper>
      { !complete &&
      <Paper elevation={0} className={classes.paper}>
        <form className={classes.form} onSubmit={handleNext} noValidate>
          {children[step]}
          <div className={classes.actionsContainer}>
            <Button
              disabled={step === 0}
              onClick={handleBack}
              className={classes.button}
            >
              {t('navigation.previous')}
            </Button>
            <AsyncButton
              variant="contained"
              color="primary"
              type="submit"
              size="medium"
              className={classes.button}
              loading={loading}
            >
              {step === children.length - 1 ?
                (state.loggedIn ? t('navigation.update') : t('navigation.complete'))
                : t('navigation.next')
              }
            </AsyncButton>
          </div>
        </form>
      </Paper>
      }
      { complete &&
        <Paper className={classes.paper} elevation={0}>
          <Typography variant="body1" className={classes.text}>{t('form.completed')}</Typography>
        </Paper>
      }
    </div>
  );
}

FormStepper.propTypes = {
  stepLabels: PropTypes.array.isRequired,
  children: PropTypes.array.isRequired,
  onSubmit: PropTypes.func
};

export default FormStepper;
