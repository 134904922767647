import { useLocation } from "react-router-dom";

export function useLocationHash() {
  const { pathname, hash } = useLocation();
  if (hash && hash.length > 0) {
    return { pathname, hash: hash.substring(1) };
  }
  return { pathname, hash: null };
}

export function useLocationQuery() {
  return new URLSearchParams(useLocation().search);
}
