import React from 'react';
import PropType from 'prop-types';
import { Line } from 'react-chartjs-2';

const LINE_OPTIONS = {
  maintainAspectRatio: false,
  legend: {
    display: false,
    labels: {
      boxWidth: 15,
    },
  },
  tooltips: {
    mode: 'nearest',
    intersect: false,
    callbacks: {
      labelColor: (item, chart) => {
        // User border color has box color for tooltips
        const color = chart.data.datasets[item.datasetIndex].borderColor;
        return {
          borderColor: color,
          backgroundColor: color,
        };
      },
    }
  },
  scales: {
    xAxes: [{
      ticks: {
        callback: value =>
          new Date(value).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' }),
      },
    }]
  },
  plugins: {
    labels: {
      render: () => {}
    },
  },
};

function HistoryLine({ data, ...props }) {
  return (
    <Line
      data={data}
      options={LINE_OPTIONS}
      {...props}
    />
  );
}

HistoryLine.propTypes = {
  data: PropType.object.isRequired,
};

export default HistoryLine;
