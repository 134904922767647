import React from 'react';
import { Grid, Typography, TextField, InputAdornment, makeStyles } from '@material-ui/core';
import AsyncButton from '../../commons/AsyncButton';
import { useTranslation } from 'react-i18next';
import { setting } from './utils';

const useStyle = makeStyles((theme) => ({
  days: {
    width: '100px'
  }
}));

function SchedulerSettings({ loading, data, error, setData, onSave, saving }) {
  const classes = useStyle();
  const { t } = useTranslation('admin');
  const { match, reminders, nomatch, nomatchDelay } = data;

  const handleChange = (prop) => (event) => {
    setData({...data, [prop]: Number.parseInt(event.target.value || 0) });
  };

  const handleSave = () => {
    onSave();
  };

  return (
    <Grid container spacing={2}>
      <Grid container item>
        <Typography variant="h6">{t('scheduler')}</Typography>
      </Grid>
      { error &&
        <Grid item>
          <Typography variant="body1" color="error">{error.message}</Typography>
        </Grid>
      }
      { !loading &&
        <React.Fragment>
          <Grid container item spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="body1">{t('matchEmail')}</Typography>
            </Grid>
            <Grid item className={classes.days}>
              <TextField
                value={match}
                type="number"
                onChange={handleChange('match')}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment>{t('days')}</InputAdornment>
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">{t('matchEmailReminders')}</Typography>
            </Grid>
            <Grid item className={classes.days}>
              <TextField
                value={reminders}
                type="number"
                onChange={handleChange('reminders')}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment>{t('reminders')}</InputAdornment>
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="body1">{t('nomatchEmail')}</Typography>
            </Grid>
            <Grid item className={classes.days}>
              <TextField
                value={nomatch}
                type="number"
                onChange={handleChange('nomatch')}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment>{t('days')}</InputAdornment>
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">{t('nomatchDelay')}</Typography>
            </Grid>
            <Grid item className={classes.days}>
              <TextField
                value={nomatchDelay}
                type="number"
                onChange={handleChange('nomatchDelay')}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment>{t('days')}</InputAdornment>
                }}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      }
      <Grid container item>
        <AsyncButton size="small" loading={saving} onClick={handleSave}>
          {t('action.save')}
        </AsyncButton>
      </Grid>
    </Grid>
  );
}

export default React.memo(setting(SchedulerSettings, 'scheduler'));
