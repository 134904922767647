export function deepEquals(a, b) {
  if (a === b) {
    return true;
  }
  if (typeof a !== typeof b) {
    return false;
  }
  if (a instanceof Array && b instanceof Array) {
    return a.length === b.length &&
      a.every((value, index) => deepEquals(value, b[index]));
  }
  if (a instanceof Date && b instanceof Date) {
    return a === b;
  }
  if (a?.constructor?.name === 'Object' && b?.constructor?.name === 'Object') {
    const entriesA = Object.entries(a);
    const entriesB = Object.entries(b);
    return entriesA.length === entriesB.length &&
      entriesA.every((value, index) => deepEquals(value, entriesB[index]));
  }
  return false;
}
