import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { computeMatchDetails, useMatchColorStyles } from './matchUtils';

export const useStyles = makeStyles((theme) => ({
  popover: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    width: '200px',
  },
  text: {
    cursor: 'default',
  },
  chip: {
    borderRadius: theme.shape.borderRadius,
    fontSize: '12px',
    margin: '4px 2px',
    padding: '1px 5px',
  },
}));

function MatchRatio({ details, onPopover }) {
  const { t } = useTranslation('admin');
  const classes = useStyles();
  const matchClasses = useMatchColorStyles();

  const handlePopoverOpen = React.useCallback((event) => {
    const data = computeMatchDetails(details);
    onPopover({
      position: { top: event.clientY, left: event.clientX },
      element: event.currentTarget,
      content: (
        <div className={classes.popover}>
          {data.map(item =>
            <span key={item.name} className={clsx([classes.chip, matchClasses.coloredBackground(item.value)])}>
              {t(`match.${item.name}`)}
            </span>
          )}
        </div>
      )
    });
  }, [details, onPopover, t, classes, matchClasses]);

  const handlePopoverClose = React.useCallback(() => {
    onPopover(null);
  }, [onPopover]);

  return <>
    <Typography
      className={classes.text}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {`${details.percent}%`}
    </Typography>
  </>;
}

export default React.memo(MatchRatio);
