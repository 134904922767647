import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useCollection } from '../../../api/collection';

const useStyles = makeStyles((theme) => ({
  userInput: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const OPTIONS = {
  listen: true
};

export default function UserInput({ label, types, value, onChange, disabled }) {
  const classes = useStyles();
  const { data, loading } = useCollection('users', OPTIONS);
  const users = React.useMemo(() => !types ? undefined : data.filter((user) => types.includes(user.type)), [types, data]);

  if (!types || types.length === 0) {
    return null;
  }

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <div className={classes.userInput}>
      <Autocomplete
        loading={loading}
        disabled={disabled}
        options={users}
        value={value}
        onChange={handleChange}
        getOptionLabel={(option) => `${option.firstname} ${option.lastname}`}
        getOptionSelected={(option, v) => option.id === v.id}
        style={{ width: 300 }}
        renderInput={(params) =>
            <TextField {...params} label={label} variant="outlined" size="small" />
        }
      />
    </div>
  );
}
