import React from 'react';
import PropType from 'prop-types';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Typography, TextField, makeStyles } from '@material-ui/core';
import ModalDialog from './commons/ModalDialog';
import AsyncButton from './commons/AsyncButton';
import { useTranslation } from 'react-i18next';
import env from '../env';

const useStyles = makeStyles((theme) => ({
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
  },
  input: {
    margin: theme.spacing(2, 0),
  }
}));

function RetrievePassword({ open, onClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const email = React.useRef('');
  const [ error, setError ] = React.useState();
  const [ sent, setSent ] = React.useState(null);
  const [ loading, setLoading ] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase.auth().sendPasswordResetEmail(email.current.value, { url: env.actionRedirectURL })
      .then(function () {
        setSent(email.current.value);
      })
      .catch(function (error) {
        setError(t(`firebaseError.${error.code}`));
      })
      .finally(function () {
        setLoading(false);
      });
  };

  return (
    <ModalDialog open={open} onClose={onClose}>
      <Typography className={classes.text} component="h1" variant="h5">{t('forgotten.title')}</Typography>
      {Boolean(sent) ?
        <Typography className={classes.text} variant="body2">
          {t('forgotten.sent', { email: sent })}
        </Typography>
        :
        <React.Fragment>
          <Typography className={classes.text} variant="body2">{t('forgotten.details')}</Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Adresse e-mail"
              name="email"
              autoComplete="email"
              autoFocus
              className={classes.input}
              inputRef={email}
              error={error && error.length > 0}
              helperText={error}
            />
            <AsyncButton type="submit" loading={loading} className={classes.input}>{t('forgotten.action')}</AsyncButton>
          </form>
        </React.Fragment>
      }
    </ModalDialog>
  );
}

RetrievePassword.propTypes = {
  open: PropType.bool.isRequired,
  onClose: PropType.func,
};

export default RetrievePassword;
