import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { Typography, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, Paper, IconButton, Tooltip, Link } from '@material-ui/core';
import { Done, Clear, Launch } from '@material-ui/icons';
import { useCollection } from '../../api/collection';
import { useFetch } from '../../api/fetch';
import { MatchStatus } from '../../api/admin/match';
import EmailPreview from '../EmailPreview';
import HistoryDialog from '../commons/HistoryDialog';

const VISIBLES_MATCHES = [
  MatchStatus.pending,
  MatchStatus.declined,
  MatchStatus.discussion,
  MatchStatus.succeeded,
  MatchStatus.failed,
];

const FETCH_OPTIONS = { useCache: true, authenticated: true };

function ActionIcon({ name }) {
  switch (name) {
    case 'validate':
      return <Done />;
    case 'cancel':
      return <Clear />;
    case 'succeeded':
      return <Done />;
    case 'failed':
      return <Clear />;
    default:
      return <Launch />;
  }
}

function MatchAction({ actions }) {
  const { t } = useTranslation();

  if (actions) {
    const handleClick = (link) => () => {
      window.location = link;
    };

    return (
      <TableCell>
        { Object.entries(actions).map(([name, action]) =>
          <Tooltip key={name} arrow title={t(`profile.${name}`)}>
            <IconButton size="small" onClick={handleClick(action)}>
              <ActionIcon name={name} />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    );
  }
  return null;
}

const MatchRow = React.memo(({ uid, match, type, disabled, onClick }) => {
  const { t } = useTranslation();
  const { data, loading, error } = useFetch(`/match/${match.id}?uid=${uid}`, FETCH_OPTIONS);

  if ((error || data === null) && !loading) {
    return null;
  }

  const renderText = (fct) => {
    return (
      <Typography variant="body1">
        {loading ? <Skeleton variant="text" /> : fct()}
      </Typography>
    );
  };

  const renderStatus = () => {
    if (!disabled && data && data.actions && Object.values(data.actions).length > 0) {
      return <MatchAction actions={data.actions} />;
    }
    return <TableCell>{renderText(() => t(`match.status.${match.status}`))}</TableCell>;
  };

  return (
    <TableRow>
      <TableCell>{renderText(() => <Link href="#" onClick={onClick}>{getMatchTitle(data, type)}</Link>)}</TableCell>
      <TableCell>{renderText(() => data.start)}</TableCell>
      {renderStatus()}
    </TableRow>
  );
});

function UserMatches({ profile, jobId, width, disabled, ...others }) {
  const { t } = useTranslation();
  const options = React.useMemo(() => getOptions(profile, jobId), [profile, jobId]);
  const { data } = useCollection('match', options);
  const matches = React.useMemo(() => sortAndFilterMatches(data), [data]);
  const [emailId, setEmailId] = React.useState(null);

  const handleClick = (match) => (event) => {
    event.preventDefault();
    const emails = match.emails && match.emails[profile.type];
    if (emails) {
      setEmailId(emails[emails.length - 1]);
    }
  };

  const handleClose = () => {
    setEmailId(null);
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper} {...others}>
        <Table size="small">
          {matches.length === 0
          ? <caption>{t('profile.nomatch')}</caption>
          : <TableHead>
              <TableRow>
                <TableCell>{t(`profile.${profile.type}.name`)}</TableCell>
                <TableCell>{t(`profile.${profile.type}.start`)}</TableCell>
                <TableCell style={{ width: 100 }}>{t(`profile.${profile.type}.status`)}</TableCell>
              </TableRow>
            </TableHead>
          }
          <TableBody>
          { matches.map(match =>
            <MatchRow
              key={match.id}
              uid={profile.id}
              match={match}
              type={profile.type}
              disabled={disabled}
              onClick={handleClick(match)}
            />
          )}
          </TableBody>
        </Table>
      </TableContainer>
      <HistoryDialog
        fullWidth
        maxWidth="md"
        fullscreen="sm"
        name="email"
        open={Boolean(emailId)}
        onClose={handleClose}
      >
        <EmailPreview id={emailId} />
      </HistoryDialog>
    </React.Fragment>
  );
}

export default React.memo(UserMatches);

function getMatchTitle(data, type) {
  if (type === 'candidate') {
    if (data.company) {
      return data.company;
    } else if (data.firstname && data.lastname) {
      return `${data.firstname} ${data.lastname}`;
    } else if (data.sector) {
      return `${data.contract} ${data.sector}`;
    }
    return `${data.contract} ${data.recruiter}`;
  } else if (type === 'recruiter') {
    if (data.firstname && data.lastname) {
      return `${data.firstname} ${data.lastname}`;
    } else if (data.title) {
      return data.title;
    } else if (data.roles) {
      return data.roles.join(', ');
    }
    return `${data.contract} ${data.recruiter}`;
  }
  return '';
}

function getOptions(profile, jobId) {
  if (profile) {
    const { id } = profile;
    switch (profile.type) {
      case 'candidate':
        return { where: ['candidate', '==', id], listen: true };
      case 'recruiter':
        return jobId
          ? { where: [['recruiter', '==', id], ['job', '==', jobId]], listen: true }
          : { where: ['recruiter', '==', id], listen: true };
      default:
        break ;
    }
  }
  return null;
}

function sortAndFilterMatches(matches) {
  if (matches) {
    return matches
      .filter(match => VISIBLES_MATCHES.includes(match.status))
      .sort((a, b) => (a.updated < b.updated ? 1 : -1));
  }
  return [];
}
