import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';

function CheckboxGroup({ required, legend, onChange, error, helperText, value, fields, className }) {

  const handleChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      onChange([...value, name]);
    } else {
      onChange(value.filter((v) => v !== name));
    }
  };

  return (
    <FormControl required={required} component="fieldset" className={className} error={error}>
      { legend ? <FormLabel component="legend">{legend}</FormLabel> : undefined }
      <FormGroup>
        { fields.map((field) => 
          <FormControlLabel
            control={
              <Checkbox
                name={field.id}
                checked={value ? value.indexOf(field.id) >= 0 : false}
                onChange={handleChange}
                color="primary"
              />
            }
            label={field.name}
            key={field.id}
          />
        ) }
      </FormGroup>
      { helperText ? <FormHelperText>{helperText}</FormHelperText> : undefined }
    </FormControl>
  );
}

CheckboxGroup.propTypes = {
  required: PropTypes.bool,
  legend: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  })).isRequired,
  value: PropTypes.array,
  className: PropTypes.string,
};

export default CheckboxGroup;
