import React from 'react';
import { Typography, Link } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { useUser } from '../../api/users';

function UserName({ uid, link, options, ...props }) {
  const { loading, user } = useUser({ uid, cache: true });

  const renderUserName = () => {
    if (!user) {
      return 'Invalid user';
    }
    const displayName = `${user.firstname} ${user.lastname}`;
    if (link) {
      let href = `/${user.type}/${user.id}`;
      if (options) {
        href += '?' + Object.entries(options).map(entry => entry.join('=')).join('&');
      }
      return (
        <Link href={href} target="_blank">
          {displayName}
        </Link>
      );
    }
    return displayName;
  };

  return (
    <Typography variant="body2" {...props}>
      { loading
      ? <Skeleton variant="text" width={130} />
      : renderUserName()
      }
    </Typography>
  );
}

export default React.memo(UserName);
