import React from 'react';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Typography, ThemeProvider, withWidth, isWidthDown } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import ProgressiveImage from 'react-progressive-graceful-image';
import candidateTheme from '../themes/candidate';
import recruiterTheme from '../themes/recruiter';
import bannerDesktop from '../resources/landing-desktop.jpg';
import bannerDesktopPlaceholder from '../resources/landing-desktop-placeholder.jpg';
import bannerMobile from '../resources/landing-mobile.jpg';
import bannerMobilePlaceholder from '../resources/landing-mobile-placeholder.jpg';
import clsx from 'clsx';
import AnimatedSteps from '../components/AnimatedSteps';
import Logo from '../components/Logo';
import { ScrollToTopButton } from '../components/ScrollTop';
import { grey } from '@material-ui/core/colors';
import RevealCascade from '../components/commons/RevealCascade';
import ContactSection from '../components/ContactSection';
import reason1 from '../resources/reason01.jpg';
import reason2 from '../resources/reason02.jpg';
import reason3 from '../resources/reason03.jpg';
import mentors from '../resources/mentors.jpg';
import advisors from '../resources/advisors.jpg';
import partners from '../resources/partners.jpg';

const OPACITY = '0.3';
const HOVER_OPACITY = '0.7';
const STEP_TIMEOUT = 2000;
const REVEAL_FRACTION = 0.5;

const backgroundFilter = `linear-gradient(rgba(0, 0, 0, ${OPACITY}), rgba(0, 0, 0, ${OPACITY})), `;
const hoverFilter = `linear-gradient(rgba(255, 255, 255, ${HOVER_OPACITY}), rgba(255, 255, 255, ${HOVER_OPACITY})), `;

const useStyles = makeStyles((theme) => {
  return ({
    root: {
      display: 'flex',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      minHeight: '700px',
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        minHeight: '500px',
      },
    },
    catchphrase: {
      color: theme.palette.common.white,
      maxWidth: '70%',
      fontSize: '4em',
      fontWeight: '400',
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        fontSize: '2.5em',
        maxWidth: '80%'
      }
    },
    actionSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    section: {
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: theme.spacing(4, 4, 8),
      '&:nth-child(odd)': {
        backgroundColor: theme.palette.common.white,
      }
    },
    sectionTitle: {
      width: '100%',
      textAlign: 'center',
      fontSize: '2em',
      fontWeight: 'bold',
      margin: theme.spacing(4, 0, 6)
    },
    intro: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: grey[800],
      color: theme.palette.getContrastText(grey[800]),
      textAlign: 'center',
      padding: theme.spacing(4, 6, 5),
      '& p': {
        maxWidth: '800px',
      }
    },
    reason: {
      flexGrow: '1',
      color: theme.palette.common.white,
      width: '260px',
      maxWidth: '500px',
      height: '260px',
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: theme.transitions.create('color', { duration: 1000 }),
      cursor: 'default',
      '&:hover': {
        // transform: 'scale(1.1)',
        color: '#000000',
      }
    },
    reason1: {
      background: `${backgroundFilter}no-repeat center/cover url(${reason1})`,
      '&:hover': {
        background: `${hoverFilter}no-repeat center/cover url(${reason1})`,
      }
    },
    reason3: {
      background: `${backgroundFilter}no-repeat center/cover url(${reason2})`,
      '&:hover': {
        background: `${hoverFilter}no-repeat center/cover url(${reason2})`,
      }
    },
    reason2: {
      background: `${backgroundFilter}no-repeat center/cover url(${reason3})`,
      '&:hover': {
        background: `${hoverFilter}no-repeat center/cover url(${reason3})`,
      }
    },
    steps: {
      backgroundColor: 'transparent',
      margin: theme.spacing(4, 2),
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(4, 0),
      }
    },
    aboutElement: {
      maxWidth: '250px',
      margin: theme.spacing(2, 8),
      textAlign: 'center',
      '& img': {
        width: '200px',
        height: '200px',
        objectFit: 'cover',
        borderRadius: '100px',
        boxShadow: theme.shadows[10],
        marginBottom: theme.spacing(2),
      }
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
    aboutButton: {
      marginTop: theme.spacing(4),
    }
  });
});

const useSubStyles = makeStyles((theme) => ({
  actionBlock: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: theme.spacing(4),
    textAlign: 'center',
    width: '300px',
    flexGrow: '1',
    '& p': {
      maxWidth: '500px',
      margin: 'auto',
    }
  },
  button: {
    flex: 1,
    marginBottom: theme.spacing(2),
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
}));

function ActionBlock({ path, label, text }) {
  const classes = useSubStyles();

  return (
    <div className={classes.actionBlock}>
      <Fade fraction={REVEAL_FRACTION}>
        <Button variant="outlined" className={classes.button} component={RouterLink} to={path}>{label}</Button>
        <Typography variant="body1"><Trans i18nKey={text} ns="home" /></Typography>
      </Fade>
    </div>
  );
}

function AboutElement({ className, image, text, delay = 0 }) {
  return (
    <div className={className}>
      <Zoom delay={delay} fraction={REVEAL_FRACTION}>
        <img src={image} alt="" />
      </Zoom>
      <Fade delay={500 + delay} fraction={REVEAL_FRACTION}>
        <Typography variant="body1"><Trans i18nKey={text} ns="home" /></Typography>
      </Fade>
    </div>
  );
}

function Home({ width }) {
  const classes = useStyles();
  const { t } = useTranslation('home');
  const background = isWidthDown('xs', width)
      ? { image: bannerMobile, placeholder: bannerMobilePlaceholder }
      : { image: bannerDesktop, placeholder: bannerDesktopPlaceholder };

  return (
    <main>

      <ProgressiveImage
        src={background.image}
        placeholder={background.placeholder}
      >
        {(src, style) =>
          <section className={classes.titleContainer} style={Object.assign(style, { backgroundImage: `${backgroundFilter}url(${src})` })}>
            <Fade duration={2000}>
              <Typography className={classes.catchphrase} variant="h1">{t('catchphrase')}</Typography>
            </Fade>
          </section>
        }
      </ProgressiveImage>
      <section className={classes.actionSection}>
        <ThemeProvider theme={candidateTheme}>
          <ActionBlock
            path="/candidate"
            label={t('designer.label')}
            text="designer.description"
          />
        </ThemeProvider>
        <ThemeProvider theme={recruiterTheme}>
          <ActionBlock
            path="/recruiter"
            label={t('recruiter.label')}
            text="recruiter.description"
          />
        </ThemeProvider>
      </section>

      <section className={classes.intro}>
        <Fade delay={100}>
          <Logo variant="h4" gutterBottom />
          <Typography variant="body1"><Trans i18nKey="intro" ns="home" /></Typography>
        </Fade>
      </section>

      <section className={classes.section}>
        <Typography className={classes.sectionTitle} variant="h2">{t('why.title')}</Typography>
        <RevealCascade Component={Zoom} fraction={REVEAL_FRACTION}>
          <div className={clsx([classes.reason, classes.reason1])}>
            <Typography variant="body1"><Trans i18nKey="why.quality" ns="home" /></Typography>
          </div>
          <div className={clsx([classes.reason, classes.reason2])}>
            <Typography variant="body1"><Trans i18nKey="why.confidentiality" ns="home" /></Typography>
          </div>
          <div className={clsx([classes.reason, classes.reason3])}>
            <Typography variant="body1"><Trans i18nKey="why.simplicity" ns="home" /></Typography>
          </div>
        </RevealCascade>
      </section>

      <section className={classes.section}>
        <Typography className={classes.sectionTitle} variant="h2">{t('how.title')}</Typography>
        <AnimatedSteps className={classes.steps} timeout={STEP_TIMEOUT} />
      </section>

      <section className={classes.section}>
        <Typography className={classes.sectionTitle} variant="h2">{t('about.title')}</Typography>
        <AboutElement className={classes.aboutElement} text="about.mentors" image={mentors} />
        <AboutElement className={classes.aboutElement} text="about.advisors" image={advisors} delay={200} />
        <AboutElement className={classes.aboutElement} text="about.partners" image={partners} delay={400} />
        <div className={classes.buttonContainer}>
          <Button className={classes.aboutButton} variant="contained" color="primary" component={RouterLink} to="/about">{t('about.button')}</Button>
        </div>
      </section>

      <ContactSection className={classes.section} />

      <ScrollToTopButton />

    </main>
  );
}

export default withWidth()(Home);
