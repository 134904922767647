import React, { useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { DatePicker } from "../../commons/DatePicker";
import { useTranslation } from 'react-i18next';

const TranslatedDatePicked = ({ t, ...props }) =>
  <DatePicker
    autoOk
    showTodayButton
    okLabel={t('action.ok')}
    cancelLabel={t('action.cancel')}
    todayLabel={t('date.today')}
    {...props}
  />;

function toStartOfDay(date) {
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);
  return date;
}

function toEndOfDay(date) {
  date.setSeconds(59);
  date.setMinutes(59);
  date.setHours(23);
  return date;
}

function DateFilter({ onConfirm, onClear, onClose, showClear, from: initialFrom, to: initialTo, ...props }) {
  const { t } = useTranslation('admin');
  const [from, setFrom] = useState(initialFrom || new Date());
  const [to, setTo] = useState(initialTo || new Date());
  const [error, setError] = useState(false);

  const handleComplete = () => {
    const startDate = toStartOfDay(from);
    const endDate = toEndOfDay(to);
    if (startDate <= endDate) {
      setError(false);
      onConfirm({
        from: startDate,
        to: endDate
      });
    } else {
      setError(true);
    }
  };

  return (
    <Dialog onClose={onClose} {...props}>
      <DialogTitle>{t('date.filter')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <TranslatedDatePicked
              label={t('date.from')}
              value={from}
              onChange={date => setFrom(date)}
              t={t}
              error={error}
            />
          </Grid>
          <Grid item>
            <TranslatedDatePicked
              label={t('date.to')}
              value={to}
              onChange={date => setTo(date)}
              t={t}
              error={error}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {showClear && <Button onClick={onClear}>{t('action.clear')}</Button>}
        <Button onClick={onClose}>{t('action.cancel')}</Button>
        <Button onClick={handleComplete}>{t('action.ok')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export function useDateFilter() {
  const [opened, setOpened] = useState(false);
  const [filters, setFilters] = useState(null);

  return { dateFilters: filters, ...useMemo(() => {

    const open = (options) => {
      setOpened(options || true);
    };
    const close = () => {
      setOpened(false);
    };  
    const handleConfirm = ({ from, to }) => {
      if (opened?.field) {
        setFilters([
          [opened.field, '>=', from],
          [opened.field, '<=', to],
        ]);
      }
      close();
    };
    const handleClear = () => {
      setFilters(null);
      close();
    };
    const component = () =>
      <DateFilter
        open={Boolean(opened)}
        from={opened && filters && filters[0][2]}
        to={opened && filters && filters[1][2]}
        showClear={Boolean(filters)}
        onClose={close}
        onConfirm={handleConfirm}
        onClear={handleClear}
      />
    ;

    return {
      DateFilter: component,
      openFilter: open,
      closeFilter: close,
      isActiveFilter: field => filters?.[0]?.[0] === field,
    };
  }, [opened, filters]) };
}

export default DateFilter;
