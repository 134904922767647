import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useMountedState } from '../../api/states';
import { activateProfile, deactivateProfile } from '../../api/users';
import AsyncButton from '../commons/AsyncButton';
import { useConfirm } from '../commons/ConfirmDialog';

export default function ActivationButton({ profile, synchronize }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const isMounted = useMountedState();
  const { ConfirmDialog, confirm } = useConfirm();

  const handleActivate = () => {
    setLoading(true);
    activateProfile(profile.id, profile.jobId)
      .then(() => synchronize())
      .finally(() => isMounted() && setLoading(false));
  };

  const handleDeactivate = () => {
    confirm({ title: t(`disable.${profile.type}Title`), text: t(`disable.${profile.type}Text`) }, confirmed => {
      if (confirmed) {
        setLoading(true);
        deactivateProfile(profile.id, profile.jobId)
          .then(() => synchronize())
          .finally(() => isMounted() && setLoading(false));
      }
    });
  };

  return (
    <Fragment>
      { profile.disabled
      ? <AsyncButton
          variant="contained"
          loading={loading}
          onClick={handleActivate}
        >
          {t('profile.activate')}
        </AsyncButton>
      : <AsyncButton
          variant="contained"
          color="default"
          loading={loading}
          onClick={handleDeactivate}
          size="small"
        >
          {t('profile.deactivate')}
        </AsyncButton>
      }
      <ConfirmDialog cancel={t('disable.cancel')} ok={t('disable.confirm')} />
    </Fragment>
  );
}
