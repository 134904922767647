import env from './env';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDa-7GRSz6XYyXO00FuL5Jj7PMoexs1u6w",
  authDomain: "designlinkapp.firebaseapp.com",
  databaseURL: "https://designlinkapp.firebaseio.com",
  projectId: "designlinkapp",
  storageBucket: "designlinkapp.appspot.com",
  messagingSenderId: "454237149125",
  appId: "1:454237149125:web:295ddbe33313bb7e139dac",
  measurementId: "G-9PBFXM9DLQ"
};

class FirebaseApp {

  constructor() {
    this.initialized = false;
    this.isEmulated = false;
  }
  
  initialize() {
    firebase.initializeApp(firebaseConfig);

    // Dev environement
    // if (false) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Developpement environement started');
    
      env.setBaseURL('http://localhost:3000/');
      env.setFunctionURL('http://localhost:5001/designlinkapp/us-central1/api');
    
      firebase.firestore().settings({
        host: 'localhost:8080',
        ssl: false
      });
      firebase.auth().useEmulator('http://localhost:9099/');
      firebase.database().useEmulator('localhost', 9000);
      this.isEmulated = true;
    } else { // Production
      firebase.analytics();
    }
    this.initialized = true;
  }

  firestore() {
    return firebase.firestore();
  }

}

export default new FirebaseApp();
