import React from 'react';
import { TextField, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Send } from '@material-ui/icons';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import AsyncButton from './commons/AsyncButton';
import { normalizePhoneNumber } from '../utils/formUtils';
import { sendMessage } from '../api/contact';

function ContactForm(props) {
  const { t } = useTranslation('home');
  const email = React.useRef('');
  const phone = React.useRef('');
  const message = React.useRef('');
  const [errors, setErrors] = React.useState({});
  const [sent, setSent] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  let newErrors = {...errors};

  const handleSubmit = (event) => {
    event.preventDefault();
    const valid = [validateEmail(), validatePhone(), validateMessage()].every(value => value);
    if (valid) {
      setLoading(true);
      sendMessage({
        email: email.current.value,
        phone: phone.current.value,
        message: message.current.value,
      }).then(() => {
        setSent(true);
      }).catch(error => setErrors({...errors, global: true}))
        .finally(() => setLoading(false));
    }
  };

  const validateEmail = () => {
    const { value } = email.current;
    const valid = value.length > 0 && isEmail(value);
    newErrors = { ...newErrors, email: !valid }
    setErrors(newErrors);
    return valid;
  };

  const validatePhone = () => {
    const value = normalizePhoneNumber(phone.current.value);
    const valid = !value || isMobilePhone(value);
    newErrors = { ...newErrors, phone: !valid }
    setErrors(newErrors);
    return valid;
  };

  const validateMessage = () => {
    const { value } = message.current;
    const valid = value.length > 10;
    newErrors = { ...newErrors, message: !valid }
    setErrors(newErrors);
    return valid;
  };

  return (
    <form {...props} onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2} wrap="wrap" justify="center">
        <Grid item xs={12} sm={6}>
          <TextField label={t('contact.email')} fullWidth inputRef={email} error={errors.email} onBlur={validateEmail} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label={t('contact.phone')} fullWidth inputRef={phone} error={errors.phone} onBlur={validatePhone} />
        </Grid>
        <Grid item xs={12}>
          <TextField label={t('contact.message')} inputRef={message} error={errors.message} multiline rows={6} fullWidth />
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          { errors.global && <Typography variant="body2" color="error">{t('contact.error')}</Typography> }
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          { sent
          ? <Typography variant="body1" color="primary">{t('contact.sent')}</Typography>
          : <AsyncButton
              loading={loading}
              variant="text"
              color="inherit"
              type="submit"
              endIcon={<Send />}
            >
              {t('contact.submit')}
            </AsyncButton>
          }
        </Grid>
      </Grid>
    </form>
  );
}

export default React.memo(ContactForm);
