import firebase from 'firebase/app';
import 'firebase/analytics';

export default function analytics(event, options) {
  try {
    firebase.analytics().logEvent(event, options)
  } catch (error) {
    // Do nothing
  }
}
