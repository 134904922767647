import { useEffect, useMemo, useState } from "react";
import { useCollection } from "../collection";
import { getDocument } from "../document";
import { useMountedState } from "../states";

export class StatsProvider {
  constructor(config, translation) {
    this.config = config;
    this.t = translation;
    this.labels = {};
  }

  getFieldValue(data, name, defaultValue = 0) {
    if (name.endsWith('_active')) {
      const baseName = name.substr(0, name.indexOf('_active'));
      const disabledName = `${baseName}_disabled`;
      if (baseName in data && disabledName in data) {
        return data[baseName] - data[disabledName];
      }
      return defaultValue;
    }
    return data[name] || defaultValue;
  }

  getDataset(data, dataset, { label, colors, limit, sort, collection } = {}) {
    if (data) {
      let entries;
      if (Array.isArray(dataset)) {
        entries = dataset.map(name => ([name, this.getFieldValue(data, name)]));
      } else if (typeof dataset === 'string' && dataset in data) {
        entries = Object.entries(data[dataset]);
        if (!label) {
          label = dataset;
        }
      }
      if (entries) {
        if (sort !== false) {
          entries = entries.sort((a, b) => b[1] - a[1]);
        }
        if (limit) {
          entries = entries.slice(0, limit);
        }
        const statistics = Object.fromEntries(entries);
        const values = Object.values(statistics);
        return Promise.all(
          Object.keys(statistics).map((name, index) =>
            this.getLabel(name, { count: values[index], collection, dataset: label })
          )
        ).then(labels => ({
          label: label,
          labels,
          data: values,
          backgroundColor: colors || this.config.defaultColors,
        }));
      }
    }
    return Promise.resolve(null);
  }

  computeChartData(document, name) {
    const config = this.getChartConfig(name);
    let promise;
    let datasets;
    let labels;
    if (config instanceof Array) {
      promise = Promise.all(config.map(dataset => this.getDataset(document, dataset)));
    } else if (config instanceof Object) {
      promise = Promise.all(config.labels ? config.labels.map(label => this.getLabel(label)) : [])
        .then(results => {
          labels = results.length ? results : null;
          return Promise.all(
            config.datasets.map((dataset, index) =>
              this.getDataset(document, dataset, {
                label: labels ? labels[index] : null,
                colors: config.colors?.[index],
                limit: config.limit,
                sort: config.sort,
                collection: config.collection
              })
            )
          );
        });
    }
    return promise.then(result => {
      datasets = result.filter(dataset => dataset !== null);
      if (!labels) {
        labels = datasets && datasets.length ? datasets[0].labels : [];
      }
      return {
        labels,
        datasets,
      };
    });
  }

  getLabel(name, { count, collection, dataset, key } = {}) {
    return new Promise((resolve, reject) => {
      if (collection) {
        const label = this.labels[name];
        let promise;
        if (typeof label === 'string') {
          resolve(`${count} ${label}`);
        } else if (label instanceof Promise) {
          promise = label;
        } else {
          promise = getDocument(name, collection);
          this.labels[name] = promise;
        }
        if (promise) {
          promise.then(doc => {
            this.labels[name] = doc.get('name');
            resolve(`${count} ${doc.get('name')}`)
            return doc;
          });
        }
      } else if (!isNaN(name) && dataset) {
        resolve(this.t(dataset, { count: Number(name), context: name }) + ` (${count})`);
      } else {
        resolve(this.t(key ? `${key}.${name}` : name, { count }));
      }
    })
  }

  getChartConfig(name) {
    return this.config.charts[name];
  }
}

export function useStatistics(document, controller, name) {
  const [data, setData] = useState({});
  const isMounted = useMountedState();

  useEffect(() => {
    controller.computeChartData(document, name)
      .then(result => {
        if (isMounted()) {
          setData(result)
        }
      });
  },
  [document, controller, name, isMounted]);

  return { data };
}

export function useHistoryStatistics({
  days,
  controller,
  fields,
  background,
}) {
  const options = useMemo(() => ({
    orderBy: ['date', 'desc'],
    limit: days
  }), [days]);
  const { data: stats } = useCollection('statistics', options);
  const [data, setData] = useState({});
  
  useEffect(() => {
    const statsData = stats.slice(0).reverse();
    if (!fields || !stats) {
      return {
        labels: [],
        datasets: [],
      };
    }
    const labels = statsData.map(entry => entry.id);
    Promise.all(fields.map((field) => {
      const values = statsData.map(entry => controller.getFieldValue(entry, field.name));
      return controller.getLabel(field.name, { key: 'history' })
        .then(label => ({
          label,
          data: values,
          backgroundColor: background ? field.color : 'rgba(0, 0, 0, 0)',
          borderColor: field.color,
        }));
    })).then(datasets => setData({
      labels,
      datasets,
    }));
  }, [stats, controller, fields, background]);

  return { data };
}
