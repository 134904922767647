import React, { useMemo } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { TagInput, CheckboxInput, SelectInput, SliderInput, DateInput, TextInput, Visible, RadioInput } from '../components/inputs/FormSections';
import { getContractList, getRecruiterList, getJobTypes, getExperienceMarks, getPeriodMarks, getYesNo } from '../utils/dataProvider';
import { PrimarySkills, ComplementarySkills } from '../components/inputs/SkillInputs';
import Remuneration from '../components/inputs/Remuneration';
import DegreeList from '../components/inputs/DegreeList';
import Profile from '../components/inputs/Profile';
import WebLinks from '../components/inputs/WebLinks';
import { isLinkedInUrl, isValidDate, normalizeProfile } from '../utils/formUtils';
import { isNewEmail } from '../api/auth';
import Form from '../components/Form';
import { LastJobs } from '../components/inputs/LastJobs';
import RGPD from '../components/RGPD';
import { useLoggedIn } from '../contexts/FormContext';
import { useProfile } from '../api/session';
import UserSheet from '../components/UserSheet';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  container: {
    width: '660px',
    maxWidth: '100%',
  },
  section: {
    width: '100%',
  },
}));

const TYPE = 'candidate';

const defaultProfile = {
  type: TYPE,
  roles: [],
  contracts: [],
  start: new Date(Date.now() + 86400000), // Tomorrow
  duration: [0, 6],
  recruiters: [],
  sectors: [],
  jobTypes: [],
  international: 'no',
  place: '',
  experience: 0,
  degrees: [ { degree: '', discipline: '' } ],
  expert: [],
  proficient: [],
  novice: [],
  softs: [],
  tools: [],
  languages: [],
  lastJobs: [],
  salary: '',
  adr: '',
  freeText: '',
  website: '',
  linkedIn: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  hasCompany: 'no',
  company: '',
  rgpd: false,
};

const isDurationVisible = (contracts) => contracts.some(contract => contract !== 'cdi');  
const isPlaceVisible = (jobTypes) => jobTypes.some(jobType => jobType !== 'remote');
const isRemunerationVisible = (contracts) => contracts.some(contract => contract !== 'volunteer');
const isAnnualSalaryVisible = (contracts) => contracts.some(contract => contract === 'cdi');
const isMonthlySalaryVisible = (contracts) => contracts.some(contract => contract === 'cdd' || contract === 'intership' || contract === 'apprenticeship');
const isSalaryVisible = (contracts) => isAnnualSalaryVisible(contracts) || isMonthlySalaryVisible(contracts);
const isAdrVisible = (contracts) => contracts.some(contract => contract === 'freelance');
const isCompanyVisible = (hasCompany) => hasCompany === 'yes';
const isInternational = (form) => form.international === 'yes';
const isLocal = (form) => form.international === 'no';

const COUNTRIES_OPTIONS = { filter: ['type', 'country'], autocomplete: true };
const REGIONS_OPTIONS = { filter: ['type', 'region'], autocomplete: true };

function CandidateStep1() {
  const classes = useStyles();
  const { t } = useTranslation();
  const loggedIn = useLoggedIn();
  const periodMarks = React.useMemo(() => getPeriodMarks(t), [t]);

  const handleInternational = (value, dispatch) => {
    dispatch({ type: 'change', value: '', name: 'place' });
  };

  return (
    <Box className={classes.section}>
      <TagInput
        name="roles"
        collection="roles"
        title={t('candidate.designer')}
        propose={{
          name: 'newRoles',
          title: t('form.missingRoleTitle'),
          tooltip: t('form.missingRoleTooltip')
        }}
      />
      <CheckboxInput name="contracts" getFields={() => getContractList(t)} title={t('candidate.contract')} />
      <DateInput name="start" title={t('candidate.start')} disablePast={!loggedIn} />
      <Visible when={(form) => isDurationVisible(form.contracts)}>
        <SliderInput name="duration" marks={periodMarks} title={t('candidate.duration')} legend="exact" />
      </Visible>
      <CheckboxInput name="recruiters" getFields={() => getRecruiterList(t)} title={t('candidate.recruiter')} />
      <TagInput
        name="sectors"
        collection="sectors"
        title={t('candidate.sector')}
        propose={{
          name: 'newSectors',
          title: t('form.missingSectorTitle'),
          tooltip: t('form.missingSectorTooltip')
        }}
      />
      <CheckboxInput name="jobTypes" getFields={() => getJobTypes(t)} title={t('candidate.jobType')} />
      <Visible when={(form) => isPlaceVisible(form.jobTypes)}>
        <RadioInput
          name="international"
          title={t('candidate.international')}
          getFields={() => getYesNo(t)}
          onChange={handleInternational}
        />
        <Visible when={isInternational}>
          <SelectInput name="place" title={t('candidate.place')} collection="places" label={t('candidate.country')} inputProps={COUNTRIES_OPTIONS} />
        </Visible>
        <Visible when={isLocal}>
          <SelectInput name="place" title={t('candidate.place')} collection="places" label={t('candidate.region')} inputProps={REGIONS_OPTIONS} />
        </Visible>
      </Visible>
    </Box>
  );
}

function CandidateStep2() {
  const classes = useStyles();
  const { t } = useTranslation();
  const expMarks = React.useMemo(() => getExperienceMarks(t), [t]);

  return (
    <Box className={classes.section}>
      <SliderInput name="experience" title={t('candidate.experience')} marks={expMarks} legend="lower" />
      <DegreeList name="degrees" title={t('candidate.degree')} />
      <PrimarySkills namespace={TYPE} limit={6} />
      <ComplementarySkills namespace={TYPE} limit={6} />
      <LastJobs name="lastJobs" title={t('candidate.lastJobs')} />
      <Visible when={(state) => isRemunerationVisible(state.contracts)}>
        <Remuneration
          title={t('candidate.remuneration')}
          annualVisible={(state) => isAnnualSalaryVisible(state.contracts)}
          monthlyVisible={(state) => isMonthlySalaryVisible(state.contracts)}
          adrVisible={(state) => isAdrVisible(state.contracts)}
        />
      </Visible>
    </Box>
  );
}

function CandidateStep3() {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleHasCompany = (value, dispatch) => {
    if (value === 'no') {
      dispatch({ type: 'change', value: '', name: 'company' });
    }
  };

  return (
    <Box className={classes.section}>
      <TextInput name="freeText" title={t('candidate.freeText')} multiline max={2000} rows={8} />
      <WebLinks title={t('candidate.links')} />
      <RadioInput
        name="hasCompany"
        title={t('candidate.hasCompany')}
        getFields={() => getYesNo(t)}
        onChange={handleHasCompany}
      />
      <Visible when={(form) => isCompanyVisible(form.hasCompany)}>
        <TextInput
          name="company"
          title={t('candidate.company')}
          label={t('common.company')}
          inputProps={{
            id: 'company',
            name: 'company',
            autoComplete: 'organization',
          }}
        />
      </Visible>
      <Profile title={t('candidate.profile')} />
      <RGPD />
    </Box>
  );
}

function validateLastJob(lastJob) {
  return Object.entries(lastJob).every(([key, value]) => {
    if (key === 'duration' && lastJob.contract === 'cdi') {
      return true;
    }
    return (value !== null && value !== undefined);
  }) || Object.entries(lastJob).every(([key, value]) => {
    if (key === 'duration' && lastJob.contract === 'cdi') {
      return true;
    }
    return (value === null || value === undefined);
  });
}

async function validate(state, dispatch, step) {
  const loggedIn = state.loggedIn;
  const form = normalizeProfile(state.form);
  let errors;

  switch (step) {
    case (0) :
      errors = {
        roles: form.roles.length === 0,
        contracts: form.contracts.length === 0,
        start: !isValidDate(form.start),
        duration: isDurationVisible(form.contracts) && (!form.duration || form.duration.length !== 2),
        recruiters: form.recruiters.length === 0,
        sectors: form.sectors.length === 0,
        jobTypes: form.jobTypes.length === 0,
        place: isPlaceVisible(form.jobTypes) && form.place.length === 0
      };
      break ;
    case (1):
      errors = {
        experience: form.experience === undefined,
        degrees: form.degrees.some(degree => (!degree.degree || !degree.discipline) && degree.degree !== degree.discipline),
        primary: form.expert.length === 0 && form.proficient.length === 0 && form.novice.length === 0,
        softs: form.softs.length === 0,
        tools: form.tools.length === 0,
        languages: form.languages.length === 0,
        lastJobs: form.lastJobs.length !== 0 && form.lastJobs.some(lastJob => !validateLastJob(lastJob)),
        salary: isRemunerationVisible(form.contracts) && isSalaryVisible(form.contracts) && (!form.salary || form.salary.length === 0),
        adr: isRemunerationVisible(form.contracts) && isAdrVisible(form.contracts) && (!form.adr || form.adr.length === 0),
      };
      break ;
    case (2):
      errors = {
        freeText: form.freeText.length === 0,
        website: form.website.length !== 0 && !isURL(form.website),
        linkedIn: form.linkedIn.length !== 0 && !isLinkedInUrl(form.linkedIn),
        company: form.hasCompany === 'yes' && form.company.length === 0,
        firstname: !loggedIn && form.firstname.length < 2,
        lastname: !loggedIn && form.lastname.length < 2,
        email: !loggedIn && (!isEmail(form.email) || form.email !== form.emailConfirm || !(await isNewEmail(form.email))),
        emailConfirm: !loggedIn && form.email !== form.emailConfirm,
        phone: form.phone.length !== 0 && !isMobilePhone(form.phone),
        password: !loggedIn && (!form.password || form.password.length < 6),
        rgpd: !form.rgpd
      };
      break ;
    default:
      break ;
  }

  if (errors && Object.values(errors).some(value => value)) {
    dispatch({ type: 'errors', value: errors });
    return { success: false, errors: errors };
  }
  return { success: true };
}

function initProfile(profile) {
  if (profile) {
    if (profile.hasCompany === undefined) {
      profile.hasCompany = profile.company && profile.company.length > 0 ? 'yes' : 'no';
    }
    if (profile.international === undefined) {
      profile.international = 'no';
    }
  }
  return profile;
}

function Candidate() {
  const classes = useStyles();
  const { profile: profileData, synchronize } = useProfile();
  const profile = useMemo(() => initProfile(profileData), [profileData]);

  if (profile && profile.disabled) {
    return (
      <div className={classes.root}>
        <main className={classes.container}>
          <UserSheet profile={profile} synchronize={synchronize} />
        </main>
      </div>
    );
  }

  return (
    <Form
      type={TYPE}
      profile={profile}
      defaultProfile={defaultProfile}
      synchronize={synchronize}
      validate={validate}
      header={profile ? <UserSheet profile={profile} synchronize={synchronize} /> : null}
    >
      <CandidateStep1 />
      <CandidateStep2 />
      <CandidateStep3 />
    </Form>
  );
}

export default Candidate;
