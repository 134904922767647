import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import MatchSettings from './MatchSettings';
import ContactSettings from './ContactSettings';
import SchedulerSettings from './SchedulerSettings';
import Tools from './Tools';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

export default function Settings() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <MatchSettings />
      </Paper>
      <Paper className={classes.paper}>
        <ContactSettings />
      </Paper>
      <Paper className={classes.paper}>
        <SchedulerSettings />
      </Paper>
      <Paper className={classes.paper}>
        <Tools />
      </Paper>
    </div>
  );
}
