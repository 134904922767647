import { IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import React from 'react';
import { FixedSizeList } from 'react-window';
import { AutoSizer } from 'react-virtualized';
import InfiniteLoader from 'react-window-infinite-loader';
import clsx from 'clsx';
import { Skeleton } from '@material-ui/lab';
import { FilterList } from '@material-ui/icons';
import { blue } from '@material-ui/core/colors';

const SCROLL_WIDTH = 24;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  table: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  thead: {
    width: '100%',
  },
  tbody: {
    height: '100%',
  },
  content: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    minWidth: '100%',
    maxWidth: '100%',
    width: '100%',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 0,
    flexShrink: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: theme.spacing(0, 2),
  },
  scrollSpace: {
    padding: 0,
    margin: 0,
  },
  expandingCell: {
    flex: 1
  },
  skeleton: {
    margin: 0,
    padding: 0,
    flex: 1,
  },
}));

const itemKey = (index, data) => index < data.length ? data[index].id : 'loading' + index;

function ContentWrapper({
  items,
  loadMoreItems,
  threshold,
  itemSize,
  className,
  loaderRef,
  itemCount,
  tableWidth,
  columns,
  Row,
  onDoubleClick,
}) {
  const isItemLoaded = index => index < items.length;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
          threshold={threshold}
          ref={loaderRef}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              className={className}
              height={height}
              width={Math.max(tableWidth, width)}
              itemCount={itemCount}
              itemSize={itemSize}
              itemKey={itemKey}
              itemData={items}
              onItemsRendered={onItemsRendered}
              ref={ref}
            >
              {({ index, style }) =>
                <Row
                  item={isItemLoaded(index) ? items[index] : null}
                  style={style}
                  index={index}
                  itemSize={itemSize}
                  columns={columns}
                  onDoubleClick={onDoubleClick}
                />
              }
            </FixedSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

function getDataValue(data, key) {
  if (typeof data === 'object') {
    const p = key.indexOf('.');
    if (p >= 0) {
      return getDataValue(data[key.substring(0, p)], key.substring(p + 1));
    }
    return data[key];
  }
  return null;
}

function formatData(rowData, column) {
  if (column.render) {
    return column.render(rowData);
  }
  const data = getDataValue(rowData, column.field);
  if (!data) {
    return '';
  }
  if (column.lookup) {
    return column.lookup[data] || data;
  }
  if (data instanceof Date) {
    return <span title={data.toLocaleString()}>{data.toLocaleDateString()}</span>;
  }
  return data.toString();
}

function ContentRow({ item, style, index, columns, itemSize, onDoubleClick }) {
  const classes = useStyles();
  const data = item ? item.data() : {};
  data.metadata = item?.metadata || {};
  const renderCell = item
    ? column => formatData(data, column)
    : column => <Skeleton width={column.width} className={classes.skeleton} />

  return (
    <TableRow
      component="div"
      className={classes.row}
      style={style}
      hover
      onDoubleClick={onDoubleClick ? () => onDoubleClick(data) : undefined}
    >
      {columns.map((column, colIndex) => {
        const content = renderCell(column);
        const title = typeof content === 'string' ? content : undefined;
        const key = `${data.id || 'loading'}-${index}-${column.field || colIndex}`;
        return (
          <TableCell
            key={key}
            component="div"
            variant="body"
            align={column.align}
            className={clsx(
              classes.cell,
              !column.width && classes.expandingCell
            )}
            style={{
              minWidth: column.minWidth,
              flexBasis: column.width || false,
              height: itemSize,
              justifyContent: column.align
            }}
            title={title}
          >
            {content}
          </TableCell>
        )
      })}
    </TableRow>
  );
}

function InfiniteTable({
  className,
  columns,
  rowSize = 48,
  orderBy,
  orderDirection,
  onOrderBy,
  elevation,
  ...props
}) {
  const classes = useStyles();
  const tableWidth = React.useMemo(
    () => columns.reduce((width, col) => width + (col.width || col.minWidth || 0), 0) + SCROLL_WIDTH,
    [columns]
  );

  return (
    <Paper className={clsx(className, classes.root)} elevation={elevation}>
      <Table component="div" className={classes.table}>
        <TableHead component="div" className={classes.thead}>
          <TableRow component="div" className={classes.row}>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                component="div"
                className={clsx(
                  classes.cell,
                  classes.column,
                  !column.width && classes.expandingCell
                )}
                style={{
                  minWidth: column.minWidth,
                  flexBasis: column.width || false,
                  height: rowSize,
                  justifyContent: column.align
                }}
                scope="col"
              >
                {column.field && column.sort !== false
                  ? <TableSortLabel
                      disabled={!column.field || column.sort === false}
                      active={column.field && orderBy === column.field}
                      direction={orderDirection}
                      onClick={onOrderBy ? () => onOrderBy(column.field) : null}
                    >
                      {column.title}
                    </TableSortLabel>
                  : column.title
                }
                {column.filter &&
                  <IconButton style={column.active ? { color: blue[500] } : null} size="small" edge="start" onClick={column.filter.bind(null, column)}>
                    <FilterList fontSize="inherit" />
                  </IconButton>
                }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody component="div" className={classes.tbody}>
          <ContentWrapper
            {...props}
            className={classes.content}
            itemSize={rowSize}
            tableWidth={tableWidth}
            columns={columns}
            Row={ContentRow}
          />
        </TableBody>
      </Table>
    </Paper>
  );
}

export default InfiniteTable;
