import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, makeStyles, TextField } from '@material-ui/core';
import { CollectionSelect } from '../Collection';
import { FormSection } from '../inputs/FormSections';
import { getDegreeList } from '../../utils/dataProvider';
import { useTranslation } from 'react-i18next';
import { connectToForm } from '../../contexts/FormContext';
import DynamicList from '../commons/DynamicList';
import { sortByName } from '../../utils/formUtils';

const useStyles = makeStyles((theme) => ({
  rowContent: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    flex: '1',
    padding: theme.spacing(1),
    minWidth: '250px'
  },
}));

const Degree = React.memo(({ value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const degreeList = React.useMemo(() => getDegreeList(t), [t]);

  const handleChange = (name, value) => {
    onChange({ name, value });
  };

  return (
    <div className={classes.rowContent}>
      <TextField
        className={classes.item}
        variant="standard"
        value={value.degree}
        onChange={(event) => handleChange('degree', event.target.value)}
        select
        label={t('form.degreeLevel')}
        size="small"
        fullWidth
      >
        { degreeList.map(item =>
          <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        )}
      </TextField>
      <CollectionSelect
        className={classes.item}
        collection="disciplines"
        sort={sortByName}
        value={value.discipline}
        onChange={(v) => handleChange('discipline', v)}
        label={t('form.discipline')}
        size="small"
        variant="standard"
        fullWidth
      />
    </div>
  );
});

function DegreeList({ name, title, value, error, dispatch }) {

  const handleChange = (data, index) => {
    const newValues = [...value];
    newValues[index][data.name] = data.value;
    dispatch({ type: 'change', value: newValues, name });
  };

  const handleNewRow = () => {
    dispatch({ type: 'change', value: [...value, { degree: '', discipline: '' }], name });
  };

  const handleRemoveRow = (index) => {
    const newValues = [...value];
    newValues.splice(index, 1);
    dispatch({ type: 'change', value: newValues, name });
  };

  return (
    <FormSection id={name} title={title} error={error}>
      <DynamicList
        Component={Degree}
        values={value}
        onChange={handleChange}
        onNewRow={handleNewRow}
        onDeleteRow={handleRemoveRow}
        max={5}
      />
    </FormSection>
  );
}

DegreeList.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default connectToForm(React.memo(DegreeList));
