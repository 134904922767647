import React from 'react';
import { Grid, TextField, InputAdornment, makeStyles } from '@material-ui/core';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { connectToForm } from '../../contexts/FormContext';
import { useTranslation } from 'react-i18next';
import { FormSection } from './FormSections';
import { Email, Phone, Lock } from '@material-ui/icons';
import { normalizePhoneNumber } from '../../utils/formUtils';
import { useSession } from '../../api/session';

const useStyles = makeStyles((theme) => ({
  adornment: {
    paddingRight: theme.spacing(1),
  },
}));

function getEmailErrorText(email, emailConfirm) {
  if (email.length === 0) {
    return 'form.required';
  }
  if (!isEmail(email)) {
    return 'form.format';
  }
  if (email !== emailConfirm) {
    return 'form.emailConfirm';
  }
  return 'form.alreadyInUse';
}

function preventPaste(event) {
  event.preventDefault();
}

function EmailSection({ value, error, displayConfirm, confirm, errorConfirm, dispatch, disabled, sm, xs}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const errorText = error ? t(getEmailErrorText(value, confirm)) : undefined;

  const validateEmails = disabled ? undefined : (email, confirmEmail) => {
    dispatch({ type: 'error', name: 'email', value: !isEmail(email) || confirmEmail !== email });
    dispatch({ type: 'error', name: 'emailConfirm', value: confirmEmail !== email });
  };

  const handleEmail = disabled ? undefined : (event) => {
    const value = event.target.value;
    dispatch({ type: 'change', name: 'email', value });
    validateEmails(value, confirm);
  };

  const handleEmailConfirm = disabled ? undefined : (event) => {
    const confirm = event.target.value;
    dispatch({ type: 'change', name: 'emailConfirm', value: confirm });
    validateEmails(value, confirm);
  };

  return (
    <>
    <Grid item sm={sm} xs={xs}>
      <TextField
        label={t('common.email')}
        id="email"
        name="email"
        autoComplete="email"
        fullWidth
        variant="outlined"
        value={value}
        onChange={handleEmail}
        disabled={disabled}
        error={error}
        helperText={errorText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.adornment}>
              <Email />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
    { displayConfirm &&
    <Grid item sm={sm} xs={xs}>
      <TextField
        label={t('common.emailConfirm')}
        id="emailConfirm"
        name="emailConfirm"
        autoComplete="off"
        fullWidth
        variant="outlined"
        value={confirm || ''}
        onChange={handleEmailConfirm}
        onPaste={preventPaste}
        disabled={disabled}
        error={errorConfirm}
        helperText={errorConfirm && t('form.emailConfirm')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.adornment}>
              <Email />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
    }
    </>
  );
}

function Profile({
  title, dispatch, firstname, lastname, email, emailConfirm, phone, password,
  firstnameError, lastnameError, emailError, emailConfirmError, phoneError, passwordError
}) {
  const classes = useStyles();
  const { user } = useSession();
  const { t } = useTranslation();
  const phoneErrorText = phoneError ? (phone.length === 0 ? t('form.required') : t('form.format') ) : undefined;

  const handleChange = (event, name) => {
    const value = event.target.value;
    dispatch({ type: 'change', value, name });
  };

  const handlePhone = (event) => {
    const value = event.target.value;
    if (phoneError && isMobilePhone(value)) {
      dispatch({ type: 'error', name: 'phone', value: false });
    }
    dispatch({ type: 'change', name: 'phone', value });
  };

  const validatePhone = () => {
    const value = normalizePhoneNumber(phone);
    dispatch({ type: 'change', name: 'phone', value });
    dispatch({ type: 'error', name: 'phone', value: value.length > 0 && !isMobilePhone(value) });
  };

  const handlePassword = user ? undefined : (event) => {
    dispatch({ type: 'change', name: 'password', value: event.target.value });
  };

  return (
    <FormSection title={title}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <TextField
            label={t('common.firstname')}
            id="firstname"
            name="firstname"
            autoComplete="given-name"
            fullWidth
            variant="outlined"
            value={firstname}
            disabled={Boolean(user)}
            onChange={(event) => handleChange(event, 'firstname')}
            error={firstnameError}
            helperText={firstnameError && t('form.required')}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            label={t('common.lastname')}
            id="lastname"
            name="lastname"
            autoComplete="family-name"
            fullWidth
            variant="outlined"
            value={lastname}
            disabled={Boolean(user)}
            onChange={(event) => handleChange(event, 'lastname')}
            error={lastnameError}
            helperText={lastnameError && t('form.required')}
          />
        </Grid>
        <EmailSection
          sm={12} xs={12}
          value={email}
          error={emailError}
          displayConfirm={!user}
          confirm={emailConfirm}
          errorConfirm={emailConfirmError}
          dispatch={dispatch}
          disabled={Boolean(user)}
        />
        <Grid item sm={12} xs={12}>
          <TextField
            label={t('common.phone')}
            id="phone"
            name="phone"
            autoComplete="tel-national"
            fullWidth
            variant="outlined"
            value={phone}
            onChange={handlePhone}
            onBlur={validatePhone}
            error={phoneError}
            helperText={phoneErrorText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.adornment}>
                  <Phone />
                </InputAdornment>
              ), 
            }}
          />
        </Grid>
        {!user && 
        <Grid item sm={12} xs={12}>
          <TextField
            label={t('common.password')}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={handlePassword}
            error={passwordError}
            helperText={passwordError && t('form.passwordLength')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.adornment}>
                  <Lock />
                </InputAdornment>
              ), 
            }}
          />
        </Grid>
        }
      </Grid>
    </FormSection>
  );
}

export default connectToForm(React.memo(Profile), {
  names: ['firstname', 'lastname', 'email', 'emailConfirm', 'phone', 'password'],
  errors: ['firstname', 'lastname', 'email', 'emailConfirm', 'phone', 'password'],
});
