import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Routes from './Routes';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { useAuth, SessionProvider } from './api/session';
import GlobalLoading from './components/commons/GlobalLoading';
import RouteThemeProvider from './themes/RouteThemeProvider';
import defaultTheme from './themes/main';
import candidateTheme from './themes/candidate';
import recruiterTheme from './themes/recruiter';
import CookieConsent from './components/CookieConsent';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
}));

const THEMES = {
  default: defaultTheme,
  '/candidate': candidateTheme,
  '/recruiter': recruiterTheme,
};

function App() {
  const classes = useStyles();
  const session = useAuth();

  return (
    <Router>
      <RouteThemeProvider themes={THEMES}>
        <CssBaseline />
        <Suspense fallback={<GlobalLoading />}>
          <div className={classes.root}>
            <SessionProvider session={session}>
              <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <NavBar />
                { !session.initialized
                ? <GlobalLoading />
                : <Routes />
                }
                <Route render={({ location }) => location.pathname.startsWith('/admin') ? null : <Footer />} />
              </SnackbarProvider>
            </SessionProvider>
          </div>
          <CookieConsent />
        </Suspense>
      </RouteThemeProvider>
    </Router>
  );
}

export default App;
