import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Tags from '../commons/Tags';
import { CollectionTags } from '../Collection';
import languageList from '../../utils/languages.json';
import { FormSection } from './FormSections';
import { useTranslation } from 'react-i18next';
import { connectToForm } from '../../contexts/FormContext';
import ProposeInput from './ProposeInput';

function nameSorter(a, b) {
  return a.name > b.name ? 1 : -1;
}

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export const SkillCategoryTags = ({ label, id, limit, value, category, onChange, propose, ...others }) => {
  const classes = useStyles();

  return (
    <div className={classes.rowContainer}>
      <CollectionTags
        id={id}
        label={label}
        placeholder
        collection="skills"
        sort={nameSorter}
        filter={['category', category]}
        value={value}
        onChange={onChange}
        limit={limit}
        moreData={propose && propose.name}
        {...others}
      />
      { propose && <ProposeInput id={`${id}-propose`} {...propose} /> }
    </div>
  );
};

export const LanguageTags = ({ value, onChange, t, ...others }) =>
<Box paddingBottom="20px">
  <Tags
    id="languages"
    label={t('skillLabel.languages')}
    placeholderText={t('skillLabel.languagesPlaceholder')}
    data={languageList}
    value={value}
    onChange={onChange}
    filterOptions={createFilterOptions({ limit: 5, })}
    {...others}
  />
</Box>

export const PrimarySkills = connectToForm(React.memo(({ limit, namespace, expert, proficient, novice, dispatch, primaryError, ...props }) => {
  const { t } = useTranslation();

  const handleChange = (value, name) => {
    dispatch({ type: 'change', value, name });
  }

  return (
    <FormSection id="primary" title={t(`${namespace}.activities`)} error={primaryError}>
      <SkillCategoryTags
        id="expert"
        label={t(`${namespace}.skillLabel.expert`, { limit })}
        value={expert}
        blacklist={[...proficient, ...novice]}
        onChange={(v) => handleChange(v, 'expert')}
        category="activities"
        limit={limit}
        propose={{
          name: 'newActivitiesExpert',
          title: t('form.missingSkillTitle'),
          tooltip: t('form.missingSkillTooltip')
        }}
        {...props}
      />
      <SkillCategoryTags
        id="proficient"
        label={t(`${namespace}.skillLabel.proficient`, { limit })}
        value={proficient}
        blacklist={[...expert, ...novice]}
        onChange={(v) => handleChange(v, 'proficient')}
        category="activities"
        limit={limit}
        propose={{
          name: 'newActivitiesProficient',
          title: t('form.missingSkillTitle'),
          tooltip: t('form.missingSkillTooltip')
        }}
        {...props}
      />
      <SkillCategoryTags
        id="novice"
        label={t(`${namespace}.skillLabel.novice`, { limit })}
        value={novice}
        blacklist={[...proficient, ...expert]}
        onChange={(v) => handleChange(v, 'novice')}
        category="activities"
        limit={limit}
        propose={{
          name: 'newActivitiesNovice',
          title: t('form.missingSkillTitle'),
          tooltip: t('form.missingSkillTooltip')
        }}
        {...props}
      />
    </FormSection>
  );
}), {
  names: ['expert', 'proficient', 'novice'],
  errors: ['primary']
});

export const ComplementarySkills = connectToForm(React.memo(({
  limit, namespace, softs, tools, languages, dispatch, softsError, toolsError, languagesError, ...props
}) => {
  const { t } = useTranslation();
  const error = softsError || toolsError || languagesError;

  const handleChange = (value, name) => {
    dispatch({ type: 'change', value, name });
  }

  return (
    <FormSection title={t(`${namespace}.complementary`)} error={error}>
      <SkillCategoryTags
        id="softs"
        label={t(`skillLabel.softs`, { limit }) }
        value={softs}
        onChange={(v) => handleChange(v, 'softs')}
        category="softs"
        error={softsError}
        limit={limit}
        propose={{
          name: 'newSofts',
          title: t('form.missingSkillTitle'),
          tooltip: t('form.missingSkillTooltip')
        }}
        {...props}
      />
      <SkillCategoryTags
        id="tools"
        label={t(`skillLabel.tools`, { limit })}
        value={tools}
        onChange={(v) => handleChange(v, 'tools')}
        category="tools"
        error={toolsError}
        limit={limit}
        propose={{
          name: 'newTools',
          title: t('form.missingSkillTitle'),
          tooltip: t('form.missingSkillTooltip')
        }}
        {...props}
      />
      <LanguageTags
        t={t}
        value={languages}
        onChange={(v) => handleChange(v, 'languages')}
        error={languagesError}
      />
    </FormSection>
  );
}), {
  names: ['softs', 'tools', 'languages'],
  errors: ['softs', 'tools', 'languages']
});
