import React from 'react';
import { List, ListItem, ListItemText, Paper, makeStyles, Collapse, Icon, ListItemIcon } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import GlobalLoading from '../../commons/GlobalLoading';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useCollection } from '../../../api/collection';
import TemplateEditor from './TemplateEditor';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    }
  },
  menu: {
    margin: theme.spacing(1),
  },
  content: {
    flexGrow: 1,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));

const OPTIONS = {
  listen: true
};

const TEMPLATES_CONFIG = {
  admin: [
    ['admin-welcome', 'face'],
    ['admin-message', 'message'],
  ],
  candidate: [
    ['candidate-welcome', 'face'],
    ['candidate-new-match', 'assignment'],
    ['candidate-reminder-match', 'assignment_return'],
    ['candidate-last-reminder-match', 'assignment_late'],
    ['candidate-success-match', 'assignment_turned_in'],
    ['candidate-failed-match', 'not_interested'],
    ['candidate-follow-match', 'help'],
    ['candidate-last-follow-match', 'help_outline'],
    ['candidate-inactivity', 'notifications_off'],
  ],
  recruiter: [
    ['recruiter-welcome', 'face'],
    ['recruiter-new-match', 'assignment'],
    ['recruiter-reminder-match', 'assignment_return'],
    ['recruiter-last-reminder-match', 'assignment_late'],
    ['recruiter-success-match', 'assignment_turned_in'],
    ['recruiter-failed-match', 'not_interested'],
    ['recruiter-follow-match', 'help'],
    ['recruiter-last-follow-match', 'help_outline'],
    ['recruiter-inactivity', 'notifications_off'],
  ],
};

export default function EmailTemplateList() {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading, collection } = useCollection('templates', OPTIONS);
  const [template, setTemplate] = React.useState(null);
  const [expand, setExpand] = React.useState({});

  if (loading) {
    return <GlobalLoading />;
  }

  const handleChange = (newValue) => {
    setTemplate(newValue);
  };

  const handleSave = () => {
    if (template) {
      // template.html = DOMPurify.sanitize(template.html); // Issue with handlebars
      return collection.updateDocument(template.id, template)
        .then(() => enqueueSnackbar(t('action.saveSuccess'), { variant: "success"}))
        .catch((error) => enqueueSnackbar(error.message, { variant: "error" }));
    }
    return Promise.resolve();
  };

  const handleExpand = (category) => () => {
    setExpand({...expand, [category]: !expand[category] });
  };

  const handleClick = (name) => () => {
    setTemplate(data.find(item => item.id === name));
  };

  return (
    <div className={classes.root}>
      <List component={Paper} className={classes.menu}>
        { Object.entries(TEMPLATES_CONFIG).map(([category, config]) =>
          <React.Fragment key={category}>
            <ListItem button onClick={handleExpand(category)}>
              <ListItemText primary={t(`email.${category}`)} />
              {expand[category] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expand[category]}>
            { config.map(([name, icon]) =>
              <List component="div" key={name} disablePadding>
                <ListItem className={classes.nested} onClick={handleClick(name)} button selected={template && template.id === name}>
                  <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
                  <ListItemText primary={t(`email.${name}`)} />
                </ListItem>
              </List>
            )}
            </Collapse>
          </React.Fragment>
        )}
      </List>
      <Paper className={classes.content}>
        <TemplateEditor template={template} onChange={handleChange} onSave={handleSave} />
      </Paper>
    </div>
  );
}
