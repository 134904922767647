import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, Typography, makeStyles, CircularProgress, withWidth, isWidthDown } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  title: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  completeMessage: {
    color: theme.palette.success.main,
    margin: theme.spacing(3, 2, 2),
  }
}));

function IrreversibleConfirm({
  open,
  onClose,
  onConfirm,
  title,
  subTitle,
  message,
  keyword,
  cancelBtn,
  confirmBtn,
  closeBtn,
  loading,
  error,
  completed,
  completeMessage,
  width,
}) {
  const classes = useStyle();
  const [value, setValue] = React.useState('');
  const fullscreen = isWidthDown('xs', width);

  useEffect(() => {
    // Reset text on open/close
    setValue('');
  }, [open]);

  const handleInput = (event) => {
    setValue(event.target.value?.toUpperCase());
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress style={{ textAlign: 'center' }} />
        </div>
      );
    } else if (completed) {
      return (
        <DialogContentText className={classes.completeMessage}>{completeMessage}</DialogContentText>
      );
    }
    return (
      <Fragment>
        <DialogContentText>{message}</DialogContentText>
        { error
        ? <Typography variant="body1" color="error">{error}</Typography>
        : <TextField
            autoFocus
            fullWidth
            margin="dense"
            variant="outlined"
            placeholder={keyword}
            value={value}
            onChange={handleInput}
            error={Boolean(value && !keyword.startsWith(value))}
            disabled={loading}
          />
        }
      </Fragment>
    );
  };

  const renderActions = () => {
    if (completed) {
      return (
        <Button onClick={onClose} color="primary" variant="contained">
          {closeBtn}
        </Button>
      );
    }
    return (
      <Fragment>
        <Button onClick={onClose} color="primary" disabled={loading}>
          {cancelBtn}
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" disabled={loading || value !== keyword}>
          {confirmBtn}
        </Button>
      </Fragment>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullscreen}>
      <DialogTitle disableTypography className={classes.title}>
        <Typography variant="h5" gutterBottom>{title}</Typography>
        <Typography variant="body1">{subTitle}</Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        {renderActions()}
      </DialogActions>
    </Dialog>
  );
}

IrreversibleConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  message: PropTypes.string,
  keyword: PropTypes.string.isRequired,
  cancelBtn: PropTypes.string,
  confirmBtn: PropTypes.string,
  closeBtn: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  completed: PropTypes.bool,
  completeMessage: PropTypes.string,
};

export default withWidth()(IrreversibleConfirm);
