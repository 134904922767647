import React from 'react';
import Consent from 'react-cookie-consent';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useTheme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

function CookieConsent() {
  const { t } = useTranslation('cookie');
  const theme = useTheme();

  return (
    <Consent
      buttonText={t('button')}
      style={{
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.getContrastText(theme.palette.grey[800]),
        justifyContent: 'center',
        alignItems: 'center',
        ...theme.typography.body2
      }}
      contentStyle={{
        flex: 'initial',
        margin: 'auto',
        padding: '15px',
      }}
      buttonStyle={{
        borderRadius: theme.shape.borderRadius,
        backgroundColor: blue[500],
        color: theme.palette.getContrastText(blue[500]),
        ...theme.typography.button
      }}
    >
      <Trans t={t} i18nKey="message">
        En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies afin d’améliorer votre expérience et optimiser nos sites et services. Pour en savoir plus, consultez notre <Link href="/legal#privacy" style={{ color: blue[500] }}>politique de confidentialité</Link>
      </Trans>
    </Consent>
  );
}

export default CookieConsent;
