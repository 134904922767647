import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import dataConverter from './dataConverter';

export function getUser(uid) {
  return firebase.firestore().collection('users').withConverter(dataConverter).doc(uid).get();
}

export function getUserData(uid, jobId) {
  return getUser(uid).then(doc => {
    const data = doc.data();
    if (doc.get('type') === 'recruiter') {
      if (jobId) {
        return doc.ref.collection('jobs').doc(jobId).withConverter(dataConverter).get()
          .then(job => {
            if (job.exists) {
              return {...data, ...job.data()};
            }
            return data;
          });
      } else {
        data.jobs = {};
        return doc.ref.collection('jobs').orderBy('created', 'asc').withConverter(dataConverter).get()
          .then(jobs => {
            jobs.docs.forEach(job => {
              if (job.exists) {
                data.jobs[job.id] = job.data();
              }
            });
            return data;
          });
        }
    }
    return data;
  });
}

export function getUserByEmail(email) {
  return firebase.firestore().collection('users').withConverter(dataConverter).where('email', '==', email).limit(1).get()
  .then(snap => {
    if (snap.empty || !snap.docs[0].exists) {
      return null;
    }
    return snap.docs[0];
  })
}

export function updateUser(profile) {
  const {
    id, // Extract id
    email, emailNormalized, firstname, lastname, password, rgpd, created, // Extract unmutable data
    ...data
  } = profile;
  const updated = new Date();

  // Recruiter
  if (profile.jobId) {
    const {
      jobId, // Extract job id
      phone, // Only mutable field from profile
      jobs, // Extract job list
      disabled, // Profile disabled will be used for job default disabled status
      jobDisabled, // Extract job disabled
      ...jobData
    } = data;
    const userRef = firebase.firestore().collection('users').doc(id);
    if (jobId === 'new') {
      return Promise.all([
        userRef.withConverter(dataConverter).update({ phone, updated }),
        userRef.collection('jobs').doc().withConverter(dataConverter).set({ ...jobData, created: updated, updated, disabled: Boolean(disabled) }),
      ]);
    } else {
      return Promise.all([
        userRef.withConverter(dataConverter).update({ phone, updated }),
        userRef.collection('jobs').doc(jobId).withConverter(dataConverter).update({ ...jobData, updated, disabled: Boolean(disabled) }),
      ]);
    }
  }

  // Candidate
  return firebase.firestore().collection('users').withConverter(dataConverter).doc(id).update({...data, updated});
}

export function deleteJob(userId, jobId) {
  return firebase.firestore().collection('users').doc(userId).collection('jobs').doc(jobId).delete();
}

const user_cache = {};

function userCacheId(uid, job) {
  return job ? `${uid}-${job}` : uid;
}

export function useUser({ uid, job, cache }) {
  const [error, setError] = React.useState(false);
  const [user, setUser] = React.useState((cache && user_cache[userCacheId(uid, job)]) || null);
  const [loading, setLoading] = React.useState(!Boolean(user));

  React.useEffect(() => {
    if (uid) {
      const cacheId = userCacheId(uid, job);
      if (cache && user_cache[cacheId]) {
        setUser(user_cache[cacheId]);
        setLoading(false);
      } else {
        const controller = new AbortController();
        const signal = controller.signal;
        getUserData(uid, job)
          .then(data => {
            if (!signal.aborted) {
              setUser(data);
              if (cache) {
                user_cache[cacheId] = data;
              }
            }
          })
          .catch(error => !signal.aborted && setError(error))
          .finally(_ => !signal.aborted && setLoading(false));
        return () => controller.abort();
      }
    }
  }, [uid, job, cache]);

  return {
    loading,
    user,
    error,
  }
}

export function activateProfile(uid, jobId) {
  let doc = firebase.firestore().collection('users').doc(uid);
  if (jobId) {
    doc = doc.collection('jobs').doc(jobId);
  }
  return doc.withConverter(dataConverter).update({ disabled: false, updated: new Date() });
}

export function deactivateProfile(uid, jobId) {
  let doc = firebase.firestore().collection('users').doc(uid);
  if (jobId) {
    doc = doc.collection('jobs').doc(jobId);
  }
  return doc.withConverter(dataConverter).update({ disabled: true, updated: new Date() });
}
