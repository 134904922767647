import { createMuiTheme } from '@material-ui/core';
import { frFR } from '@material-ui/core/locale';
import { grey } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    toolbar: '#fff',
    primary: {
      main: grey[700],
    },
    secondary: {
      main: grey[700],
    },
    text: {
      primary: grey[700],
    },
  },
}, frFR);

export default theme;
