import React from 'react';
import isURL from 'validator/lib/isURL';
import { TextField, Grid } from '@material-ui/core';
import { LinkedIn, Link } from '@material-ui/icons';
import { FormSection } from '../inputs/FormSections';
import { connectToForm } from '../../contexts/FormContext';
import { useTranslation } from 'react-i18next';
import { isLinkedInUrl, normalizeLinkedInUrl } from '../../utils/formUtils';

function WebLinks({ title, dispatch, website, linkedIn, websiteError, linkedInError }) {
  const { t } = useTranslation();

  const handleWebsite = (event) => {
    const value = event.target.value;
    dispatch({ type: 'error', name: 'website', value: value.length > 0 && !isURL(value) });
    dispatch({ type: 'change', name: 'website', value });
  };

  const handleLinkedIn = (event) => {
    let value = event.target.value;
    dispatch({ type: 'error', name: 'linkedIn', value: value.length > 0 && !isLinkedInUrl(value) });
    dispatch({ type: 'change', name: 'linkedIn', value });
  };

  const handleLinkedInBlur = () => {
    if (linkedIn) {
      const normalized = normalizeLinkedInUrl(linkedIn);
      if (linkedIn !== normalized) {
        dispatch({ type: 'change', name: 'linkedIn', value: normalized });
        dispatch({ type: 'error', name: 'linkedIn', value: !isLinkedInUrl(normalized) });
      }
    }
  };

  return (
    <FormSection title={title}>
      <Grid container spacing={1} alignItems="flex-end">
        <Grid container spacing={2} item xs={12}>
          <Grid item>
            <Link />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="website"
              name="website"
              autoComplete="url"
              fullWidth
              placeholder={t('common.website')}
              value={website}
              onChange={handleWebsite}
              error={websiteError}
              helperText={websiteError && t('form.format')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          <Grid item>
            <LinkedIn />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="linkedin"
              name="linkedin"
              autoComplete="url"
              fullWidth
              placeholder={t('common.LinkedIn')}
              value={linkedIn}
              onChange={handleLinkedIn}
              error={linkedInError}
              helperText={linkedInError && t('form.format')}
              onBlur={handleLinkedInBlur}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormSection>
  );
}

export default connectToForm(React.memo(WebLinks), {
  names: ['website', 'linkedIn'],
  errors: ['website', 'linkedIn'],
});
