import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';
import AsyncButton from '../../commons/AsyncButton';
import { useTranslation } from 'react-i18next';
import { setting } from './utils';
import isEmail from 'validator/lib/isEmail';

function ContactSettings({ loading, data, error, setData, onSave, saving }) {
  const { t } = useTranslation('admin');
  const { email } = data;
  const [errorText, setErrorText] = React.useState(null);

  const handleChange = (event) => {
    setData({ email: event.target.value });
  };

  const handleSave = () => {
    if (isEmail(email)) {
      setErrorText(null);
      onSave();
    } else {
      setErrorText(t('invalidEmail'));
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container item>
        <Typography variant="h6">{t('contact')}</Typography>
      </Grid>
      { error &&
        <Grid item>
          <Typography variant="body1" color="error">{error.message}</Typography>
        </Grid>
      }
      { !loading &&
        <Grid item xs={10} sm={8} md={5}>
          <TextField
            label={t('contactEmail')}
            value={email}
            onChange={handleChange}
            fullWidth
            error={Boolean(errorText)}
            helperText={errorText}
          />
        </Grid>
      }
      <Grid container item>
        <AsyncButton size="small" loading={saving} onClick={handleSave}>
          {t('action.save')}
        </AsyncButton>
      </Grid>
    </Grid>
  );
}

export default React.memo(setting(ContactSettings, 'contact'));
