import React from 'react';
import { List, ListItem, ListItemSecondaryAction, IconButton, ListItemText, Collapse, ListItemIcon, Badge, Dialog, DialogTitle, DialogContent, Grid, Typography, makeStyles } from '@material-ui/core';
import { Delete, Done, ExpandMore, ExpandLess, AssignmentInd, BusinessCenter, ListAlt } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useCollection } from '../../api/collection';
import { deleteSubmission, validateSubmission } from '../../api/admin/submission';
import Widget from './Widget';
import UserName from './UserName';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: '100%',
    flex: 1,
  },
}));

function SubmissionProperties({ name, users, created, category, ...dialogProps }) {
  const { t } = useTranslation('admin');

  const Row = ({name, value}) =>
  <Grid item container spacing={2}>
    <Grid item xs={4}>{name}</Grid>
    <Grid item xs={8}>{value}</Grid>
  </Grid>;

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{name}</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          { created &&
          <Row name={t('table.created')} value={created.toLocaleString()} />
          }
          { users &&
          <Row name={t('table.by')} value={
            users.map((user) =>
              <UserName key={user} uid={user} link />
            )}
          />
          }
          { category &&
          <Row name={t('category')} value={t(category)} />
          }
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

function SubmissionList({ name, data, icon, onProperties, groupBy }) {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation('admin');
  const [expand, setExpand] = React.useState(true);
  const [loading, setLoading] = React.useState({});
  
  if (!data || data.length === 0) {
    return null;
  }

  let sortedData = data;
  let groupName = '';

  if (groupBy) {
    sortedData = data.sort((a, b) => (a[groupBy] === b[groupBy] ? 0 : (a[groupBy] > b[groupBy] ? 1 : -1)));
  }

  const handleExpand = () => {
    setExpand(!expand);
  };

  const handleError = (error) => {
    enqueueSnackbar(error.message, { variant: 'error' });
  };

  const handleValidate = (item) => {
    setLoading({...loading, [item.id]: true});
    validateSubmission(name, item)
      .then(() => enqueueSnackbar(t('submissions.added', { name: item.name }), { variant: 'success' }))
      .catch(handleError)
      .finally(() => setLoading({...loading, [item.id]: false}));
  };

  const handleDelete = (item) => {
    setLoading({...loading, [item.id]: true});
    deleteSubmission(name, item)
      .catch(handleError)
      .finally(() => setLoading({...loading, [item.id]: false}));
  };

  const renderGroupName = (item) => {
    if (groupName !== item[groupBy]) {
      groupName = item[groupBy];
      return (
        <ListItem divider>
          <ListItemText><b>{t(groupName)}</b></ListItemText>
        </ListItem>
      );
    }
  };

  return (
    <List>
      <ListItem onClick={handleExpand} button>
        <ListItemIcon>
          <Badge badgeContent={sortedData.length} color="secondary">
            {icon}
          </Badge>
        </ListItemIcon>
        <ListItemText>{t(`${name}`)}</ListItemText>
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={handleExpand}>
            { expand ? <ExpandLess /> : <ExpandMore /> }
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={expand}>
      { sortedData.map((item) =>
        <React.Fragment key={item.id}>
          {groupBy && renderGroupName(item)}
          <ListItem key={item.id} dense button onClick={() => onProperties(item)}>
            <ListItemText>{item.name}</ListItemText>
            <ListItemSecondaryAction>
              <IconButton edge="end" size="small" onClick={() => handleValidate(item)} disabled={loading[item.id]}>
                <Done />
              </IconButton>
              <IconButton edge="end"  size="small" onClick={() => handleDelete(item)} disabled={loading[item.id]}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </React.Fragment>
      )}
      </Collapse>
    </List>
  );
};

const OPTIONS = { listen: true };

export default function Submissions() {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const [properties, setProperties] = React.useState({ open: false });
  const { data: newRoles } = useCollection('newRoles', OPTIONS);
  const { data: newSectors } = useCollection('newSectors', OPTIONS);
  const { data: newSkills } = useCollection('newSkills', OPTIONS);

  const handlePropertiesClose = () => {
    setProperties({...properties, open: false });
  };

  const handleShowProperties = (item) => {
    setProperties({
      open: true,
      ...item
    });
  };

  const isEmpty = () => {
    return (
      (newRoles === null || newRoles.length === 0) &&
      (newSectors === null || newSectors.length === 0) &&
      (newSkills === null || newSkills.length === 0)
    );
  };

  return (
    <Widget title={t('submissions.title')}>
      { isEmpty()
      ? <Typography variant="body2">{t('submissions.none')}</Typography>
      : <div className={classes.content}>
          <SubmissionProperties {...properties} onClose={handlePropertiesClose} />
          <SubmissionList name="newRoles" data={newRoles} icon={<AssignmentInd />} onProperties={handleShowProperties} />
          <SubmissionList name="newSectors" data={newSectors} icon={<BusinessCenter />} onProperties={handleShowProperties}  />
          <SubmissionList name="newSkills" data={newSkills} icon={<ListAlt />} onProperties={handleShowProperties} groupBy="category" />
        </div>
      }
    </Widget>
  );
}
