import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import ActivationButton from './ActivationButton';
import UserMatches from './UserMatches';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  rootDisabled: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.getContrastText(theme.palette.grey[600]),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  matches: {
  },
}));

function UserSheet({ profile, synchronize }) {
  const classes = useStyles();

  if (!profile) {
    return null;
  }

  return (
    <div className={clsx(classes.root, { [classes.rootDisabled]: profile.disabled })}>
      <div className={classes.titleContainer}>
        <Typography variant="h6" gutterBottom>{`${profile.firstname} ${profile.lastname}`}</Typography>
        <ActivationButton profile={profile} synchronize={synchronize} />
      </div>
      <UserMatches profile={profile} disabled={profile.disabled} className={classes.matches} />
    </div>
  );
}

export default React.memo(UserSheet);
