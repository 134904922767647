import isURL from 'validator/lib/isURL';
import normalizeEmail from 'validator/lib/normalizeEmail';

export const linkedInPrefix = 'https://www.linkedin.com/';

export function isLinkedInUrl(url) {
  return url.startsWith(linkedInPrefix) && isURL(url);
}

export function normalizeLinkedInUrl(value) {
  if (value) {
    return value.replace(/(^.*linkedin\.com\/)/ig, linkedInPrefix);
  }
  return '';
}

export function normalizePhoneNumber(value) {
  if (value) {
    return value.trim().replace(/\s+/g, '');
  }
  return '';
}

export function normalizeEmailAddress(email) {
  return normalizeEmail(email.trim(), {
    gmail_remove_dots: false,
  });
}

export function normalizeProfile(profile) {
  Object.entries(profile).forEach(([key, value]) => {
    if (typeof value === 'string') {
      profile[key] = value.trim();
    }
    if (Array.isArray(value)) {
      profile[key] = value.filter(el => !isEmptyObject(el));
    }
  });
  return profile;
}

function isEmptyObject(value) {
  if (typeof value === 'object') {
    return Object.values(value).every(el => !el);
  }
  return false;
}

export function sortByName(a, b) {
  if (a && a.name && b && b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
}

// Transform string to lower case and remove accentuated characters
export function normalizeString(str) {
  return str.toLowerCase().normalize('NFD').replace(/[(),\u0300-\u036f]/g, '').replace('/', ' ');
}

export function isValidDate(date) {
  return date && date instanceof Date && !isNaN(date.getTime());
}
