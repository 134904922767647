import firebase from 'firebase/app';
import 'firebase/firestore';
import dataConverter from '../dataConverter';

const skillMap = {
  newTools: 'tools',
  newSofts: 'softs',
  newActivitiesExpert: 'expert',
  newActivitiesProficient: 'proficient',
  newActivitiesNovice: 'novice'
};

export function validateSubmission(fromCollection, item) {
  const toCollection = getTargetCollection(fromCollection);
  const db = firebase.firestore();
  const { name, category, users } = item;
  const data = category ? { name, category } : { name };
  
  data.created = data.updated = new Date();

  return db.collection(toCollection).withConverter(dataConverter)
    .add(data)
    .then((doc) => {
      const promises = [];
      if (users) {
        users.forEach(uid => {
          promises.push(
            updateUserSubmissions(uid, fromCollection, name, toCollection, doc.id)
          );
        });
      }
      return Promise.all(promises);
    })
    .then(() => db.collection(fromCollection).doc(item.id).delete());
}

export function deleteSubmission(fromCollection, item) {
  const { id, name, users } = item;
  
  return firebase.firestore().collection(fromCollection)
    .doc(id).delete()
    .then(() => {
      const promises = [];
      users.forEach(uid => {
        promises.push(
          updateUserSubmissions(uid, fromCollection, name)
        );
      });
      return Promise.all(promises);
    });
};

function updateUserSubmissions(uid, from, oldValue, to, newValue) {

  // Do the update on either the user or the job
  const doUpdate = (snapshot) => {
    const data = {};
    let fromValue;
    if (from === 'newSkills') { // Specific case for skills
      Object.entries(skillMap).forEach(([fromSkill, toSkill]) => {
        fromValue = snapshot.get(fromSkill);
        if (Array.isArray(fromValue) && fromValue.indexOf(oldValue) !== -1) {
          data[fromSkill] = fromValue.filter(v => v !== oldValue);
          if (newValue) {
            data[toSkill] = [...snapshot.get(toSkill), newValue];
          }
        }
      });
    } else { // default
      fromValue = snapshot.get(from);
      if (Array.isArray(fromValue)) {
        data[from] = fromValue.filter(v => v !== oldValue);
      }
      if (to) {
        const toValue = snapshot.get(to);
        if (Array.isArray(toValue)) {
          data[to] = [...toValue, newValue];
        }
      }
    }
    if (Object.keys(data).length > 0) {
      return snapshot.ref.update(data);
    }
    return Promise.resolve();
  };

  // Retrieve the user and jobs to launch the update
  return firebase.firestore().collection('users').doc(uid).get()
    .then(userSnap => {
      if (userSnap.exists) {
        if (userSnap.get('type') === 'recruiter' && !userSnap.get('contract')) {
          return userSnap.ref.collection('jobs').get()
            .then(jobs => {
              const promises = [];
              jobs.forEach(job => promises.push(doUpdate(job)));
              return Promise.all(promises);
            });
        } else {
          return doUpdate(userSnap);
        }
      }
    });
}

function getTargetCollection(fromCollection) {
  if (fromCollection.startsWith('new')) {
    return fromCollection.substring(3).toLowerCase();
  }
  throw new Error('Invalid collection "from"');
}
