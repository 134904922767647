import React, { useEffect, useState } from 'react';
import { Dialog, isWidthDown, withWidth } from '@material-ui/core';
import { useLocationHash } from '../../utils/location';

// Dialog using browser history for keeping track of the open/close state
// allowing to use the back button to close the dialog
function HistoryDialog({ children, open, name, width, fullscreen, maxWidth, onClose, ...props }) {
  const { hash } = useLocationHash();
  const [isOpened, setIsOpened] = useState(false);
  const isFullscreen = isWidthDown(fullscreen, width);

  useEffect(() => {
    if (open && !isOpened) {
      window.location.hash = `#${name}`;
      setIsOpened(true);
    } else if (!open && isOpened && hash === name) {
      window.history.back();
      setIsOpened(false);
    } else if (open && isOpened && hash !== name) {
      // Back button
      onClose();
      setIsOpened(false);
    }
  }, [open, name, hash, isOpened, onClose]);

  return (
    <Dialog
      open={isOpened}
      maxWidth={!isFullscreen && maxWidth}
      fullScreen={isFullscreen}
      onClose={onClose}
      {...props}
    >
      {children}
    </Dialog>
  );
}

export default withWidth()(HistoryDialog);
