import React from 'react';
import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import analytics from '../api/analytics';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    textAlign: 'center',
    marginTop: 'auto',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.getContrastText(theme.palette.grey[800]),
    font: theme.typography.body2.font,
    ...theme.typography.body2
  },
  nav: {
    textAlign: 'center',
    paddingRight: '2em',
  },
  list: {
    display: 'inline',
    margin: 0,
    padding: 0,
    '& li': {
      display: 'inline',
      '&::before': {
        content: '" "',
      },
      '&::after': {
        content: '" "',
        whiteSpace: 'normal',
        wordSpacing: '2em',
        backgroundImage: 'linear-gradient(white, white)',
        backgroundSize: '0.1em 1em',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      },
      '& a': {
        whiteSpace: 'nowrap',
        display: 'inline-block',
      }
    },
  }
}));

function Copyright() {
  return (
    <span>
      {'Copyright © '}
      <Link color="inherit" component={RouterLink} to="/">
        Design Link
      </Link>{' '}
      {new Date().getFullYear()}
    </span>
  );
}

function LegalLink({ children }) {
  const { t } = useTranslation('legal');
  return (
    <Link color="inherit" component={RouterLink} to={`/legal#${children}`}>
      {t(children)}
    </Link>
  );
}

const useShareStyle = makeStyles((theme) => ({
  root: {
    '& button': {
      margin: theme.spacing(2, 1, 0)
    }
  },
  button: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
  icon: {
    '& circle': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      '& circle': {
        fill: theme.palette.primary.light,
      },
    }
  }
}));

function Share({ url }) {
  const classes = useShareStyle();

  const handleClick = (method) => () => {
    analytics('share', { method, content_id: url });
  };

  return (
    <div className={classes.root}>
      <FacebookShareButton url={url} className={classes.button} onClick={handleClick('facebook')}>
        <FacebookIcon size={32} round className={classes.icon} />
      </FacebookShareButton>
      <TwitterShareButton url={url} className={classes.button} onClick={handleClick('twitter')}>
        <TwitterIcon size={32} round className={classes.icon} />
      </TwitterShareButton>
      <LinkedinShareButton url={url} className={classes.button} onClick={handleClick('linkedin')}>
        <LinkedinIcon size={32} round className={classes.icon} />
      </LinkedinShareButton>
    </div>
  );
}

function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <nav className={classes.nav}>
        <ul className={classes.list}>
          <li><Copyright /></li>
          <li><LegalLink>notice</LegalLink></li>
          <li><LegalLink>terms</LegalLink></li>
          <li><LegalLink>privacy</LegalLink></li>
        </ul>
      </nav>
      <Share url="https://designlink.fr/" />
    </footer>
  );
}

export default Footer;
