import React, { useEffect, useMemo, useState } from 'react';
import PropType from 'prop-types';
import ChartComponent from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import { useStatistics } from '../../../api/admin/statistics';
import { makeStyles, Popover, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { BarChart, CompassCalibration, DonutLarge, PieChart } from '@material-ui/icons';
import clsx from 'clsx';
import { useLocalState } from '../../../api/states';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
}));

function chartOptions(type, options, data, animation) {
  let leftPadding = 0;
  if (type === 'horizontalBar') {
    leftPadding = data.datasets?.reduce((result, dataset) =>
      Math.max(result, dataset.labels?.reduce(
        (result, label) => Math.max(result, label.length) || 0,
      0)),
    0) * 4;
  }
  return {
    animation: {
      duration: animation === false ? 0 : 1000
    },
    legend: {
      display: !['bar', 'horizontalBar'].includes(type),
      position: 'right',
      labels: {
        boxWidth: 15,
      },
    },
    tooltips: {
      callbacks: {
        label: (item, data) => {
          const label =
            data.datasets[item.datasetIndex].labels?.[item.index]
            || data.labels[item.index]
          return ` ${label}`;
        }
      },
    },
    scales: {
      xAxes: [{
        stacked: options?.stacked,
        gridLines: {
          display : false,
        },
        ticks: {
          display: type === 'bar'
        }
      }],
      yAxes: [{
        stacked: options?.stacked,
        gridLines: {
          display : false,
        },
        ticks: {
          display: type === 'horizontalBar'
        }
      }]
    },
    layout: {
      padding: {
        left: leftPadding,
      }
    },
    plugins: {
      labels: {
        render: !['bar', 'horizontalBar'].includes(type) ? 'label' : 'value',
        fontColor: '#fff',
        overlap: false,
      },
    },
  };
}

const CHART_TYPES = [
  { value: 'pie', icon: <PieChart /> },
  { value: 'doughnut', icon: <DonutLarge /> },
  { value: 'horizontalBar', icon: <BarChart style={{transform: 'rotate(90deg)'}} /> },
  { value: 'bar', icon: <BarChart /> },
  { value: 'polarArea', icon: <CompassCalibration /> },
];

function Chart({
  document,
  controller,
  name,
  defaultType = 'pie',
  title,
  className,
  ...props
}) {
  const classes = useStyles();
  const data = useMemo(() => controller.getChartConfig(name), [name, controller]);
  const [type, setType] = useLocalState(`chart_${name}`, data.defaultType || defaultType);
  const [contextMenu, setContextMenu] = useState(null);
  const [redraw, setRedraw] = useState(true);
  const [animation, setAnimation] = useState(true);
  const { data: stats } = useStatistics(document, controller, name);

  const handleOpenMenu = (event) => {
    event.preventDefault();
    if (contextMenu) {
      setContextMenu(null);
    } else {
      setContextMenu({ top: event.clientY, left: event.clientX });
    }
  };
  const handleCloseMenu = () => {
    setContextMenu(null);
  };

  const handleChartType = (event, newType) => {
    if (newType && newType !== type) {
      setType(newType);
      setRedraw(true);
    }
  };

  useEffect(() => {
    if (redraw) {
      setRedraw(false);
      setAnimation(true);
    } else {
      const handleResize = () => {
        setAnimation(false);
        setRedraw(true);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [redraw]);

  return (
    <div className={clsx([classes.root, className])} onContextMenu={handleOpenMenu}>
      {title && <Typography variant="overline" align="center" gutterBottom>{title}</Typography>}
      <ChartComponent
        type={type}
        data={stats}
        options={chartOptions(type, data.options, stats, animation)}
        redraw={redraw}
        {...props}
      />
      <Popover
        keepMounted
        open={contextMenu !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu}
      >
        <ToggleButtonGroup
          exclusive
          value={type}
          onChange={handleChartType}
          size="small"
        >
          {CHART_TYPES.map(({value, icon}) =>
            (!data.types || data.types.includes(value))
            ? <ToggleButton key={value} value={value}>{icon}</ToggleButton>
            : null
          )}
        </ToggleButtonGroup>
      </Popover>
    </div>
  );
}

Chart.propTypes = {
  document: PropType.object,
  controller: PropType.object.isRequired,
  name: PropType.string.isRequired,
  title: PropType.string,
};

export default Chart;
