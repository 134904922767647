import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Fab, IconButton, isWidthDown, Link, makeStyles, Typography, withWidth } from '@material-ui/core';
import { Add, Delete, Edit, LinkedIn } from '@material-ui/icons';
import { useCollection } from '../api/collection';
import PeopleDialog from './admin/PeopleDialog';
import Medium from '../resources/Medium';
import { useStorage } from '../api/storage';
import Expander from './commons/Expander';
import clsx from 'clsx';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const PEOPLE_WIDTH = 200;
const PEOPLE_HEIGHT = 290;
const PEOPLE_IMAGE_WIDTH = 240;
const PEOPLE_IMAGE_HEIGHT = 135;

function makeResponsiveHeight(theme, props, rowHeight) {
  const style = {};
  theme.breakpoints.keys.forEach(breakpoint => {
    if (breakpoint in props) {
      style[theme.breakpoints.up(breakpoint)] = {
        maxHeight: rowHeight * props[breakpoint]
      };
    }
  });
  return style;
}

const useStyle = makeStyles(theme => ({
  root: (props) => ({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: (PEOPLE_WIDTH + theme.spacing(4)) * 5,
    overflow: 'hidden',
    ...makeResponsiveHeight(theme, props, PEOPLE_HEIGHT + theme.spacing(2)),
  }),
  rootImageOnly: (props) => ({
    maxWidth: (PEOPLE_IMAGE_WIDTH + theme.spacing(6)) * 5,
    ...makeResponsiveHeight(theme, props, PEOPLE_IMAGE_HEIGHT + theme.spacing(4)),
  }),
  people: {
    position: 'relative',
    display: 'block',
    width: PEOPLE_WIDTH,
    height: PEOPLE_HEIGHT,
    textAlign: 'center',
    overflow: 'hidden',
    margin: theme.spacing(1),
    '&:hover $buttons': {
      opacity: 1,
    },
  },
  peopleImageOnly: {
    width: PEOPLE_IMAGE_WIDTH + theme.spacing(2),
    height: PEOPLE_IMAGE_HEIGHT + theme.spacing(2),
  },
  addPeople: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    display: 'inline-block',
    width: 140,
    height: 140,
    borderRadius: 70,
    objectFit: 'cover',
    margin: theme.spacing(1),
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.common.white,
  },
  square: {
    borderRadius: 0,
    objectFit: 'contain',
    width: PEOPLE_IMAGE_WIDTH,
    height: PEOPLE_IMAGE_HEIGHT,
    boxShadow: theme.shadows[2],
  },
  buttons: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    transition: theme.transitions.create('opacity'),
  }
}));

function People({
  displayName,
  title,
  title2,
  location,
  linkedin,
  medium,
  image,
  editable,
  onEdit,
  onDelete,
  variant,
  imageOnly,
  classes,
}) {
  const Image = image ? ({alt, ...props}) => <img alt={alt || ''} {...props}/> : (props) => <div {...props} />;

  return (
    <Zoom>
      <div className={clsx([classes.people, imageOnly && classes.peopleImageOnly])}>
        <Image src={image} alt={displayName} className={clsx([classes.image, variant && classes[variant]])} onClick={editable ? onEdit : null} />
        {!imageOnly && <Fade delay={300}><>
          <Typography variant="body1" style={{ fontWeight: 500 }}>{displayName}</Typography>
          {title && <Typography variant="body2" color="textSecondary">{title}</Typography>}
          {title2 && <Typography variant="body2" color="textSecondary">{title2}</Typography>}
          {location && <Typography variant="body1">{location}</Typography>}
          {linkedin && <Link href={linkedin} target="_blank" rel="noopener"><LinkedIn /></Link>}
          {medium && <Link href={medium} target="_blank" rel="noopener"><Medium /></Link>}
        </></Fade>}
        {editable &&
          <div className={classes.buttons}>
            <IconButton size="small" onClick={onEdit}><Edit /></IconButton>
            <IconButton size="small" onClick={onDelete}><Delete /></IconButton>
          </div>
        }
      </div>
    </Zoom>
  );
}

const STORAGE_OPTIONS = { fetchURL: true };

function PeopleList(props) {
  const { type, editable, variant = 'circle', imageOnly, width } = props;
  const classes = useStyle(props);
  const options = useMemo(() => {
    const options = { orderBy: ['created', 'asc'] };
    if (type) {
      options.where = ['type', '==', type];
    }
    if (editable) {
      options.listen = true;
    }
    return options;
  }, [type, editable]);
  const { loading, data, collection } = useCollection('people', options);
  const { storage, synchronize } = useStorage('people', STORAGE_OPTIONS);
  const [edit, setEdit] = useState(null);

  if (loading) {
    return <CircularProgress />
  }

  const handleEdit = (data) => editable ? () => {
    setEdit({ data, image: storage.getFileURL(data.id) });
  } : null;

  const handleDelete = (id) => editable ? () => {
    return collection.deleteDocument(id)
      .then(() => storage.deleteFile(id));
  } : null;

  const handleCloseEdit = () => {
    setEdit(null);
  };

  const handleSave = editable ? (data, file) => {
    const promise = data.id
      ? collection.updateDocument(data.id, data)
      : collection.addDocument({ type, ...data });

    if (file) {
      return promise
        .then(res => storage.addFile(data.id || res.id, file))
        .then(synchronize);
    }
    return promise;
  } : null;

  return (
    <Expander
      className={clsx([classes.root, imageOnly && classes.rootImageOnly])}
      refresh={data && data.length}
      incremental={isWidthDown('xs', width)}
    >
      {data.map(people =>
        <People
          key={people.id}
          image={storage.getFileURL(people.id)}
          editable={editable}
          onEdit={handleEdit(people)}
          onDelete={handleDelete(people.id)}
          variant={variant}
          imageOnly={imageOnly}
          classes={classes}
          {...people}
        />
      )}
      {editable && <>
        <div className={clsx([classes.people, classes.addPeople])}>
          <Fab color="primary" aria-label="add" onClick={handleEdit({})}>
            <Add />
          </Fab>
        </div>
        <PeopleDialog
          open={Boolean(edit)}
          data={edit ? edit.data : null}
          imageUrl={edit ? edit.image : null}
          onClose={handleCloseEdit}
          onSave={handleSave}
          variant={variant}
          imageOnly={imageOnly}
        />
      </>}
    </Expander>
  );
}

PeopleList.defaultProps = {
  xs: 5,
};

PeopleList.propTypes = {
  type: PropTypes.string,
  editable: PropTypes.bool,
  variant: PropTypes.oneOf(['circle', 'square']),
  imageOnly: PropTypes.bool,
  rows: PropTypes.number,
};

export default withWidth()(PeopleList);
