import React from 'react';
import { FormSection } from './FormSections';
import { connectToForm } from '../../contexts/FormContext';
import DynamicList from '../commons/DynamicList';
import { makeStyles } from '@material-ui/core';
import { getRecruiterList, getContractList, getPeriodList } from '../../utils/dataProvider';
import { useTranslation } from 'react-i18next';
import { CollectionSelect, CollectionTags } from '../Collection';
import Select from '../commons/Select';
import Tags from '../commons/Tags';
import { sortByName } from '../../utils/formUtils';

const useStyles = makeStyles((theme) => ({
  rowContent: {
    flexGrow: '1',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    flex: '1',
    padding: theme.spacing(1),
    minWidth: '250px'
  },
}));

const JobDetails = ({ value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (name, value) => {
    onChange({ name, value });
  };

  return (
    <div className={classes.rowContent}>
      <CollectionSelect
        className={classes.item}
        variant="standard"
        collection="sectors"
        sort={sortByName}
        value={value.sector}
        onChange={(v) => handleChange('sector', v)}
        size="small"
        fullWidth
        autocomplete
        inputProps={{ label: t('form.sector') }}
      />
      <Select
        className={classes.item}
        variant="standard"
        value={value.recruiter}
        onChange={(v) => handleChange('recruiter', v)}
        label={t('form.recruiter')}
        size="small"
        fullWidth
        options={() => getRecruiterList(t)}
        autocomplete
      />
      <Select
        className={classes.item}
        variant="standard"
        value={value.contract}
        onChange={(v) => handleChange('contract', v)}
        label={t('form.contract')}
        size="small"
        fullWidth
        options={() => getContractList(t, true)}
        autocomplete
      />
      <CollectionSelect
        className={classes.item}
        variant="standard"
        collection="roles"
        sort={sortByName}
        value={value.role}
        onChange={(v) => handleChange('role', v)}
        size="small"
        fullWidth
        autocomplete
        inputProps={{ label: t('form.role') }}
      />
      { value.contract && value.contract !== 'cdi' &&
        <Select
          className={classes.item}
          variant="standard"
          value={value.duration}
          onChange={(v) => handleChange('duration', v)}
          label={t('form.duration')}
          size="small"
          fullWidth
          options={() => getPeriodList(t)}
          autocomplete
        />
      }
      
    </div>
  );
};

function LastJobsImpl({ name, title, value, error, dispatch }) {

  const handleChange = (data, index) => {
    const newValues = [...value];
    newValues[index][data.name] = data.value;
    dispatch({ type: 'change', value: newValues, name });
  };

  const handleNewRow = () => {
    dispatch({
      type: 'change',
      value: [...value,
        { sector: null, recruiter: null, contract: null, role: null, duration: null }
      ],
      name
    });
  };

  const handleRemoveRow = (index) => {
    const newValues = [...value];
    newValues.splice(index, 1);
    dispatch({ type: 'change', value: newValues, name });
  };

  return (
    <FormSection id={name} title={title} error={error}>
      <DynamicList
        Component={JobDetails}
        values={value}
        onChange={handleChange}
        onNewRow={handleNewRow}
        onDeleteRow={handleRemoveRow}
        max={3}
      />
    </FormSection>
  );
}

export const LastJobs = connectToForm(React.memo(LastJobsImpl));


const useExperienceStyles = makeStyles((theme) => ({
  row: {
    marginBottom: theme.spacing(2)
  },
}));

function LastJobExperiencesImpl({ name, title, value, error, dispatch }) {
  const classes = useExperienceStyles();
  const { t } = useTranslation();
  const recruiterList = React.useMemo(() => getRecruiterList(t), [t]);

  const handleChange = (type) => (newValue) => {
    const newValues = { ...value };
    newValues[type] = newValue;
    dispatch({ type: 'change', value: newValues, name });
  };

  return (
    <FormSection id={name} title={title} error={error}>
      <CollectionTags
        id="last-sectors"
        className={classes.row}
        label={t('form.sectors')}
        collection="sectors"
        sort={sortByName}
        value={value.sectors || []}
        onChange={handleChange('sectors')}
        placeholder
      />
      <Tags
        id="last-recruiter"
        className={classes.row}
        label={t('form.recruiters')}
        data={recruiterList}
        value={value.recruiters || []}
        onChange={handleChange('recruiters')}
        placeholder
      />
      <CollectionTags
        id="last-roles"
        className={classes.row}
        label={t('form.roles')}
        collection="roles"
        sort={sortByName}
        value={value.roles || []}
        onChange={handleChange('roles')}
        placeholder
      />
    </FormSection>
  );
}

export const LastJobExperiences = connectToForm(React.memo(LastJobExperiencesImpl));
