import React from 'react';
import { useSession } from './api/session';
import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './views/Home';
import Candidate from './views/Candidate';
import Recruiter from './views/Recruiter';
import NotFound from './views/NotFound';
import Admin from './views/Admin';
import Action from './views/Action';
import Legal from './views/Legal';
import { useTranslation } from 'react-i18next';
import About from './views/About';

function hasPrivilege(session) {
  return session.claims && session.claims.admin;
}

const DEFAULT_TITLE = 'Design Link';

function Page({ title, ...others }) {
  React.useEffect(() => {
    document.title = title || DEFAULT_TITLE;
  }, [title]);
  return <Route {...others} />;
}

function SecuredPage({component, ...props}) {
  const session = useSession();
  if (hasPrivilege(session)) {
    return <Page component={component} {...props} />;
  }
  return <Page component={NotFound} {...props} />
}

function RedirectPage({redirect, path, exact, ...props}) {
  const redirectUrl = redirect && redirect();
  if (redirectUrl && redirectUrl !== path) {
    return <Redirect path={path} exact={exact} to={redirectUrl} />
  }
  return (
    <Page path={path} exact={exact} {...props} />
  );
}

export default function Routes() {
  const session = useSession();
  const { t } = useTranslation('pages');

  const handleRedirect = () => {
    if (session.profile) {
      return `/${session.profile.type}`;
    }
  };

  return (
    <Switch>
      <RedirectPage exact path="/" component={Home} redirect={handleRedirect} />
      <RedirectPage exact path="/candidate" component={Candidate} redirect={handleRedirect} title={t('candidate')} />
      <RedirectPage exact path="/recruiter" component={Recruiter} redirect={handleRedirect} title={t('recruiter')} /> 
      <SecuredPage exact path="/admin" component={Admin} title={t('admin')} />
      <SecuredPage exact path="/candidate/:id" component={Candidate} />
      <SecuredPage exact path="/recruiter/:id" component={Recruiter} />
      <Page exact path="/action/:data" component={Action} />
      <Page exact path="/legal" component={Legal} title={t('legal')} />
      <Page exact path="/about" component={About} title={t('about')} />
      <Page component={NotFound} />
    </Switch>
  );
}
