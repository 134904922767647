import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  // Load translation from /public/locales
  .use(Backend)
  // Detect use language
  .use(LanguageDetector)
  // Pass the i18n instance to React
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false
    }
  });


export default i18n;
