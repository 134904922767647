class Env {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.actionRedirectURL = this.baseURL + '#login';
    this.functionURL = 'https://us-central1-designlinkapp.cloudfunctions.net/api';
  }

  setBaseURL(url) {
    this.baseURL = url;
    this.actionRedirectURL = this.baseURL + '#login';
  }

  setFunctionURL(url) {
    this.functionURL = url;
  }
}

const env = new Env('https://designlink.fr/');

export default env;
