import React, { useCallback, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { makeStyles } from '@material-ui/core';
import InfiniteTable from './InfiniteTable';
import Toolbar from './Toolbar';
import dataConverter from '../../../api/dataConverter'; 
import { normalizeString } from '../../../utils/formUtils';
import { deepEquals } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useMountedState, usePrevious } from '../../../api/states';

const INEQUALITY_OPERATORS = ['<', '<=', '>', '>='];
const SNAPSHOT_OPTIONS = { includeMetadataChanges: true };

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%'
  },
  table: {
    width: '100%',
    height: 'calc(100vh - 300px)',
    minHeight: '400px',
  },
}));

function compare(a, b) {
  if (typeof a === 'string') {
    return a.localeCompare(b);
  }
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
}

function applyFilter(doc, where) {
  if (doc && where.length === 3) {
    const a = doc.data()[where[0]];
    const b = where[2];
    switch (where[1]) {
      case '==' : return a === b;
      case '!=' : return a !== b;
      case '>'  : return a > b;
      case '>=' : return a >= b;
      case '<'  : return a < b;
      case '<=' : return a <= b;
      default   : return false;
    }
  }
  return false;
}

function hasInequality(where) {
  if (where && where.length > 0) {
    if (Array.isArray(where[0])) {
      for (let nested of where) {
        if (INEQUALITY_OPERATORS.includes(nested[1])) {
          return nested[0];
        }
      }
    }
    else if (INEQUALITY_OPERATORS.includes(where[1])) {
      return where[0];
    }
  }
  return false;
}

function createQuery(collection, { limit, orderBy, filter, where, keywords, last }) {
  let query = firebase.firestore().collection(collection).withConverter(dataConverter);
  let sortFct = null;
  let filterFct = null;

  if (where && where.length > 0) {
    if (hasInequality(where) && keywords && keywords.length) {
      // Filter manually if we have keywords
      if (Array.isArray(where[0])) {
        filterFct = (doc) => where.every(nested => applyFilter(doc, nested));
      } else {
        filterFct = (doc) => applyFilter(doc, where);
      }
    } else {
      if (Array.isArray(where[0])) {
        where.forEach(nestedWhere => query = query.where(...nestedWhere));
      } else {
        query = query.where(...where);
      }
    }
  }

  if (keywords && keywords.length) {
    // Full Text Search - retrieve all and sort manually as we cannot use composite indexes
    if (last) {
      return {
        onSnapshot: (callback) => callback([], [], false),
        get: () => new Promise(resolve => resolve([])),
      };
    }
    keywords.forEach(keyword => query = query.where(`_index.keywords.${keyword}`, '==', true));
    if (orderBy) {
      sortFct = (a, b) => compare(a.get(orderBy[0]), b.get(orderBy[0])) * (orderBy[1] === 'asc' ? 1 : -1);
    }
  } else {
    // Progressive loading
    if (orderBy) { query = query.orderBy(...orderBy); }
    if (limit) { query = query.limit(limit); }
    if (last) { query = query.startAfter(last); }
  }

  const processSnapshots = (snapshots) => {
    let docs = snapshots.docs;
    if (filterFct) {
      docs = docs.filter(filterFct);
    }
    if (filter) {
      docs = docs.filter(filter);
    }
    if (sortFct) {
      docs = docs.sort(sortFct);
    }
    return docs;
  };

  return {
    onSnapshot: (callback) => query.onSnapshot(
      SNAPSHOT_OPTIONS,
      snapshots => {
        const removed = [];
        snapshots.docChanges(SNAPSHOT_OPTIONS).forEach(change => {
          if (change.type === 'removed') {
            removed.push(change.doc);
          }
        });
        const hasNext = snapshots.docs.length === limit;
        return callback(processSnapshots(snapshots), removed, hasNext);
      }
    ),
    get: () => query.get().then(processSnapshots),
  };
}

function formatDataForExport(data) {
  if (data instanceof Date) {
    return [data.getDate(), data.getMonth() + 1, data.getFullYear()]
      .map(n => n < 10 ? `0${n}` : `${n}`).join('/');
  } else if (data === undefined) {
    return false;
  }
  return data;
}

function FirebaseTable({
  collection,
  filter,
  search,
  exports,
  columns,
  where,
  sort,
  pageSize = 50,
  threshold = 40,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [itemCount, setItemCount] = React.useState(pageSize);
  const [orderBy, setOrderBy] = React.useState(sort);
  const [lockOrderBy, setLockOrderBy] = React.useState(null);
  const [keywords, setKeywords] = React.useState(null);
  const [reset, setReset] = React.useState(false);
  const [observers, setObservers] = React.useState([]);
  const isMounted = useMountedState();
  const loaderRef = React.useRef(null);
  const previousWhere = usePrevious(where);

  const loadMoreItems = React.useCallback(() => {
    const query = createQuery(collection, {
      limit: pageSize,
      last: items.length && items[items.length - 1],
      where,
      filter,
      orderBy,
      keywords,
    });
    setLoading(true);
    const observer = query.onSnapshot((snapshots, removed, hasNext) => {
      if (isMounted()) {
        setItems(prev => {
          const data = [...prev];
          
          snapshots.forEach(doc => {
            const index = data.findIndex(item => item.id === doc.id);
            if (index < 0) {
              // Added
              data.push(doc);
            } else {
              // Updated
              data[index] = doc;
            }
          });
          // Removed
          removed.forEach(doc => {
            const index = data.findIndex(item => item.id === doc.id);
            if (index >= 0) {
              data.splice(index, 1);
            }
          });

          setItemCount(hasNext ? data.length + 1 : data.length);
          setLoading(false);
          return data;
        });
      }
    });
    if (observer) {
      setObservers(prev => [...prev, observer]);
    }
  }, [items, keywords, orderBy, pageSize, collection, filter, where, isMounted]);

  React.useEffect(() => {
    // Unmount
    return () => {
      if (!isMounted()) {
        observers.forEach(observer => observer());
      }
    };
  }, [observers, isMounted]);

  React.useEffect(() => {
    if (reset) {
      setObservers(prev => {
        prev.forEach(observer => observer());
        return [];
      });
      if (loaderRef.current) {
        loaderRef.current.resetloadMoreItemsCache();
        loadMoreItems();
      }
      setReset(false);
    }
  }, [reset, loadMoreItems]);

  const clear = useCallback(() => {
    setItems([]);
    setLoading(false);
    setItemCount(pageSize);
    setReset(true);
  }, [pageSize]);

  const handleOrderBy = useCallback((field, lock) => {
    if (!lockOrderBy || lockOrderBy === field || lock) {
      let direction = 'desc';
      if (orderBy && field === orderBy[0] && orderBy[1] === 'desc') {
        direction = 'asc';
      }
      setOrderBy([field, direction]);
      if (lock) {
        setLockOrderBy(field);
      }
      clear();
    }
  }, [clear, orderBy, lockOrderBy]);

  // React to 'where' change
  useEffect(() => {
    if (where !== previousWhere) {
      const field = hasInequality(where);
      if (field) {
        if (orderBy && field !== orderBy[0]) {
          handleOrderBy(field, true);
        } else {
          setLockOrderBy(field);
        }
      } else {
        setLockOrderBy(null);
      }
      clear();
    }
  }, [where, previousWhere, clear, handleOrderBy, orderBy]);

  const handleSearch = (text) => {
    if (text) {
      setKeywords(normalizeString(text).split(' ').filter(keyword => keyword));
    } else {
      setKeywords(null);
    }
    clear();
  };

  const handleExport = exports ? (type) => {
    const query = createQuery(collection, {
      orderBy,
      keywords,
      filter,
      where,
    });

    const filename = `${(exports.filename || 'export')}.${type}`;
    const config = exports[type];

    return query.get()
      .then(snapshots => {
        let data = '\uFEFF'; // UTF8 BOM
        if (config.headers) {
          data += config.fields.map(field => t(`table.${field}`)).join(config.separator) + '\n';
        }
        snapshots.forEach(doc => {
          const docData = doc.data();
          data += config.fields.map(field => formatDataForExport(docData[field])).join(config.separator) + '\n'
        });
        return { filename, data };
      });
  } : null;

  let exportTypes = [];
  if (exports) {
    for (let key in exports) {
      if (key !== 'filename') {
        exportTypes.push(key);
      }
    }
  }

  return (
    <div className={classes.root}>
      { search &&
        <Toolbar
          searchLoading={loading}
          nbSearchResults={keywords && items.length}
          onSearch={handleSearch}
          onExport={handleExport}
          exportTypes={exportTypes}
        />
      }
      <InfiniteTable
        className={classes.table}
        items={items}
        itemCount={itemCount}
        loadMoreItems={loadMoreItems}
        loaderRef={loaderRef}
        threshold={threshold}
        columns={columns}
        orderBy={orderBy && orderBy[0]}
        orderDirection={orderBy && orderBy[1]}
        onOrderBy={handleOrderBy}
        {...props}
      />
    </div>
  );
}

export default React.memo(FirebaseTable, deepEquals);
