import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';

function RouteThemeProvider({ children, themes }) {
  const { pathname } = useLocation();
  const theme = React.useMemo(() => {
    if (pathname in themes) {
      return themes[pathname];
    }
    return themes.default;
  }, [themes, pathname]);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

RouteThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  themes: PropTypes.shape({
    default: PropTypes.object.isRequired,
  }),
};

export default RouteThemeProvider;
