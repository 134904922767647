import firebase from 'firebase/app';
import 'firebase/firestore';
import isEmail from 'validator/lib/isEmail';
import { normalizeEmailAddress } from '../../utils/formUtils';
import dataConverter from '../dataConverter';
import { getUserByEmail } from '../users';

export async function createAdminUser({ email, firstname, lastname }) {
  if (!email || !firstname || !lastname) {
    throw new Error('Missing field');
  }
  email = normalizeEmailAddress(email);
  if (!isEmail(email)) {
    throw new Error('Invalid e-mail address');
  }

  if (await getUserByEmail(email) !== null) {
    throw new Error('User already exists');
  }
  
  const user = {
    email,
    firstname,
    lastname,
    type: 'admin',
  };
  user.created = user.updated = new Date();
  return await firebase.firestore().collection('users').add(user);
}

export function deleteUser(uid) {
  return firebase.firestore().collection('users').doc(uid).delete();
}

export function updateUserName(profile) {
  const { id, firstname, lastname } = profile;
  return firebase.firestore().collection('users').doc(id).withConverter(dataConverter)
    .update({ firstname, lastname });
}
