import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Zoom, Backdrop, makeStyles, Container, Paper } from '@material-ui/core';
import { useLocationHash } from '../../utils/location';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    outline: 0,
  },
  paper: {
    margin: 0,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function ModalDialog({ open, hash, onClose, children, maxWidth = 'sm', timeout = 400 }) {
  const classes = useStyles();
  const { hash: currentHash } = useLocationHash();
  const isOpened = open === true || (hash !== undefined && hash === currentHash);

  return (
    <Modal
      open={isOpened}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{timeout: timeout}}
      className={classes.modal}
    >
      <Zoom in={isOpened} timeout={timeout}>
        <Container component="main" maxWidth={maxWidth} className={classes.container}>
          <Paper className={classes.paper}>
            {children}
          </Paper>
        </Container>
      </Zoom>
    </Modal>
  );
}

ModalDialog.propTypes = {
  open: PropTypes.bool,
  hash: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any,
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xs', 'xl']),
  timeout: PropTypes.number,
};

export default ModalDialog;
