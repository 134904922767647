import React from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import JobList from '../components/JobList';
import { activateProfile, deactivateProfile, deleteJob } from '../api/users';
import { useConfirm } from '../components/commons/ConfirmDialog';
import ActivationButton from '../components/UserSheet/ActivationButton';
import { Helper } from './FormHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  container: {
    zIndex: 10,
    flex: 1,
    margin: theme.spacing(4),
    maxWidth: theme.breakpoints.width('lg'),
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.breakpoints.width('md'),
      margin: 0,
    }
  },
  helper: {
    margin: theme.spacing(10, 4, 2),
    position: 'sticky',
    top: '30px',
    width: '350px',
    textShadow: '1px',
    [theme.breakpoints.down('md')]: {
      position: 'initial',
      width: theme.breakpoints.width('md'),
      margin: theme.spacing(2, 0)
    },
    '& div': {
      padding: theme.spacing(2),
    },
    '& p': {
      fontStyle: 'italic',
      fontWeight: '400',
      marginBottom: theme.spacing(4)
    }
  },
  disabled: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '660px',
    margin: 'auto',
    padding: theme.spacing(2),
    whiteSpace: 'pre-line',
    '& p': {
      margin: theme.spacing(2, 0)
    }
  },
}));

function RecruiterHeader({ profile, synchronize, synchronizing, t }) {
  const classes = useStyles();

  if (synchronizing) {
    return (
      <div className={classes.disabled}>
        <CircularProgress />
      </div>
    );
  }
  if (!profile) {
    return null;
  }
  let message;
  let activactionButton = false;

  if (profile.disabled) {
    message = 'disable.statusMessage';
    activactionButton = true;
  } else if (profile.jobs) {
    const jobs = Object.values(profile.jobs);

    if (jobs.length === 0) {
      message = 'job.nojob';
    } else if (jobs.every(job => job.disabled)) {
      message = 'job.noactivejob';
    }
  }

  if (message) {
    return (
      <div className={classes.disabled}>
        <Typography variant="body1" color="textSecondary" align="center">{t(message)}</Typography>
        {activactionButton && <ActivationButton profile={profile} synchronize={synchronize} />}
      </div>
    );
  }
  return null;
}

function RecruiterJobs({ profile, synchronize, synchronizing, history }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { ConfirmDialog, confirm } = useConfirm();

  const handleShow = (job) => {
    history.push(`${history.location.pathname}?job=${job.id}`)
  };
  const handleNew = () => {
    history.push(`${history.location.pathname}?job=new`)
  };
  const handleEnableDisable = (job, enable) => {
    if (enable) {
      return activateProfile(profile.id, job.id).then(() => synchronize());
    }
    return new Promise((resolve) => {
      confirm({ title: t('job.disableTitle'), text: t('job.disableText'), ok: t('job.disable') }, (confirmed) => {
        if (confirmed) {
          return deactivateProfile(profile.id, job.id)
            .then(() => synchronize())
            .then(resolve);
        }
        resolve(false);
      });
    });
  };
  const handleDelete = (job) => {
    return new Promise((resolve) => {
      confirm({ title: t('job.deleteTitle'), text: t('job.deleteText'), ok: t('delete.confirm') }, (confirmed) => {
        if (confirmed) {
          return deleteJob(profile.id, job.id)
            .then(() => synchronize())
            .then(() => resolve(true));
        }
        resolve(false);
      });
    });
  };
  return (
    <main className={classes.root}>
      <Helper title="jobs_title" text="jobs" className={classes.helper} />
      <div className={classes.container}>
        <RecruiterHeader profile={profile} synchronize={synchronize} synchronizing={synchronizing} t={t} />
        <JobList
          profile={profile}
          onShow={handleShow}
          onNew={handleNew}
          onEnableDisable={handleEnableDisable}
          onDelete={handleDelete}
        />
      </div>
      <ConfirmDialog cancel={t('delete.cancel')} />
    </main>
  );
}

export default RecruiterJobs;
