import React from 'react';
import { FormControlLabel, Checkbox, FormControl, makeStyles, Typography, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { connectToForm } from '../contexts/FormContext';

const useStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0, 2, 8),
  },
  checkbox: {
    marginBottom: theme.spacing(6),
  }
}));

function RGPD({ rgpd, rgpdError, dispatch }) {
  const classes = useStyle();
  const { t } = useTranslation('legal');  

  const handleChange = (event) => {
    dispatch({ type: 'change', name: 'rgpd', value: event.target.checked });
  };

  return (
    <div className={classes.root}>
      <FormControl id="rgpd" error={rgpdError} className={classes.checkbox}>
        <FormControlLabel
          label={
            <Typography variant="caption" color={rgpdError ? "error" : "textPrimary"}>
              <Trans t={t} i18nKey="form.consent">
                En soumettant ce formulaire, j'ai lu et j'accepte les <Link href="/legal" target="_blank">conditions d'utilisation</Link> du site et le traitement de mes données personnelles dans le cadre de la mise en relation entre designers et recruteurs.
              </Trans>
            </Typography>
          }
          control={
            <Checkbox
              name="rgpd"
              checked={rgpd}
              onChange={handleChange}
              color="primary"
            />
          }
        />
      </FormControl>
      <Typography variant="caption">
        <Trans t={t} i18nKey="form.privacy">
          Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, consultez notre <Link href="/legal#privacy" target="_blank">politique de confidentialité</Link>.
        </Trans>
      </Typography>
    </div>
  );
}

RGPD.propTypes = {
  ...Checkbox.propTypes
};

export default connectToForm(React.memo(RGPD), {
  names: ['rgpd'],
  errors: ['rgpd']
});
