import firebase from 'firebase/app';
import 'firebase/firestore';
import dataConverter from './dataConverter';

export function sendMessage({ email, phone, message }) {
  if (email && message) {
    const data = { email, message, created: new Date() };
    if (phone) {
      data.phone = phone;
    }
    return firebase.firestore().collection('messages').withConverter(dataConverter).add(data);
  }
  return Promise.reject(new Error('Missing email or message'));
}
