import React from 'react';
import { Grid, Typography, TextField, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AsyncButton from '../../commons/AsyncButton';
import { setting } from './utils';

const ContextList = ['roles', 'contract', 'start', 'duration', 'recruiter', 'sector', 'jobType', 'place'];
const ProfileList = ['experience', 'degree', 'expert', 'proficient', 'novice', 'softs', 'tools', 'languages', 'lastJobs', 'salary', 'adr'];

const isContext = ([key]) => ContextList.includes(key);
const sortContext = ([key1], [key2]) => ContextList.indexOf(key1) - ContextList.indexOf(key2);

const isProfile = ([key]) => ProfileList.includes(key);
const sortProfile = ([key1], [key2]) => ProfileList.indexOf(key1) - ProfileList.indexOf(key2);

function MatchSettings({ loading, data, error, setData, onSave, saving }) {
  const { t } = useTranslation('admin');
  const { threshold, ...coefficients } = data;

  const handleChange = (key) => (event) => {
    setData({...data, [key]: Number.parseInt(event.target.value || 0) });
  };

  const handleThresholdChange = (key) => (event) => {
    setData({...data, threshold: { ...threshold, [key]: Number.parseInt(event.target.value || 0) } });
  };

  return (
    <Grid container spacing={2}>
      { error &&
        <Grid item>
          <Typography variant="body1" color="error">{error.message}</Typography>
        </Grid>
      }
      <Grid container item>
        <Typography variant="h6">{t('matchingContext')}</Typography>
      </Grid>
      { !loading && coefficients &&
        Object
          .entries(coefficients)
          .filter(isContext)
          .sort(sortContext)
          .map(([key, value]) =>
            <Grid key={key} item>
              <TextField label={t(`match.${key}`)} type="number" value={value} onChange={handleChange(key)} />
            </Grid>
      ) }
      <Grid container item>
        <Typography variant="h6">{t('matchingProfile')}</Typography>
      </Grid>
      { !loading && coefficients &&
        Object
          .entries(coefficients)
          .filter(isProfile)
          .sort(sortProfile)
          .map(([key, value]) =>
            <Grid key={key} item>
              <TextField label={t(`match.${key}`)} type="number" value={value} onChange={handleChange(key)} />
            </Grid>
      ) }
      { !loading && threshold !== undefined &&
        <Grid container item spacing={2}>
          <Grid container item>
            <Typography variant="h6">{t('threshold')}</Typography>
          </Grid>
          <Grid container item>
            <TextField label={t('match.context')} type="number" value={threshold.context} onChange={handleThresholdChange('context')}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid container item>
            <TextField label={t('match.profile')} type="number" value={threshold.profile} onChange={handleThresholdChange('profile')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
          </Grid>
        </Grid>
      }
      <Grid container item>
        <AsyncButton size="small" loading={saving} onClick={onSave}>
          {t('action.save')}
        </AsyncButton>
      </Grid>
    </Grid>
  );
}

export default React.memo(setting(MatchSettings, 'match'));
