import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-handlebars";
import "ace-builds/src-noconflict/theme-tomorrow";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
}));

export default function CodeEditor({ value, onChange }) {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.root} elevation={3}>
      <AceEditor
        mode="handlebars"
        theme="tomorrow"
        width="100%"
        height="300px"
        value={value}
        onChange={onChange}
        editorProps={{ $blockScrolling: true }}
        tabSize={2}
      />
    </Paper>
  );
}
