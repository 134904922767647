import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete, FindReplace, Person } from '@material-ui/icons';
import { deleteUser } from '../../api/admin/users';
import { useSnackbar } from 'notistack';
import { useSession } from '../../api/session';
import { fetchApi } from '../../api/fetch';
import FirebaseTable from './core/FirebaseTable';
import { useDateFilter } from './core/DateFilter';
import TooltipIconButton from '../commons/TooltipIconButton';

const DEFAULT_SORT = ['created', 'desc'];

function UserActions(rowData) {
  const { t } = useTranslation('admin');
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useSession();

  if (!rowData) {
    return null;
  }

  const handleError = (error) => {
    enqueueSnackbar(error.message, { variant: 'error' });
    throw error;
  };

  const handleDelete = () => {
    return new Promise((resolve, reject) => {
      if (window.confirm(t('importantAction'))) {
        return deleteUser(rowData.id)
          .then(resolve)
          .catch(handleError);
      }
      resolve();
    });
  };

  const handleDisplayUser = () => {
    window.open(`/${rowData.type}/${rowData.id}`, '_blank');
  };

  const handleRunMatches = () => {
    if (window.confirm(t('runmatch.confirm'))) {
      fetchApi(`/tools/runmatch/${rowData.id}`, token, { authenticated: true, type: 'text' })
        .then(() => enqueueSnackbar(t('runmatch.success'), { variant: 'success' }))
        .catch(() => enqueueSnackbar(t('runmatch.error'), { variant: 'error' }));
    }
  };

  return (
    <Fragment>
      <TooltipIconButton title={t('table.displayUser')} icon={<Person />} size="small" onClick={handleDisplayUser} />
      <TooltipIconButton title={t('table.runmatch')} icon={<FindReplace />} size="small" onClick={handleRunMatches} />
      <TooltipIconButton title={t('table.delete')} icon={<Delete />} size="small" onClick={handleDelete} />
    </Fragment>
  );
}

const EXPORT_CONFIG = {
  filename: 'users',
  txt: {
    fields: ['email'],
    separator: ' ',
  },
  csv: {
    fields: ['email', 'firstname', 'lastname', 'type', 'disabled', 'rgpd', 'created'],
    separator: ';',
    headers: true,
  },
};

function UserTable() {
  const { t } = useTranslation('admin');
  const { DateFilter, openFilter, dateFilters, isActiveFilter } = useDateFilter();
  const columns = React.useMemo(() => ([
    { title: t('table.email'), field: 'email', minWidth: 180 },
    { title: t('table.firstname'), field: 'firstname', width: 130 },
    { title: t('table.lastname'), field: 'lastname', width: 130 },
    { title: t('table.type'), field: 'type', lookup: { candidate: t('table.candidate'), recruiter: t('table.recruiter') }, width: 100 },
    { title: t('table.state'), field: 'disabled', render: rowData => rowData.disabled ? t('table.disabled') : t('table.active'), width: 100, sort: false },
    { title: t('table.created'), field: 'created', width: 130, filter: openFilter, active: isActiveFilter('created') },
    { title: t('table.updated'), field: 'updated', width: 150, filter: openFilter, active: isActiveFilter('updated') },
    { title: t('table.actions'), render: UserActions, width: 150 }
  ]), [t, openFilter, isActiveFilter]);

  const filter = doc => doc.get('type') !== 'admin';

  return (
    <>
      <FirebaseTable
        collection="users"
        filter={filter}
        where={dateFilters}
        sort={DEFAULT_SORT}
        columns={columns}
        search
        exports={EXPORT_CONFIG}
      />
      <DateFilter />
    </>
  );
}

export default UserTable;
