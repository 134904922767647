import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import AsyncButton from '../../commons/AsyncButton';
import { fetchApi } from '../../../api/fetch';
import { useSession } from '../../../api/session';
import { useTranslation } from 'react-i18next';
import { useDocument } from '../../../api/document';

function CallServiceButton({ label, uri, confirm, success, error, loading, ...props }) {
  const { token } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = React.useState(false);

  const handleClick = () => {
    if (confirm && window.confirm(confirm)) {
      setFetching(true);
      fetchApi(uri, token, { authenticated: true, type: 'text' })
        .then(() => enqueueSnackbar(success, { variant: 'success' }))
        .catch(() => enqueueSnackbar(error, { variant: 'error' }))
        .finally(() => setFetching(false));
    }
  };

  const isLoading = loading || fetching;

  return (
    <AsyncButton size="small" loading={isLoading} onClick={handleClick} {...props}>
      {label}
    </AsyncButton>
  );
}

const ACTIVE_OPERATION_STATUS = ['started', 'running'];
const DOCUMENT_OPTIONS = { listen: true };

function OperationButton({ operation, ...props }) {
  const { document, loading } = useDocument(operation, 'operations', DOCUMENT_OPTIONS);
  const isLoading = loading || ACTIVE_OPERATION_STATUS.includes(document?.status);

  return <CallServiceButton loading={isLoading} {...props} />;
}

function Tools() {
  const { t } = useTranslation('admin');

  return (
    <Grid container spacing={2}>
      <Grid container item>
        <Typography variant="h6">{t('tools')}</Typography>
      </Grid>
      <Grid container item xs={6}>
        <OperationButton
          operation="index"
          label={t('index.runUsers')}
          uri="/tools/index/users"
          confirm={t('index.confirm')}
          success={t('index.success')}
          error={t('index.error')}
          fullWidth
        />
      </Grid>
      <Grid container item xs={6}>
        <OperationButton
          operation="index"
          label={t('index.runMatch')}
          uri="/tools/index/match"
          confirm={t('index.confirm')}
          success={t('index.success')}
          error={t('index.error')}
          fullWidth
        />
      </Grid>
      <Grid container item xs={6}>
        <OperationButton
          operation="match"
          label={t('table.runmatch')}
          uri="/tools/runmatch"
          confirm={t('runmatch.confirmAll')}
          success={t('runmatch.started')}
          error={t('runmatch.error')}
          fullWidth
        />
      </Grid>
      <Grid container item xs={6}>
        <CallServiceButton
          label={t('statistics.computeAll')}
          uri="/tools/statistics"
          confirm={t('statistics.confirm')}
          success={t('statistics.complete')}
          error={t('statistics.error')}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default Tools;
