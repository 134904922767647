import React from 'react';
import { useSession } from './session';
import env from '../env';

const cache = {};

class RequestError extends Error {
  constructor(code, message) {
    super(message);
    this.code = code;
  }
}

export function fetchApi(url, token, options) {
  const { useCache, authenticated, signal } = options;
  if (useCache === true && cache.hasOwnProperty(url)) {
    return Promise.resolve(cache[url]);
  }
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  } else if (authenticated === true) {
    return Promise.reject('Not authenticated');
  }
  return fetch(env.functionURL + url, {
    method: 'GET',
    headers,
    mode: 'cors',
    cache: 'default',
    signal,
  })
  .then(res => {
    if (res.ok) {
      const type = options.type || 'json';
      switch (type) {
        case 'json':
          return res.json();
        default:
          return res.text();
      }
    } else {
      throw new RequestError(res.status, res.statusText);
    }
  })
  .then(data => {
    if (useCache === true) {
      cache[url] = data;
    }
    return data;
  });
}

const FetchState = Object.freeze({
  READY: Symbol('ready'),
  FETCHING: Symbol('fetching'),
  REFRESHING: Symbol('refreshing'),
  REFRESHED: Symbol('refreshed')
});

export function useFetch(url, options) {
  const [state, setState] = React.useState(FetchState.READY);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [data, setData] = React.useState(null);
  const { token, refreshToken } = useSession();

  React.useEffect(() => {
    setError(null);
    setState(FetchState.READY);
    setData(null);
    setLoading(false);
  }, [url]);

  React.useEffect(() => {
    if (url && error === null && [FetchState.READY, FetchState.REFRESHED].includes(state)) {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const doFetch = async () => {
        setLoading(true);
        try {
          const result = await fetchApi(url, token, { ...options, signal }); 
          if (!signal.aborted) {
            setData(result);
          }
        } catch (error) {
          if (!signal.aborted) {
            if (options.authenticated && token && error.code === 401 && state === FetchState.READY) {
              setState(FetchState.REFRESHING);
              try {
                await refreshToken();
                setState(FetchState.REFRESHED);
              }
              catch (e) {
                setError(e);
              }
            } else {
              setError(error)
            }
          }
        } finally {
          if (!signal.aborted) {
            setLoading(false);
            setState(FetchState.READY);
          }
        }
      };

      doFetch();

      return () => abortController.abort();
    }
  }, [url, token, options, refreshToken, state, error]);

  return { loading, error, data };
}
