import React from 'react';
import { Stepper, Step, StepLabel, useMediaQuery, withStyles, StepIcon, StepConnector } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

const StyledStepIcon = withStyles(theme => ({
  root: {
    transition: theme.transitions.create('all', { duration: 1000 }),
    zIndex: 10,
    transform: 'scale(1.5)',
  },
  active: {
    transform: 'scale(2)',
  },
}))(StepIcon);

const StyledConnector = withStyles(theme => ({
  horizontal: {
    margin: theme.spacing(0, 2),
  },
}))(StepConnector);

const StyledStepLabel = withStyles(theme => ({
  root: {
  },
  vertical: {
    // margin: theme.spacing(1, 0, 2),
  },
  labelContainer: {
    marginLeft: theme.spacing(2),
  },
  label: {
    transition: theme.transitions.create('color', { duration: 1000 }),
    padding: theme.spacing(1, 2),
  }
}))(StepLabel);

function AnimatedSteps({ timeout, ...props }) {
  const [step, setStep] = React.useState(0);
  const [ref, inView] = useInView();
  const [animated, setAnimated] = React.useState(inView);
  const small = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const orientation = small ? 'vertical' : 'horizontal';

  React.useEffect(() => {
    if (inView) {
      setStep(0);
    }
    setAnimated(inView)
  }, [inView]);

  React.useEffect(() => {
    if (animated) {
      const timer = setTimeout(() => {
        setStep(step === 3 ? 0 : step + 1);
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [step, timeout, animated]);

  return (
    <Stepper {...props} ref={ref} activeStep={step} orientation={orientation} nonLinear alternativeLabel={!small} connector={<StyledConnector />}>
      <Step>
        <StyledStepLabel StepIconComponent={StyledStepIcon}><Trans i18nKey="how.one" ns="home" /></StyledStepLabel>
      </Step>
      <Step>
        <StyledStepLabel StepIconComponent={StyledStepIcon}><Trans i18nKey="how.two" ns="home" /></StyledStepLabel>
      </Step>
      <Step>
        <StyledStepLabel StepIconComponent={StyledStepIcon}><Trans i18nKey="how.three" ns="home" /></StyledStepLabel>
      </Step>
      <Step>
        <StyledStepLabel StepIconComponent={StyledStepIcon}><Trans i18nKey="how.four" ns="home" /></StyledStepLabel>
      </Step>
    </Stepper>
  );
}

export default React.memo(AnimatedSteps);
