import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContactSection from '../components/ContactSection';
import { ScrollToTopButton } from '../components/ScrollTop';
import Logo from '../components/Logo';
import PeopleList from '../components/PeopleList';
import { useSession } from '../api/session';

const useStyles = makeStyles((theme) => ({
  root: {
    '& section': {
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(4, 4, 8),
      '&:nth-child(even)': {
        backgroundColor: theme.palette.common.white,
      }
    }
  },
  title: {
    width: '100%',
    textAlign: 'center',
    fontSize: '2em',
    fontWeight: 'bold',
    margin: theme.spacing(4, 0)
  },
  subtitle: {
    margin: theme.spacing(0, 2, 4),
    maxWidth: 1160,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 4),
    }
  },
  button: {
    marginTop: theme.spacing(8),
  },
}));

function hasPrivilege(session) {
  return session.claims && session.claims.admin;
}

function About() {
  const classes = useStyles();
  const { t } = useTranslation('about');
  const session = useSession();
  const editable = hasPrivilege(session);

  return (
    <main className={classes.root}>

      <section>
        <Logo className={classes.title} variant="h2" text={t('mentors')} />
        <Typography className={classes.subtitle} variant="subtitle1" align="center">{t('mentors_text')}</Typography>
        <PeopleList type="mentor" editable={editable} xs={5} sm={2} />
      </section>

      <section>
        <Logo className={classes.title} variant="h2" text={t('advisors')} />
        <Typography className={classes.subtitle} variant="subtitle1" align="center">{t('advisors_text')}</Typography>
        <PeopleList type="advisor" editable={editable} xs={5} sm={1} />
      </section>

      <section>
        <Logo className={classes.title} variant="h2" text={t('partners')} />
        <Typography className={classes.subtitle} variant="subtitle1" align="center">{t('partners_text')}</Typography>
        <PeopleList type="partner" editable={editable} variant="square" imageOnly xs={5} sm={2} />
        <Button variant="contained" color="primary" component={RouterLink} to="/" className={classes.button}>
          {t('homeButton')}
        </Button>
      </section>

      <ContactSection />
      <ScrollToTopButton />
      
    </main>
  );
}

export default About;
