import React from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import dataConverter from './dataConverter'; 
import { useMountedState } from './states';

export class Collection {

  constructor(name, data) {
    this.name = name;
    this.data = data;
    this.collection = firebase.firestore().collection(this.name);
  }

  setCollection(name) {
    if (name !== this.name) {
      this.name = name;
      this.collection = firebase.firestore().collection(this.name);
    }
  }

  setData(data) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  addDocument(data) {
    data.updated = data.created = new Date();
    return this.collection.withConverter(dataConverter).add(data);
  }

  updateDocument(id, data) {
    if (data.updated) {
      data.updated = new Date();
    }
    return this.collection.withConverter(dataConverter).doc(id).set(data);
  }

  deleteDocument(id) {
    return this.collection.doc(id).delete();
  }
}

export function getCollection(name, options) {
  const query = createCollectionQuery(name, options);
  return query.get();
}

export function useCollection(name, options) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [collection] = React.useState(new Collection(name, data));
  const isMounted = useMountedState();

  React.useEffect(() => {
    collection.setCollection(name);

    const query = createCollectionQuery(name, options);

    const handleResults = (snapshots) => {
      if (isMounted()) {
        const values = [];
        snapshots.forEach(doc => values.push(doc.data()));
        setLoading(false);
        collection.setData(values);
        setData(values);
      }
    };

    const handleError = (error) => {
      if (isMounted()) {
        setError(error);
      }
    };

    if (options && options.listen) {
      return query.onSnapshot(handleResults, handleError);
    }

    query.get().then(handleResults).catch(handleError);
    
  }, [name, collection, options, isMounted]);

  return {
    error,
    loading,
    collection,
    data,
  }
}

function createCollectionQuery(name, options) {
  let query = firebase.firestore().collection(name).withConverter(dataConverter);
  if (options) {
    const { where, orderBy, limit } = options;
    if (Array.isArray(where) && where.length > 0) {
      if (Array.isArray(where[0])) {
        where.forEach(nestedWhere => {
          query = query.where(...nestedWhere);
        });
      } else {
        query = query.where(...where);
      }
    }
    if (orderBy) {
      query = query.orderBy(...orderBy);
    }
    if (limit) {
      query = query.limit(limit);
    }
  }
  return query;
}
