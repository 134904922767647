import React, { useEffect, useState } from 'react';
import { Button, ButtonBase, Dialog, DialogActions, DialogContent, Grid, makeStyles, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AsyncButton from '../commons/AsyncButton';
import { Add } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  imageButton: {
    width: 200,
    height: 200,
    backgroundColor: theme.palette.grey[200],
    transition: theme.transitions.create('background'),
    borderRadius: 100,
    overflow: 'hidden',
    '&:hover': {
      opacity: 0.8
    },
  },
  square: {
    width: 320,
    height: 180,
    borderRadius: 0,
    '& $image': {
      objectFit: 'contain'
    }
  },
  image: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  }
}));

const FIELDS = [ 'displayName', 'title', 'title2', 'location', 'linkedin', 'medium' ];
const FIELDS_IMAGE_ONLY = [ 'displayName' ];

function ImageUpload({ image, variant, onChange }) {
  const classes = useStyles();

  return (
    <div>
      <input
        accept="image/*"
        id="people-image"
        hidden
        type="file"
        onChange={onChange}
      />
      <label htmlFor="people-image">
        <ButtonBase className={clsx([classes.imageButton, variant && classes[variant]])} component="span">
          {image
          ? <img src={image} alt="" className={classes.image} />
          : <Add />
          }
        </ButtonBase>
      </label>
    </div>
  );
}

function PeopleDialog({ open, data, imageUrl, onSave, onClose, variant, imageOnly, ...props }) {
  const { t } = useTranslation('admin');
  const [state, setState] = useState(data || {});
  const [image, setImage] = useState({ url: imageUrl });
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (open) {
      setState(data || {});
      setImage({ url: imageUrl });
    }
  }, [open, data, imageUrl]);
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage({
        file,
        url: URL.createObjectURL(file)
      });
    }
  };

  const handleSave = () => {
    if (onSave && state.displayName) {
      setSaving(true);
      onSave(state, image.file)
        .then(() => onClose && onClose())
        .finally(() => setSaving(false));
    }
  };

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item container xs={12} justify="center">
            <ImageUpload image={image.url} variant={variant} onChange={handleImage} />
          </Grid>
          {(imageOnly ? FIELDS_IMAGE_ONLY : FIELDS).map(field =>
            <Grid key={field} item xs={12}>
              <TextField
                name={field}
                label={t(`table.${field}`)}
                fullWidth
                onChange={handleChange}
                value={state[field] || ''}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('action.cancel')}</Button>
        <AsyncButton onClick={handleSave} loading={saving}>{t('action.save')}</AsyncButton>
      </DialogActions>
    </Dialog>
  );
}

export default PeopleDialog;
