import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { useFetch } from '../api/fetch';
import { LinearProgress } from '@material-ui/core';

const FETCH_OPTIONS = {
  useCache: true,
  authenticated: true,
  type: 'text',
};

function EmailPreview({ id }) {
  const { loading, data } = useFetch(id ? `/email/${id}` : null, FETCH_OPTIONS);
  const [content, setContent] = React.useState(null);

  React.useEffect(() => {
    if (data) {
      setContent(DOMPurify.sanitize(data));
    } else {
      setContent(null);
    }
  }, [data]);

  if (loading) {
    return <LinearProgress style={{ margin: 10 }} />
  }

  return (
    <div dangerouslySetInnerHTML={{__html: content }} />
  );
}

EmailPreview.propTypes = {
  id: PropTypes.string,
};


export default EmailPreview;
