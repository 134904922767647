import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import dataConverter from './dataConverter'; 

const cache_map = {};

// Get the specified document
// An optional observer function can be passed to listen
// for document changes. In this case the unsubscribe function
// is returned
export function getDocument(path, collection, observer, options) {
  const cacheKey = options?.cache
    ? path + (collection ? `-${collection}` : '')
    : null;
  if (!observer && cacheKey && cacheKey in cache_map) {
    return Promise.resolve(cache_map[cacheKey]);
  }

  let db = firebase.firestore();
  if (collection) {
    db = db.collection(collection)
  }
  const doc = db.doc(path).withConverter(dataConverter);
  if (observer) {
    return doc.onSnapshot(observer);
  }
  const promise = doc.get();

  if (cacheKey) {
    return promise.then(doc => {
      cache_map[cacheKey] = doc;
      return doc;
    });
  }
  return promise;
}

export function listenDocument(path, collection, observer) {
  let db = firebase.firestore();
  if (collection) {
    db = db.collection(collection)
  }
  return db.doc(path).withConverter(dataConverter).onSnapshot(observer);
}

export function useDocument(path, collection, options) {
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cacheKey = path + (collection ? `-${collection}` : '');
    if (options?.cache && cacheKey in cache_map) {
      setDocument(cache_map[cacheKey]);
    } else {
      setLoading(true);
      if (options?.listen) {
        return getDocument(path, collection,
          doc => {
            setDocument(doc.exists ? doc.data() : null);
            setLoading(false);
          },
          error => {
            setDocument(null);
            setError(error);
            setLoading(false);
          });
      } else {
        const controller = new AbortController();
        const signal = controller.signal;
        getDocument(path, collection)
          .then(doc => {
            if (!signal.aborted) {
              const data = doc.exists ? doc.data() : null;
              setDocument(data);
              if (options?.cache) {
                cache_map[cacheKey] = data;
              }
            }
          })
          .catch(e => !signal.aborted && setError(e))
          .finally(() => !signal.aborted && setLoading(false))
        return () => controller.abort();
      }
    }
  }, [path, collection, options]);

  return { document, loading, error };
}

export function useRealtimeDocument(path) {
  const [document, setDocument] = useState(null);

  useEffect(() => {
    const ref = firebase.database().ref(path);
    const handleChange = (snapshot) => {
      setDocument(snapshot.val());
    };
    ref.on('value', handleChange);
    return () => ref.off('value', handleChange);
  }, [path]);

  return { document };
}
