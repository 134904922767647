import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { updateUser } from '../api/users';
import { normalizeProfile } from '../utils/formUtils';
import { signUp } from '../api/auth';
import GlobalLoading from './commons/GlobalLoading';
import { FormContextProvider } from '../contexts/FormContext';
import FormStepper from './FormStepper';
import FormHelper from './FormHelper';
import analytics from '../api/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
  },
  container: {
    zIndex: 10,
    width: '660px',
    maxWidth: '100%',
  },
  helper: {
    margin: theme.spacing(10, 4, 2),
    position: 'sticky',
    top: '30px',
    width: '350px',
    textShadow: '1px',
    [theme.breakpoints.down('md')]: {
      position: 'initial',
      width: '660px',
      margin: theme.spacing(2, 0)
    },
    '& div': {
      padding: theme.spacing(2),
    },
    '& p': {
      fontStyle: 'italic',
      fontWeight: '400',
      marginBottom: theme.spacing(4)
    }
  },
}));

// Remove unnecessary properties from the
// local profile before sending for sign up
function cleanProfile(profile) {
  delete profile.emailConfirm;
  delete profile.hasCompany;
  return profile;
}

export default function Form({
  type,
  children,
  defaultProfile,
  profile,
  validate,
  synchronize,
  onComplete,
  header,
  helperHeader,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [ complete, setComplete ] = React.useState(false);

  const stepLabels = [
    t('navigation.section1'),
    t('navigation.section2'),
    t('navigation.section3'),
  ];

  const handleComplete = (form) => {
    if (profile) {
      return updateUser(normalizeProfile(form))
        .then(synchronize)
        .then(() => {
          enqueueSnackbar(t(`${type}.updateSucceeded`), { variant: 'success'});
          if (onComplete) {
            onComplete();
          }
          return false;
        })
        .catch(() => {
          enqueueSnackbar(t('form.updateFailed'), { variant: 'error' });
          return false;
        });
    } else {
      return signUp(normalizeProfile(cleanProfile(form)))
        .then(() => {
          localStorage.removeItem(type);
          setComplete(true);
          analytics('sign_up', { method: 'email' });
          if (onComplete) {
            onComplete();
          }
          return true;
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' })
          throw error;
        });
    }
  };

  if (profile && profile.type !== type) {
    return <GlobalLoading />
  }

  const renderHeader = () => header ? header : null;

  return (
    <div className={classes.root}>
      <FormContextProvider
          defaultState={{
            form: profile || defaultProfile,
            errors: {},
            step: 0,
            loggedIn: Boolean(profile)
          }}
          validate={validate}
          storageKey={!profile && !complete ? type : undefined}
        >
        <FormHelper className={classes.helper} textKey={type} header={helperHeader} />
        <main className={classes.container}>
          {renderHeader()}
          <FormStepper stepLabels={stepLabels} complete={complete} onComplete={handleComplete}>
            {children}
          </FormStepper>
        </main>
      </FormContextProvider>
    </div>
  );
}

Form.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  profile: PropTypes.object,
  defaultProfile: PropTypes.object.isRequired,
  synchronize: PropTypes.func,
  validate: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  header: PropTypes.node,
  helperHeader: PropTypes.node,
};
