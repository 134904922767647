import React from 'react';
import { makeStyles, Container, withWidth, isWidthDown, Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PeopleAlt, ListAlt, Settings, Dashboard, BusinessCenter, AssignmentInd, Place, AssignmentTurnedIn, Email, InsertChart, SupervisedUserCircle, BugReport } from '@material-ui/icons';
import SideMenu, { MenuWidth } from '../components/commons/SideMenu';
import UserTable from '../components/admin/UserTable';
import CollectionTable from '../components/admin/CollectionTable';
import TabPanel from '../components/admin/TabPanel';
import AdminDashboard from '../components/admin/AdminDashboard';
import AdminSettings from '../components/admin/Settings';
import { useHistory } from 'react-router-dom';
import AdminTable from '../components/admin/AdminTable';
import clsx from 'clsx';
import MatchTable from '../components/admin/Matches/MatchTable';
import TemplateList from '../components/admin/EmailTemplate/TemplateList';
import { useLocationHash } from '../utils/location';
import BottomMenu from '../components/commons/BottomMenu';
import History from '../components/admin/History';
import ErrorTable from '../components/admin/ErrorTable';
import { useDocument } from '../api/document';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    // marginBottom: '100px',
  },
  contentMenuOpen: {
    marginLeft: MenuWidth,
  },
  contentMenuClose: {
    marginLeft: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(9) + 1,
    },
  }
}));

const UserPanel = () =>
  <TabPanel tabs={[
    { name: 'users', label: 'users', content: <UserTable /> },
    { name: 'admins', label: 'administrators', content: <AdminTable /> },
  ]} />;

const SkillTable = () =>
  <TabPanel tabs={[
    { name: 'activities', label: 'activities', content: <CollectionTable collection="skills" title="activities" filter={['category', 'activities']} /> },
    { name: 'soft', label: 'softs', content: <CollectionTable collection="skills" title="softs" filter={['category', 'softs']} /> },
    { name: 'tools', label: 'tools', content: <CollectionTable collection="skills" title="tools" filter={['category', 'tools']} /> },
  ]} />;

const RoleTable = () =>
  <TabPanel tabs={[
    { name: 'roles', label: 'roles', content: <CollectionTable collection="roles" title="roles" /> },
    { name: 'disciplines', label: 'disciplines', content: <CollectionTable collection="disciplines" title="disciplines" /> },
  ]} />;

const PlaceTable = () =>
  <TabPanel tabs={[
    { name: 'regions', label: 'regions', content: <CollectionTable collection="places" title="places" filter={['type', 'region']} /> },
    { name: 'countries', label: 'countries', content: <CollectionTable collection="places" title="places" filter={['type', 'country']} /> },
  ]} />;

const ContentView = React.memo(({ visible, children }) => {
  return (
    <React.Fragment>
      {visible && children}
    </React.Fragment>
  );
});

const DOCUMENT_OPTIONS = { listen: true };

function ErrorIcon() {
  const { document } = useDocument('realtime', 'statistics', DOCUMENT_OPTIONS);
  return (
    <Badge badgeContent={document?.emails_error} color="error">
      <BugReport />
    </Badge>
  );
}

function Admin({ width }) {
  const { t } = useTranslation('admin');
  const classes = useStyles();
  const [open, setOpen] = React.useState(['xl', 'lg', 'md'].includes(width));
  const { pathname, hash } = useLocationHash();
  const browserHistory = useHistory();
  const items = React.useMemo(() => [
    { key: '', text: t('dashboard'), icon: <Dashboard />, content: <AdminDashboard /> },
    { key: 'errors', text: t('errors'), icon: <ErrorIcon />, content: <ErrorTable /> },
    { key: 'history', text: t('history'), icon: <InsertChart />, content: <History /> },
    { key: 'users', text: t('users'), icon: <PeopleAlt />, content: <UserPanel /> },
    { key: 'matches', text: t('matches'), icon: <AssignmentTurnedIn />, content: <MatchTable search /> },
    { key: 'templates', text: t('templates'), icon: <Email />, content: <TemplateList /> },
    { key: 'roles', text: t('roles'), icon: <AssignmentInd />, content: <RoleTable /> },
    { key: 'sectors', text: t('sectors'), icon: <BusinessCenter />, content: <CollectionTable collection="sectors" title="sectors" /> },
    { key: 'places', text: t('places'), icon: <Place />, content: <PlaceTable /> },
    { key: 'skills', text: t('skills'), icon: <ListAlt />, content: <SkillTable /> },
    { key: 'about', text: t('about'), icon: <SupervisedUserCircle />, link: '/about' },
    { key: 'divider1', divider: true },
    { key: 'settings', text: t('settings'), icon: <Settings />, content: <AdminSettings /> },
  ], [t]);
  const small = isWidthDown('xs', width);

  const [view, setView] = React.useState(hash || '');

  const handleClick = (item) => {
    if (item.link) {
      browserHistory.push(item.link);
    } else {
      setView(item.key);
      browserHistory.push(`${pathname}#${item.key}`);
    }
  };

  const handleOpen = (open) => {
    setOpen(open);
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" disableGutters={small}>
        { !small && <SideMenu items={items} onClick={handleClick} onOpen={handleOpen} open={open} selected={view} /> }
        <main className={clsx(classes.content, !small && {
          [classes.contentMenuOpen]: open,
          [classes.contentMenuClose]: !open,
        })}>
          {/* {!small && <div className={classes.toolbar} />} */}
          {items.map(item =>
            <ContentView key={item.key} visible={view === item.key}>{item.content}</ContentView>
          )}
        </main>
      </Container>
      { small && <BottomMenu items={items} onClick={handleClick} selected={view} /> }
    </React.Fragment>
  );
}

export default withWidth()(Admin);
