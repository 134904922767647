import React, { useMemo } from 'react';
import { FormControlLabel, FormGroup, makeStyles, MenuItem, Paper, Select, Checkbox, OutlinedInput } from '@material-ui/core';
import { blue, deepPurple, green, grey, red, teal } from '@material-ui/core/colors';
import HistoryLine from './Statistics/HistoryLine';
import { useTranslation } from 'react-i18next';
import { useSessionState } from '../../api/states';
import { BarChart, Timeline } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import HistoryBar from './Statistics/HistoryBar';
import { StatsProvider, useHistoryStatistics } from '../../api/admin/statistics';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    height: 'calc(100vh - 150px)'
  },
  chart: {
    position: 'relative',
    flex: 1,
  },
  panel: {
    marginLeft: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  panelToolbar: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  panelContent: {
    marginTop: theme.spacing(2),
    overflowY: 'auto',
    flexWrap: 'nowrap',
  }
}));

const STATISTICS = [
  { name: 'users', color: deepPurple[900] },
  { name: 'users_active', color: deepPurple[400] },
  { name: 'users_disabled', color: deepPurple[200] },
  { name: 'candidates', color: blue[900] },
  { name: 'candidates_active', color: blue[500] },
  { name: 'candidates_disabled', color: blue[200] },
  { name: 'recruiters', color: red[900] },
  { name: 'recruiters_active', color: red[500] },
  { name: 'recruiters_disabled', color: red[200] },
  { name: 'jobs', color: teal[900] },
  { name: 'jobs_active', color: teal[500] },
  { name: 'jobs_disabled', color: teal[200] },
  { name: 'matches_new', color: blue[200] },
  { name: 'matches_pending', color: blue[500] },
  { name: 'matches_discussion', color: blue[900] },
  { name: 'matches_succeeded', color: green[500] },
  { name: 'matches_failed', color: red[500] },
  { name: 'matches_canceled', color: red[900] },
  { name: 'matches_expired', color: red[700] },
  { name: 'emails', color: grey[800] },
  { name: 'emails_sent', color: grey[500] },
  { name: 'emails_error', color: red[500] },
];

const DAYS = [3, 7, 30, 182, 365, 0];

// function statsProvider(data, name) {
//   if (name.endsWith('_active')) {
//     const baseName = name.substr(0, name.indexOf('_active'));
//     const disabledName = `${baseName}_disabled`;
//     if (baseName in data && disabledName in data) {
//       return data[baseName] - data[disabledName];
//     }
//     return 0;
//   }
//   return data[name] || 0;
// }

function History() {
  const classes = useStyles();
  const [fields, setFields] = useSessionState('history_fields', []);
  const [days, setDays] = useSessionState('history_days', DAYS[0]);
  const [type, setType] = useSessionState('history_type', 'line');
  const { t } = useTranslation('statistics');
  const controller = useMemo(() => new StatsProvider(STATISTICS, t), [t]);
  const { data } = useHistoryStatistics({ days, controller, fields, background: type !== 'line' });

  const handleDaysChange = (event) => {
    setDays(+event.target.value);
  };

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setFields([...fields, STATISTICS.find(entry => entry.name === name)]);
    } else {
      setFields(fields.filter(entry => entry.name !== name));
    }
  };

  const handleChartType = (event, newType) => {
    if (newType) {
      setType(newType);
    }
  };

  const isChecked = name => Boolean(fields.find(entry => entry.name === name));
  const Chart = type === 'line' ? HistoryLine : HistoryBar;

  return (
    <Paper className={classes.root}>
      <div className={classes.chart}>
        <Chart data={data} />
      </div>
      <div className={classes.panel}>
        <div className={classes.panelToolbar}>
          <ToggleButtonGroup
            exclusive
            value={type}
            onChange={handleChartType}
            size="small"
          >
            <ToggleButton value="line">
              <Timeline />
            </ToggleButton>
            <ToggleButton value="bar">
              <BarChart />
            </ToggleButton>
          </ToggleButtonGroup>
          <Select value={days} onChange={handleDaysChange} input={<OutlinedInput margin='dense' />}>
            {DAYS.map(days =>
              <MenuItem key={days} value={days}>{t('history.days', { count: days, context: `${days}` })}</MenuItem>
            )}
          </Select>
        </div>
        <FormGroup className={classes.panelContent}>
          {STATISTICS.map(({ name, color }) => {
            const checked = isChecked(name);
            return (
              <FormControlLabel
                key={name}
                control={
                  <Checkbox
                    name={name}
                    checked={checked}
                    onChange={handleCheckbox}
                    style={checked ? { color } : null}
                  />
                }
                label={t(`history.${name}`)}
              />
            );
          })}
        </FormGroup>
      </div>
    </Paper>
  );
}

export default History;
