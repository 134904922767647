import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

function AsyncButton(props) {
  const { children, loading, variant, color, size, ...others } = props;
  return (
    <Button
      variant={variant || 'contained'}
      color={color || 'primary'}
      disabled={loading}
      size={size || 'large'}
      {...others}
    >
      { loading ?
        <CircularProgress size={24} />
        :
        children
      }
    </Button>
  );
}

AsyncButton.propTypes = {
  ...Button.propTypes,
  loading: PropTypes.bool
};

export default AsyncButton;
