import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import PropType from 'prop-types';
import { Paper } from '@material-ui/core';

function mapOption(option) {
  if (typeof option === 'object') {
    if (option.temp) {
      return undefined;
    }
    return option.id;
  }
  return option;
}
function TagPapper({...props}) {
  return (
    <Paper {...props} elevation={10} />
  );
}

export default function Tags({
  id,
  label,
  placeholderText,
  placeholder,
  loading,
  data,
  value,
  onChange,
  size,
  error,
  helperText,
  autoFocus,
  limit,
  color,
  blacklist,
  ...others
}) {

  const handleChange = (event, newValue) => {
    if (!limit || newValue.length < value.length || newValue.length <= limit) {
      onChange(newValue.map(mapOption));
    }
  };

  if (blacklist) {
    data = data.filter((option) => blacklist.indexOf(option.id) === -1);
  }

  if (placeholder && data.length > 1) {
    placeholderText = `${data[0].name}, ${data[1].name}, ...`;
  }

  return (
    <Autocomplete
      id={id}
      multiple
      autoComplete
      autoHighlight
      disableClearable
      disableCloseOnSelect
      filterSelectedOptions
      fullWidth
      loading={loading}
      size={size}
      popupIcon=""
      options={data}
      getOptionLabel={(option) => option.name}
      getOptionSelected={(option, v) => option.id === v}
      PaperComponent={TagPapper}
      renderOption={(option, state) => (
        <Chip key={option.id} variant="outlined" color={color} label={option.name} size={size} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={value && value.length > 0 ? undefined : placeholderText}
          error={error}
          helperText={helperText}
          autoFocus={autoFocus}
        />
      )}
      renderTags={(options, getTagProp) =>
        options.map((id, index) => {
          const option = data.find(tag => tag.id === id);
          if (!option) {
            return undefined;
          }
          return (
            <Chip color={option.color || color} label={option.name} size={size} {...getTagProp({ index })} />
          );
        })
      }
      value={value}
      onChange={handleChange}
      {...others}
    />
  );
}

Tags.defaultProps = {
  color: 'primary',
};

Tags.propTypes = {
  id: PropType.string.isRequired,
  label: PropType.string,
  placeholderText: PropType.string,
  value: PropType.array,
  onChange: PropType.func,
  size: PropType.oneOf(['small', 'medium']),
  error: PropType.bool,
  helperText: PropType.string,
  limit: PropType.number,
  loading: PropType.bool,
  data: PropType.array,
  color: PropType.string,
  blacklist: PropType.array,
  ...Autocomplete.propTypes,
};
