import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useCollection } from '../../api/collection';
import EditableTable from '../commons/EditableTable';
import { useTranslation } from 'react-i18next';

function CollectionTable({ title, collection: name, filter, hidden }) {
  const { t } = useTranslation('admin');
  const { enqueueSnackbar } = useSnackbar();
  const options = React.useMemo(() => (filter ? { where: [filter[0], '==', filter[1]], listen: true } : { listen: true }), [filter]);
  const { loading, collection, data } = useCollection(name, options);

  if (hidden) {
    return <React.Fragment />;
  }

  const handleError = (error) => {
    enqueueSnackbar(error.message, { variant: 'error' });
    throw error;
  };

  const handleRowAdd = (newData) => {
    if (filter) {
      return collection.addDocument({...newData, [filter[0]]: filter[1]}).catch(handleError);
    }
    return collection.addDocument(newData).catch(handleError);
  };

  const handleRowUpdate = (newData, oldData) => {
    if (filter) {
      return collection.updateDocument(oldData.id, {...newData, [filter[0]]: filter[1]}).catch(handleError);  
    }
    return collection.updateDocument(oldData.id, newData).catch(handleError);
  };

  return (
    <EditableTable
      isLoading={loading}
      title={t(title)}
      columns={[
        { title: t('table.name'), field: 'name' },
        { title: t('table.created'), field: 'created', editable: 'never', type: 'date', searchable: false },
        { title: t('table.updated'), field: 'updated', editable: 'never', type: 'date', searchable: false },
      ]}
      data={data}
      editable={{
        onRowAdd: handleRowAdd,
        onRowUpdate: handleRowUpdate,
        onRowDelete: oldData => collection.deleteDocument(oldData.id).catch(handleError),
      }}
    />
  );
}

CollectionTable.propTypes = {
  title: PropTypes.string,
  collection: PropTypes.string.isRequired,
  filter: PropTypes.array,
  hidden: PropTypes.bool,
};

export default CollectionTable;
