import React, { useEffect, useMemo } from 'react';
import { makeStyles, Box, Button } from '@material-ui/core';
import { RadioInput, TagInput, SelectInput, SliderInput, DateInput, Visible, TextInput } from '../components/inputs/FormSections';
import { getContractList, getRecruiterList, getJobTypes, getDegreeList, getExperienceMarks, getPeriodMarks, getYesNo } from '../utils/dataProvider';
import { Trans, useTranslation } from 'react-i18next';
import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { PrimarySkills, ComplementarySkills } from '../components/inputs/SkillInputs';
import Remuneration from '../components/inputs/Remuneration';
import WebLinks from '../components/inputs/WebLinks';
import Profile from '../components/inputs/Profile';
import { isLinkedInUrl, isValidDate, normalizeProfile } from '../utils/formUtils';
import { isNewEmail } from '../api/auth';
import Form from '../components/Form';
import { LastJobExperiences } from '../components/inputs/LastJobs';
import RGPD from '../components/RGPD';
import { useLoggedIn } from '../contexts/FormContext';
import { useProfile } from '../api/session';
import { useHistory } from 'react-router-dom';
import { useLocationQuery } from '../utils/location';
import { ArrowBack } from '@material-ui/icons';
import { usePrevious } from '../api/states';
import RecruiterJobs from '../components/RecruiterJobs';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(2),
  },
  section: {
    width: '100%',
  },
}));

const TYPE = 'recruiter';

const defaultProfile = {
  type: TYPE,
  recruiter: '',
  sector: '',
  roles: [],
  contract: '',
  start: new Date(Date.now() + 86400000), // Tomorrow
  duration: 0,
  jobType: '',
  international: 'no',
  place: '',
  experience: 0,
  degree: '',
  disciplines: [],
  expert: [],
  proficient: [],
  novice: [],
  softs: [],
  tools: [],
  languages: [],
  lastJobs: {},
  salary: '',
  adr: '',
  freeText: '',
  website: '',
  linkedIn: '',
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  company: '',
  rgpd: false,
};

const isSectorVisible = (recruiter) => (recruiter.length > 0 && recruiter !== 'association' && recruiter !== 'individual');
const isDurationVisible = (contract) => (contract.length > 0 && contract !== 'cdi');
const isPlaceVisible = (jobType) => (jobType.length > 0 && jobType !== 'remote');
const isRemunerationVisible = (contract) => contract !== 'volunteer';
const isAnnualSalaryVisible = (contract) => contract === 'cdi';
const isMonthlySalaryVisible = (contract) => contract === 'cdd' || contract === 'intership' || contract === 'apprenticeship';
const isSalaryVisible = (contract) => isAnnualSalaryVisible(contract) || isMonthlySalaryVisible(contract);
const isAdrVisible = (contract) => contract === 'freelance';
const isCompanyVisible = (recruiter) => recruiter !== 'individual';
const isInternational = (form) => form.international === 'yes';
const isLocal = (form) => form.international === 'no';

const COUNTRIES_OPTIONS = { filter: ['type', 'country'], autocomplete: true };
const REGIONS_OPTIONS = { filter: ['type', 'region'], autocomplete: true };

function RecruiterStep1() {
  const classes = useStyles();
  const { t } = useTranslation();
  const loggedIn = useLoggedIn();
  const periodMarks = React.useMemo(() => getPeriodMarks(t), [t]);

  const handleInternational = (value, dispatch) => {
    dispatch({ type: 'change', value: '', name: 'place' });
  };

  return (
    <Box className={classes.section}>
      <RadioInput name="recruiter" getFields={() => getRecruiterList(t)} title={t('recruiter.recruiter')} />
      <Visible when={(form) => isSectorVisible(form.recruiter)}>
        <SelectInput name="sector" collection="sectors" label={t('form.sector')} inputProps={{ variant: 'outlined' }} title={t('recruiter.sector')} />
      </Visible>
      <TagInput
        name="roles"
        collection="roles"
        title={t('recruiter.designer')}
        propose={{
          name: 'newRoles',
          title: t('form.missingRoleTitle'),
          tooltip: t('form.missingRoleTooltip')
        }}
      />
      <RadioInput name="contract" getFields={() => getContractList(t)} title={t('recruiter.contract')} />
      <DateInput name="start" title={t('recruiter.start')} disablePast={!loggedIn} />
      <Visible when={(form) => isDurationVisible(form.contract)}>
        <SliderInput name="duration" marks={periodMarks} title={t('recruiter.duration')} legend="exact" />
      </Visible>
      <RadioInput name="jobType" getFields={() => getJobTypes(t)} title={t('recruiter.jobType')} />
      <Visible when={(form) => isPlaceVisible(form.jobType)}>
        <RadioInput
          name="international"
          title={t('recruiter.international')}
          getFields={() => getYesNo(t)}
          onChange={handleInternational}
        />
        <Visible when={isInternational}>
          <SelectInput name="place" title={t('recruiter.place')} collection="places" label={t('recruiter.country')} inputProps={COUNTRIES_OPTIONS} />
        </Visible>
        <Visible when={isLocal}>
          <SelectInput name="place" title={t('recruiter.place')} collection="places" label={t('recruiter.region')} inputProps={REGIONS_OPTIONS} />
        </Visible>
      </Visible>
    </Box>
  );
}

function RecruiterStep2() {
  const classes = useStyles();
  const { t } = useTranslation();
  const expMarks = React.useMemo(() => getExperienceMarks(t), [t]);

  return (
    <Box className={classes.section}>
      <SliderInput name="experience" title={t('recruiter.experience')} marks={expMarks} legend="lower" />
      <RadioInput name="degree" getFields={() => getDegreeList(t)} title={t('recruiter.degree')} />
      <TagInput name="disciplines" collection="disciplines" title={t('recruiter.discipline')} />
      <PrimarySkills namespace={TYPE} limit={3} />
      <ComplementarySkills namespace={TYPE} limit={6} />
      <LastJobExperiences name="lastJobs" title={t('recruiter.lastJobs')} />
      <Visible when={(state) => isRemunerationVisible(state.contract)}>
        <Remuneration
          title={t('recruiter.remuneration')}
          annualVisible={(state) => isAnnualSalaryVisible(state.contract)}
          monthlyVisible={(state) => isMonthlySalaryVisible(state.contract)}
          adrVisible={(state) => isAdrVisible(state.contract)}
        />
      </Visible>
    </Box>
  );
}

function RecruiterStep3() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.section}>
      <TextInput name="freeText" title={t('recruiter.freeText')} multiline max={2000} rows={8} />
      <WebLinks title={t('recruiter.links')} />
      <Visible when={(state) => isCompanyVisible(state.recruiter)}>
        <TextInput
          name="company"
          title={t('recruiter.company')}
          label={t('common.company')}
          inputProps={{
            id: 'company',
            name: 'company',
            autoComplete: 'organization',
          }}
        />
      </Visible>
      <Profile title={t('recruiter.profile')} />
      <RGPD />
    </Box>
  );
}

async function validate(state, dispatch, step) {
  const loggedIn = state.loggedIn;
  const form = normalizeProfile(state.form);
  let errors;

  switch (step) {
    case (0) :
      errors = {
        recruiter: form.recruiter.length === 0,
        sector: isSectorVisible(form.recruiter) && form.sector.length === 0,
        roles: form.roles.length === 0,
        contract: form.contract.length === 0,
        start: !isValidDate(form.start),
        duration: isDurationVisible(form.contract) && form.duration.length === 0,
        jobType: form.jobType.length === 0,
        place: isPlaceVisible(form.jobType) && form.place.length === 0,
      };
      break ;
    case (1):
      errors = {
        experience: form.experience === undefined,
        degree: form.degree.length === 0,
        primary: form.expert.length === 0 && form.proficient.length === 0 && form.novice.length === 0,
        languages: form.languages.length === 0,
        salary: isRemunerationVisible(form.contract) && isSalaryVisible(form.contract) && (!form.salary || form.salary.length === 0),
        adr: isRemunerationVisible(form.contract) && isAdrVisible(form.contract) && (!form.adr || form.adr.length === 0),
      };
      break ;
    case (2):
      errors = {
        freeText: form.freeText.length === 0,
        website: form.website.length !== 0 && !isURL(form.website),
        linkedIn: form.linkedIn.length !== 0 && !isLinkedInUrl(form.linkedIn),
        company: isCompanyVisible(form.recruiter) && form.company.length === 0,
        firstname: !loggedIn && form.firstname.length < 2,
        lastname: !loggedIn && form.lastname.length < 2,
        email: !loggedIn && (!isEmail(form.email) || form.email !== form.emailConfirm || !(await isNewEmail(form.email))),
        emailConfirm: !loggedIn && form.email !== form.emailConfirm,
        phone: form.phone.length !== 0 && !isMobilePhone(form.phone),
        password: !loggedIn && (!form.password || form.password.length < 6),
        rgpd: !form.rgpd
      };
      break ;
    default:
      break ;
  }
  if (errors && Object.values(errors).some(value => value)) {
    dispatch({ type: 'errors', value: errors });
    return { success: false, errors: errors };
  }
  return { success: true };
}

function initProfile(profile, jobId) {
  if (profile) {
    if (profile.jobs && jobId) {
      if (jobId === 'new') {
        profile = {...defaultProfile, ...profile, jobId};
      } else if (jobId in profile.jobs) {
        const { id, disabled, ...job } = profile.jobs[jobId];
        profile = {...profile, ...job, jobId, jobDisabled: disabled };
      }
    }
    if (profile.international === undefined) {
      profile.international = 'no';
    }
  }
  return profile;
}

function BackButton({ onClick }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button variant="contained" color="primary" onClick={onClick} startIcon={<ArrowBack />}>
        <Trans i18nKey="job.back" />
      </Button>
    </div>
  );
}

function Recruiter() {
  const { profile: profileData, synchronize, synchronizing } = useProfile();
  const jobId = useLocationQuery().get('job');
  const previousJobId = usePrevious(jobId);
  const history = useHistory();
  const profile = useMemo(() => initProfile(profileData, jobId), [profileData, jobId]);

  useEffect(() => {
    if (!jobId && previousJobId) {
      const el = document.getElementById(previousJobId);
      if (el) {
        el.scrollIntoView();
        return ;
      }
    }
    window.scrollTo(0, 0);
  }, [jobId, previousJobId]);

  if (profile && !jobId) {
    return (
      <RecruiterJobs
        profile={profile}
        synchronize={synchronize}
        synchronizing={synchronizing}
        history={history}
      />
    );
  }

  const toJobList = () => {
    history.push(history.location.pathname);
  };

  const handleComplete = jobId === 'new' ? toJobList : null;
  
  return (
    <Form
      type={TYPE}
      profile={profile}
      defaultProfile={defaultProfile}
      synchronize={synchronize}
      validate={validate}
      onComplete={handleComplete}
      helperHeader={profile ? <BackButton onClick={toJobList} /> : null}
    >
      <RecruiterStep1 />
      <RecruiterStep2 />
      <RecruiterStep3 />
    </Form>
  );
}

export default Recruiter;
