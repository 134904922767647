import React from 'react';
import PropTypes from 'prop-types';
import { TextField, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

function Select({ options, autocomplete, loading, label, inputProps, onChange, value, ...others}) {
  const items = React.useMemo(() => {
    switch (typeof options) {
      case 'function':
        return options();
      default:
        return options;
    }
  }, [options]);

  if (autocomplete) {
    const handleChange = onChange ? (event, newValue) => {
      onChange(newValue.id);
    } : undefined;

    const selectedValue = items.find(item => item.id === value) || null;

    return (
      <Autocomplete
        autoComplete
        autoHighlight
        disableClearable
        options={items}
        loading={loading}
        getOptionLabel={(option) => option.name || ''}
        getOptionSelected={(option, v) => option.id === v.id}
        onChange={handleChange}
        value={loading ? null : selectedValue}
        {...others}
        renderInput={(params) => <TextField label={label} {...inputProps} {...params} />}
      />
    );
  }

  const handleChange = onChange ? (event) => {
    onChange(event.target.value);
  } : undefined;

  return (
    <TextField
      select
      label={label}
      onChange={handleChange}
      value={loading || items.length === 0 ? '' : value}
      {...inputProps}
      {...others}
    >
      { items.map(item =>
        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
      )}
    </TextField>
  );
}

Select.propTypes = {
  options: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  ...TextField.propTypes
};

export default Select;
