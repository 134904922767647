import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormLabel, FormControlLabel, FormHelperText, RadioGroup, Radio } from '@material-ui/core';

function RadioboxGroup({ required, legend, onChange, error, helperText, value, fields, className }) {
  const handleChange = onChange ? (event) => {
    onChange(event.target.value);
  } : null;

  return (
    <FormControl required={required} component="fieldset" className={className} error={error}>
      { legend ? <FormLabel component="legend">{legend}</FormLabel> : undefined }
      <RadioGroup value={value} onChange={handleChange}>
        { fields.map((field) => 
          <FormControlLabel
            value={field.id}
            control={<Radio color="primary" />}
            label={field.name}
            key={field.id}
          />
        ) }
      </RadioGroup>
      { helperText ? <FormHelperText>{helperText}</FormHelperText> : undefined }
    </FormControl>
  );
}

RadioboxGroup.propTypes = {
  required: PropTypes.bool,
  legend: PropTypes.string,
  onChange: PropTypes.func,
  helperText: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  })).isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default RadioboxGroup;
