import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Paper, CircularProgress, Typography, makeStyles, Container, Button } from '@material-ui/core';
import { useFetch } from '../api/fetch';
import { useTranslation } from 'react-i18next';
import { useSession } from '../api/session';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(4),
  },
  message: {
    whiteSpace: 'pre-wrap'
  },
}));

const FETCH_OPTIONS = {
  useCache: true
};

function getMessage(t, response) {
  if (response) {
    const { error, success, messageId } = response;
    if (error) {
      return t(error);
    } else if (success) {
      return messageId ? t(messageId) : t('action/default');
    }
  }
}

export default function Action() {
  const { t } = useTranslation('actions');
  const classes = useStyles();
  const { data } = useParams();
  const { loading, data: response } = useFetch(`/action/${data}`, FETCH_OPTIONS);
  const { user } = useSession();
  const history = useHistory();

  const handleClick = () => {
    history.push('/');
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.root}>
        { loading
        ? <CircularProgress />
        : <Typography variant="body1" align="center" className={classes.message}>
            {getMessage(t, response)}
          </Typography>
        }
        { user &&
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleClick}
            >
              {t('myProfile')}
            </Button>
        }
      </Paper>
    </Container>
  );
}
