import React, { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Submissions from './Submissions';
import Widget from './Widget';
import MatchTable from './Matches/MatchTable';
import Chart from './Statistics/Chart';
import { StatsProvider } from '../../api/admin/statistics';
import { useRealtimeDocument } from '../../api/document';
import statistics from '../../utils/statistics';
import Ratio from './Statistics/Ratio';
import TransformationTunnel from './Statistics/TransormationTunnel';

const MATCH_COLUMNS = ['context', 'profile', 'candidate', 'recruiter', 'created'];

const useStyles = makeStyles(theme => ({
  chart: {
    margin: theme.spacing(1, 0, 2),
    maxHeight: '350px',
    maxWidth: '350px',
  }
}));

function MatchWidget() {
  const { t } = useTranslation('admin');

  return (
    <Widget title={t('newMatches')}>
      <MatchTable state="new" columns={MATCH_COLUMNS} elevation={0} />
    </Widget>
  );
}
// const DOCUMENT_OPTIONS = { listen: true };

export default function AdminDashboard() {
  const classes = useStyles();
  const { t } = useTranslation('statistics');
  // const { document } = useDocument('realtime', 'statistics', DOCUMENT_OPTIONS);
  const { document } = useRealtimeDocument('statistics/realtime');
  const statsProvier = useMemo(() => new StatsProvider(statistics, t), [t]);

  const renderCharts = (names) => (
    <Widget>
      {names.map(name => {
        if (name in statistics.charts) {
          const type = statistics.charts[name].type;
          let ChartEl;
          if (type === 'ratio') {
            ChartEl = Ratio;
          } else if (type === 'tunnel') {
            ChartEl = TransformationTunnel;
          } else {
            ChartEl = Chart;
          }
          return (
            <ChartEl
              key={name}
              className={classes.chart}
              name={name}
              title={t(`titles.${name}`)}
              document={document}
              controller={statsProvier}
            />
          );
        }
        return null;
      })}
    </Widget>
  );

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12}>
        {renderCharts([
          'users',
          'jobs',
          'matches_tunnel',
        ])}
      </Grid>
      <Grid item container xs={12} lg={6}>
        {renderCharts([
          'candidates_contracts',
          'candidates_experience',
          'candidates_roles',
          'candidates_places',
          'candidates_sectors',
          'candidates_inpost_ratio',
        ])}
      </Grid>
      <Grid item container xs={12} lg={6}>
        {renderCharts([
          'recruiters_contracts',
          'recruiters_experience',
          'recruiters_roles',
          'recruiters_places',
          'recruiters_sectors',
          'recruiters_multijobs_ratio',
        ])}
      </Grid>
      <Grid item xs={12} lg={8}>
        <MatchWidget />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <Submissions />
      </Grid>
    </Grid>
  );
}
