import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Drawer, List, ListItemIcon, ListItemText, ListItem, makeStyles, Divider, IconButton } from '@material-ui/core';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';

export const MenuWidth = 260;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: MenuWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: MenuWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3, 0, 1),
    marginTop: theme.mixins.toolbar.minHeight,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function SideMenu({ items, onClick, onOpen, open, selected }) {
  const classes = useStyles();

  const toggleDrawer = () => {
    if (onOpen) {
      onOpen(!open);
    }
  };

  const renderItem = (item) => {
    if (item.divider) {
      return <Divider key={item.key} />;
    }
    return (
      <ListItem button key={item.key} onClick={() => onClick(item)} selected={item.key === selected}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItem>
    );
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={toggleDrawer} size="small">
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <List>
        {items.map(item => renderItem(item))}
      </List>
    </Drawer>
  );
}

SideMenu.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  selected: PropTypes.string,
};

export default SideMenu;
