import { Button, ListItem, makeStyles, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Skeleton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocument } from '../../../api/document';
import EmailPreview from '../../EmailPreview';
import UserName from '../UserName';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  emailView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  emailContent: {
    width: '100%',
    overflow: 'auto',
  }
}));

const DOCUMENT_OPTIONS = { cache: true };

function EmailItem({ id, connector, onClick }) {
  const { t } = useTranslation('admin');
  const { document } = useDocument(id, 'mail', DOCUMENT_OPTIONS);

  let time = null;
  let name = null;

  if (document) {
    time = document.delivery?.startTime.toLocaleDateString();
    name = t(`email.${document.template.name}`);
  }

  const openEmail = onClick ? () => {
    onClick(id);
  } : undefined;

  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <Typography variant="body2" color="textSecondary">{time ? time : <Skeleton />}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        {connector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <ListItem alignItems="flex-start" dense button onClick={openEmail}>{name ? name : <Skeleton />}</ListItem>
      </TimelineContent>
    </TimelineItem>
  );
}

function EmailTimeline({ emails, onOpenEmail }) {
  if (!(emails instanceof Array)) {
    return null;
  }
  return (
    <Timeline>
      {emails.map((email, index) =>
        <EmailItem
          key={email}
          id={email}
          connector={index < emails.length - 1}
          onClick={onOpenEmail}
        />
      )}
    </Timeline>
  );
}

function MatchEmails({ emails, candidateId, recruiterId }) {
  const { t } = useTranslation('admin');
  const classes = useStyles();
  const [openEmail, setOpenEmail] = useState(null);

  if (!emails) {
    return null;
  }

  const handleOpenEmail = (id) => {
    setOpenEmail(id);
  };

  const handleCloseEmail = () => {
    setOpenEmail(null);
  };

  if (openEmail) {
    return (
      <div className={classes.emailView}>
        <Button
          onClick={handleCloseEmail}
          color="primary"
          startIcon={<ArrowBack />}
        >{t('back')}</Button>
        <div className={classes.emailContent}>
          <EmailPreview id={openEmail} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div>
        <Typography align="center">{t('candidate')}</Typography>
        <UserName uid={candidateId} align="center" variant="h6" />
        <EmailTimeline emails={emails.candidate} onOpenEmail={handleOpenEmail} />
      </div>
      <div>
        <Typography align="center">{t('recruiter')}</Typography>
        <UserName uid={recruiterId} align="center" variant="h6" />
        <EmailTimeline emails={emails.recruiter} onOpenEmail={handleOpenEmail} />
      </div>
    </div>
  );
}

export default MatchEmails;
