import React from 'react';

const initialState = {};
const FormContext = React.createContext(initialState);

export const useForm = () => React.useContext(FormContext);

const useInput = (name) => {
  const { state, dispatch } = React.useContext(FormContext);
  if (name) {
    return {
      value: state.form[name],
      error: state.errors[name],
      dispatch,
    };
  }
  return {};
}

export function useLoggedIn() {
  const { state } = React.useContext(FormContext);
  return state.loggedIn;
}

export function connectToForm(WrappedComponent, options) {
  return function(props) {
    let selectors = {};
    if (options) {
      const { state, dispatch } = React.useContext(FormContext);
      selectors = {
        dispatch,
      };
      if (options.names) {
        options.names.forEach((name) => {
          selectors[name] = state.form[name];
        });
      }
      if (options.errors) {
        options.errors.forEach((error) => {
          selectors[`${error}Error`] = state.errors[error];
        });
      }
    } else {
      selectors = useInput(props.name);
    }
    return <WrappedComponent {...selectors} {...props}/>
  }
}

export function FormContextProvider({ children, defaultState, validate, storageKey }) {
  const [initialState, setInitialState] = React.useState(defaultState);

  React.useMemo(() => {
    if (storageKey) {
      readStorage(storageKey, ({form, step}) => {
        initialState.form = {...initialState.form, ...form};
        initialState.step = step;
      });
    }
  }, [storageKey, initialState]);

  const save = ({ form, step }) => {
    if (storageKey) {
      const filteredForm = Object.fromEntries(
        Object.entries(form).filter(([key, value]) => key !== 'password')
      );
      writeStorage(storageKey, { form: filteredForm, step });
    }
  }

  function reducer(state, action) {
    const { type, value, name } = action;
    let newState = {...state};
    switch (type) {
      case 'change':
        newState.form[name] = value;
        save(newState);
        return newState;
      case 'step':
        newState.step = value;
        newState.errors = {};
        save(newState);
        return newState;
      case 'error':
        newState.errors[name] = value;
        return newState;
      case 'errors':
        newState.errors = value;
        return newState;
      case 'reset':
        newState = value;
        return newState;
      default:
        throw new Error(`Unhandled action type: ${type}`);
    }
  }

  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    setInitialState(defaultState);
    dispatch({ type: 'reset', value: defaultState });
  }, [defaultState]);

  const submit = (options) => {
    return validate(state, dispatch, options);
  };

  return (
    <FormContext.Provider value={{ state, dispatch, submit }}>
      { children }
    </FormContext.Provider>
  );
}

function readStorage(key, callback) {
  const store = JSON.parse(localStorage.getItem(key));
  // console.log('[Form] reading from localStorage:', store);
  if (store) {
    if (store.form && store.form.start) {
      store.form.start = new Date(store.form.start);
    }
    callback(store);
  }
}

function writeStorage(key, store) {
  // console.log('[Form] writting to localStorage:', store);
  localStorage.setItem(key, JSON.stringify(store));
}
