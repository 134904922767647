import React, { useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

function ConfirmDialog({ title, text, onClose, onConfirm, cancel = 'Cancel', ok = 'OK', ...props }) {
  return (
    <Dialog onClose={onClose} {...props}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {text && <DialogContent><DialogContentText style={{ whiteSpace: 'pre-line' }}>{text}</DialogContentText></DialogContent>}
      <DialogActions>
        <Button onClick={onClose} color="primary">{cancel}</Button>
        <Button onClick={onConfirm} color="primary">{ok}</Button>
      </DialogActions>
    </Dialog>
  );
}

export function useConfirm() {
  const [options, setOptions] = useState(null);

  return useMemo(() => {
    const handleClose = () => {
      if (options) {
        options.callback(false);
        setOptions(null)
      }
    };
    const handleConfirm = () => {
      if (options) {
        options.callback(true);
        setOptions(null)
      }
    };

    return {
      ConfirmDialog: (props) =>
        <ConfirmDialog
          {...props}
          {...(options?.options || {})}
          open={Boolean(options)}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />,
      confirm: (options, callback) => {
        if (options && callback) {
          setOptions({
            options,
            callback
          });
        }
      },
    }
  }, [options]);
}

export default ConfirmDialog;
