import React from 'react';
import { TextField, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormSection, Visible } from '../inputs/FormSections';
import { connectToForm } from '../../contexts/FormContext';

const useStyles = makeStyles((theme) => ({
  numberContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  salaryInput: {
    width: '100px',
    padding: theme.spacing(2, 1, 1),
    textAlign: 'right',
  },
  adrInput: {
    width: '70px',
    padding: theme.spacing(1),
  },
  remunerationSeparator: {
    paddingLeft: theme.spacing(3),
  }
}));

function Remuneration({ title, annualVisible, monthlyVisible, adrVisible, dispatch, salary, adr, salaryError, adrError }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const error = salaryError || adrError;
  let monthlyId = "salary";

  const handleChange = (event, name) => {
    dispatch({ type: 'change', name, value: Math.floor(event.target.value) });
  };

  const handleMonthly = (event) => {
    dispatch({ type: 'change', name: 'salary', value: (event.target.value * 12) });
  };

  const handleAnnualVisible = (form) => {
    const visible = annualVisible(form);
    if (visible) {
      monthlyId = "monthlySalary";
    }
    return visible;
  };

  return (
    <FormSection title={title} error={error}>
      <Visible when={handleAnnualVisible}>
        <div className={classes.numberContainer}>
          <Typography variant="body1">{t('form.salary')}</Typography>
          <TextField
            id="salary"
            value={`${Math.floor(salary)}`}
            onChange={(event) => handleChange(event, 'salary')}
            type="number"
            size="small"
            className={classes.salaryInput}
            error={salaryError}
          />
          <Typography variant="body1">{t('form.salarySuffix')}</Typography>
        </div>
      </Visible>
      <Visible when={monthlyVisible}>
        <div className={classes.numberContainer}>
          <Typography variant="body1">{t('form.salaryMonthly')}</Typography>
          <TextField
            id={monthlyId}
            value={`${Math.round(salary / 12)}`}
            onChange={handleMonthly}
            type="number"
            size="small"
            className={classes.salaryInput}
            error={salaryError}
          />
          <Typography variant="body1">{t('form.salaryMonthlySuffix')}</Typography>
        </div>
      </Visible>
      <Visible when={adrVisible}>
        <div className={classes.numberContainer}>
          <Typography variant="body1">{t('form.adr')}</Typography>
          <TextField
            id="adr"
            value={`${Math.floor(adr)}`}
            onChange={(event) => handleChange(event, 'adr')}
            type="number"
            size="small"
            className={classes.adrInput}
            error={adrError}
          />
          <Typography variant="body1">{t('form.adrSuffix')}</Typography>
        </div>
      </Visible>
    </FormSection>
  );
}

export default connectToForm(React.memo(Remuneration), {
  names: ['salary', 'adr'],
  errors: ['salary', 'adr'],
});
