import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, makeStyles, Menu, MenuItem, Paper } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import SearchInput from './SearchInput';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'baseline',
  },
}));

function exportFile(filename, data) {
  const element = document.createElement('a');
  const file = new Blob([data], { type: 'text/plain;charset=utf-8' });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}

function Toolbar({ searchLoading, nbSearchResults, onSearch, onExport, exportTypes }) {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const [loading, setLoading] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const searchHelper = typeof nbSearchResults === 'number'
    ? <Trans t={t} i18nKey="result" count={nbSearchResults}>{{nbSearchResults}} result(s)</Trans>
    : null;
  
  const handleMenuOpen = (event) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);

  const handleExport = (type) => () => {
    handleMenuClose();
    if (onExport) {
      setLoading(true);
      onExport(type)
        .then(({ filename, data }) => exportFile(filename, data))
        .finally(() => setLoading(false));
    }
  };

  const renderMenu = () => {
    if (onExport) {
      return (
        <>
          { loading
            ? <IconButton><CircularProgress size={24} /></IconButton>
            : <IconButton onClick={handleMenuOpen}><MenuIcon /></IconButton>
          }
          <Menu
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            {exportTypes
            ? exportTypes.map(type =>
              <MenuItem key={type} onClick={handleExport(type)}>{t('export', { context: type })}</MenuItem>
            )
            : <MenuItem onClick={handleExport()}>{t('export')}</MenuItem>
            }
          </Menu>
        </>
      );
    }
    return null;
  };

  return (
    <Paper className={classes.root}>
      <SearchInput
        label={t('search')}
        onSearch={onSearch}
        loading={searchLoading}
        helperText={searchHelper}
      />
      {renderMenu()}
    </Paper>
  );
}

export default Toolbar;
