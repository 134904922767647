import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Fab, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    transition: theme.transitions.create('max-height', { duration: 1000 }),
  },
  button: {
    marginTop: theme.spacing(2),
  }
}));

const ExpandButton = ({ visible, expanded, ...props }) => (visible || expanded) ? (
  <Fab size="small" color="primary" {...props}>
    { expanded ? <ExpandLess /> : <ExpandMore /> }
  </Fab>
) : null;

function Expander({ children, className, refresh, incremental, ...props }) {
  const classes = useStyles();
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);
  const [locked, setLocked] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandIndex, setExpandIndex] = useState(1);
  const baseHeight = useRef(null);

  useEffect(() => {
    if (ref.current && !locked) {
      const observer = new ResizeObserver(entries => {
        if (entries.length) {
          const el = entries[0].target;
          if (baseHeight.current === null) {
            baseHeight.current = el.clientHeight;
          }
          setVisible(el.scrollHeight > el.clientHeight);
        }
      });
      observer.observe(ref.current);
      return () => observer.disconnect();
    }
  }, [ref, expanded, locked, refresh]);

  const handleExpand = () => {
    if (incremental) {
      setExpanded(false);
      setExpandIndex(expandIndex + 1);
    } else {
      setExpanded(!expanded);
    }
    setVisible(true);
    setLocked(true); // Lock during animation
    setTimeout(() => setLocked(false), 1000);
  };

  const style = useMemo(() => {
    if (expandIndex > 1 && incremental && baseHeight.current) {
      return { maxHeight: baseHeight.current * expandIndex };
    } else if (expanded) {
      return { maxHeight: ref.current?.scrollHeight };
    }
    return null;
  }, [expanded, expandIndex, incremental]);

  return (
    <div className={classes.root}>
      <div className={clsx([className, classes.content])} style={style} {...props} ref={ref}>
        {children}
      </div>
      <ExpandButton
        className={classes.button}
        expanded={expanded}
        visible={visible}
        onClick={handleExpand}
      />
    </div>
  );
}

export default Expander;
