import React from 'react';
import PropType from 'prop-types';
import Tags from './commons/Tags';
import { useCollection } from '../api/collection';
import { useForm } from '../contexts/FormContext';
import Select from './commons/Select';

function createQueryOption(filter, orderBy) {
  if (filter || orderBy) {
    if (filter && orderBy && filter[0] !== orderBy) {
      throw new Error('Invalid query : cannot filter and order on different properties');
    }
    return {
      where: filter ? [filter[0], '==', filter[1]] : undefined, 
      orderBy,
    };
  }
}

export function CollectionSelect({ collection, filter, orderBy, sort, ...others }) {
  const options = React.useMemo(() => createQueryOption(filter, orderBy), [filter, orderBy]);
  const { loading, data } = useCollection(collection, options);
  const sortedData = React.useMemo(() => sort && data ? data.sort(sort) : data, [sort, data]);

  return (
    <Select
      options={sortedData}
      loading={loading}
      {...others}
    />
  );
}

CollectionSelect.propTypes = {
  ...Select.propTypes,
  collection: PropType.string.isRequired,
  filter: PropType.array,
  orderBy: PropType.array,
};

function connectToMoreData(WrappedComponent) {
  return function({ moreData, ...props }) {
    const { state, dispatch } = useForm();
    const name = moreData;
    const selectors = {};
    if (name) {
      selectors.more = state.form[name];
      selectors.setMore = (value) => dispatch({ type: 'change', name, value});
    }
    return <WrappedComponent {...selectors} {...props}/>
  }
}

export const CollectionTags = connectToMoreData(React.memo(({
  collection,
  value,
  onChange,
  more,
  setMore,
  filter,
  orderBy,
  sort,
  ...others
}) => {
  const options = React.useMemo(() => createQueryOption(filter, orderBy), [filter, orderBy]);
  const { loading, data } = useCollection(collection, options);
  const sortedData = React.useMemo(() => sort && data ? data.sort(sort) : data, [sort, data]);
  const allValues = more ? [...value, ...more] : value;
  const allData = more ? [...sortedData, ...more.map(item => ({id: item, name: item, color: 'secondary', temp: true}))] : sortedData;

  React.useEffect(() => {
    if (more && more.length > 0) {
      const newMore = [...more];
      const newValue = [...value];
      more.forEach((name, index) => {
        const found = sortedData.find(element => element.name.localeCompare(name, 'fr', { sensitivity: 'base' }) === 0);
        if (found) {
          newMore.splice(index, 1);
          if (!value.includes(found.id)) {
            newValue.push(found.id);
          }
        }
      });
      if (newMore.length !== more.length) {
        setMore(newMore);
        onChange(newValue);
      }
    }
  }, [sortedData, more, value, setMore, onChange]);

  const handleChange = (newValue) => {
    if (more) {
      setMore(newValue.filter(element => more.indexOf(element) !== -1));
      onChange(newValue.filter(element => more.indexOf(element) === -1));
    } else {
      onChange(newValue);
    }
  };

  return (
    <Tags
      loading={loading}
      value={allValues}
      onChange={handleChange}
      data={allData}
      {...others}
    />
  );
}));

CollectionTags.propTypes = {
  ...Tags.propTypes,
  collection: PropType.string.isRequired,
  moreData: PropType.string,
  placeholder: PropType.bool,
  filter: PropType.array,
  orderBy: PropType.array,
  sort: PropType.func,
};
