import firebase from 'firebase/app';
import 'firebase/firestore';

const dataConverter = {
  toFirestore: (data) => {
    const { id, ...content } = data;
    const result = convertToFirestore(content);
    return result;
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    const result = {
      id: snapshot.id,
      ...convertFromFirestore(data)
    };
    return result;
  }
};

function convertToFirestore(value) {
  if (value instanceof Date) {
    return firebase.firestore.Timestamp.fromDate(value);
  } else if (value instanceof Array) {
    return value.map(element => convertToFirestore(element));
  } else if (value instanceof Object) {
    const result = {};
    Object.entries(value).forEach(([key, value]) => {
      if (key && key[0] !== '_') {
        result[key] = convertToFirestore(value);
      }
    });
    return result;
  }
  return value;
}

function convertFromFirestore(value) {
  if (value instanceof firebase.firestore.Timestamp) {
    return value.toDate();
  } else if (value instanceof Array) {
    return value.map(element => convertFromFirestore(element));
  } else if (value instanceof Object) {
    const result = {};
    Object.entries(value).forEach(([key, value]) => {
      if (key && key[0] !== '_') {
        result[key] = convertFromFirestore(value);
      }
    });
    return result;
  }
  return value;
}

export default dataConverter;
