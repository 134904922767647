import React from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useCollection } from '../../api/collection';
import EditableTable from '../commons/EditableTable';
import { createAdminUser, deleteUser } from '../../api/admin/users';

const AdminFilter = { where: ['type', '==', 'admin'], listen: true };

export default function AdminTable() {
  const { t } = useTranslation('admin');
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading } = useCollection('users', AdminFilter);

  const handleError = (error) => {
    enqueueSnackbar(error.message, { variant: 'error' });
    throw error;
  };

  const handleDelete = (oldData) => {
    return new Promise((resolve, reject) => {
      if (window.confirm(t('importantAction'))) {
        return deleteUser(oldData.id)
          .then(resolve)
          .catch(handleError);
      }
      resolve();
    });
  };

  return (
    <EditableTable
      isLoading={loading}
      title={t('administrators')}
      columns={[
        { title: t('table.email'), field: 'email', editable: 'onAdd' },
        { title: t('table.firstname'), field: 'firstname' },
        { title: t('table.lastname'), field: 'lastname' },
        { title: t('table.created'), field: 'created', editable: 'never', type: 'date', searchable: false },
        { title: t('table.updated'), field: 'updated', editable: 'never', type: 'date', searchable: false },
      ]}
      data={data}
      editable={{
        onRowAdd: newData => createAdminUser(newData).catch(handleError),
        onRowDelete: handleDelete,
      }}
    />
  );
}
