import firebase from 'firebase/app';
import 'firebase/storage';
import { useCallback, useEffect, useMemo, useState } from 'react';

export class Storage {

  constructor(path) {
    this.storage = firebase.storage().ref(path);
  }

  setItems(items, urls) {
    if (items) {
      this.items = Object.fromEntries(
        items.map((item, index) => ([item.name, { item, url: urls ? urls[index] : null }]))
      );
    } else {
      this.items = {};
    }
  }

  addFile(filename, file) {
    if (!filename) {
      throw new Error('Invalid filename');
    }
    return this.storage.child(filename).put(file);
  }

  deleteFile(filename) {
    this.storage.child(filename).delete();
  }

  getFileURL(filename) {
    if (this.items && filename in this.items) {
      return this.items[filename].url;
    }
    return null;
  }

}

export function useStorage(path, options) {
  const storage = useMemo(() => new Storage(path), [path]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const synchronize = useCallback(() => {
    setLoading(true);
    setError(null);
    storage.storage.listAll()
      .then(res => {
        if (options?.fetchURL) {
          return Promise.all([
            res.items,
            ...res.items.map(item => item.getDownloadURL())
          ]);
        }
        return Promise.resolve([res.items]);
      })
      .then(res => {
        const [items, ...urls] = res;
        storage.setItems(items, urls);
      })
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [storage, options]);

  useEffect(synchronize, [synchronize]);

  return { storage, loading, error, synchronize };
}
