import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, isWidthUp, makeStyles, Typography, withWidth } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  outerWrapper: {
    display: 'table',
  },
  innerWrapper: {
    padding: '50% 0',
    height: 0,
  },
  timeline: {
    display: 'block',
  },
  timelineHorizontal: {
    transformOrigin: 'top left',
    transform: 'rotate(-90deg) translate(-100%)',
    marginTop: '-50%',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70px',
    height: '70px',
    backgroundColor: blue[500],
    color: 'white',
    borderRadius: '50px',
    padding: '10px',
    transition: 'transform 0.5s',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
  contentHorizontal: {
    transform: 'rotate(90deg)',
    '&:hover': {
      transform: 'rotate(90deg) scale(1.2)',
    }
  },
  contentRight: {
    display: 'flex',
    marginTop: '-25px',
  },
  contentLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-25px',
  },
  connector: {
    height: '80px',
    margin: theme.spacing(1, 0),
  },
  connectorRightText: {
    display: 'flex',
    justifyContent: 'center',
    transform: 'rotate(90deg) translate(20px, -5px)',
    transformOrigin: 'bottom',
    cursor: 'default',
    whiteSpace: 'nowrap',
  },
  connectorLeftText: {
    display: 'flex',
    justifyContent: 'center',
    transform: 'rotate(90deg) translate(20px, 5px)',
    transformOrigin: 'top',
    cursor: 'default',
    whiteSpace: 'nowrap',
  },
  text: {
    cursor: 'default',
  }
}));

function TransformationStep({ values, connectorValues, colors, horizontal, last }) {
  const classes = useStyles();

  const renderText = (index) => {
    const style = colors ? { backgroundColor: colors[index] } : null;
    return (
      <span
        className={clsx([classes.content, horizontal && classes.contentHorizontal])}
        style={style}
      >
        <Typography align="center" variant="caption" className={classes.text}>
          {values[index]}
        </Typography>
      </span>
    );
  };

  const renderConnector = () => {
    if (!last) {
      return (
        <TimelineConnector className={classes.connector}>
          {connectorValues && connectorValues.length > 0 &&
          <Typography variant="caption" color="textSecondary" className={classes.connectorRightText}>{connectorValues[0]}</Typography>}
          {connectorValues && connectorValues.length > 1 &&
          <Typography variant="caption" color="textSecondary" className={classes.connectorLeftText}>{connectorValues[1]}</Typography>}
        </TimelineConnector>
      );
    }
  };

  if (values.length === 1) {
    return (
      <TimelineItem>
        <TimelineSeparator>
          {renderText(0)}
          {renderConnector()}
        </TimelineSeparator>
        <TimelineContent>
        </TimelineContent>
      </TimelineItem>
    );
  } else if (values.length === 2) {
    return (
      <TimelineItem>
        <TimelineOppositeContent className={classes.contentLeft}>
          {renderText(1)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          {renderConnector()}
        </TimelineSeparator>
        <TimelineContent className={classes.contentRight}>
          {renderText(0)}
        </TimelineContent>
      </TimelineItem>
    );
  }
  return null;
}

const SECONDS_IN_MS = 1000;
const MINUTE_IN_MS = SECONDS_IN_MS * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

function getTimeString(t, date) {
  let time = date;
  if (time instanceof Date) {
    time = date.getTime();
  }
  if (typeof time === 'number') {
    if (time > DAY_IN_MS) {
      return t('time.day', { count: Math.round(time / DAY_IN_MS) });
    } else if (time > HOUR_IN_MS) {
      return t('time.hour', { count: Math.round(time / HOUR_IN_MS) });
    } else if (time > MINUTE_IN_MS) {
      return t('time.minute', { count: Math.round(time / MINUTE_IN_MS) });
    } else {
      return t('time.second', { count: Math.round(time / SECONDS_IN_MS) });
    }
  } else if (date) {
    return date.toString()
  }
  return null;
}

function TransformationTunnel({ title, document, name, controller, width }) {
  const classes = useStyles();
  const horizontal = isWidthUp('sm', width);
  const { t } = useTranslation('statistics');
  const [size, setSize] = useState(null);
  const { steps, connectors, colors } = useMemo(() => {
    const data = controller.getChartConfig(name);
    if (document && data) {
      const steps = data.values.map(step =>
        step.map(field =>
          t(field, { count: controller.getFieldValue(document, field) })
        )
      );
      const connectors = data.connectors?.map(connector =>
        connector?.map(field => 
          getTimeString(t, controller.getFieldValue(document, field, null))
        )
      );
      const colors = data.colors ? data.values.map(step =>
        step.map(field => data.colors[field])
      ) : null;
      return { steps, connectors, colors };
    }
    return {};
  }, [controller, document, name, t]);

  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      if (horizontal) {
        const rect = ref.current.getBoundingClientRect();
        setSize([rect.width, rect.height]);

        const observer = new ResizeObserver(entries => {
          if (entries.length) {
            const rect = entries[0].target.getBoundingClientRect();
            setSize([rect.width, rect.height]);
          }
        });
        observer.observe(ref.current);
        return () => observer.disconnect();
      } else {
        setSize(null);
      }
    }
  }, [horizontal]);

  return (
    <Box className={classes.root} width={size && size[0]} height={size && size[1]}>
      {title && <Typography variant="overline" align="center" gutterBottom>{title}</Typography>}
      <div className={classes.outerWrapper}>
        <div className={horizontal ? classes.innerWrapper : null}>
          <Timeline className={clsx([classes.timeline, horizontal && classes.timelineHorizontal])} ref={ref}>
            {steps && steps.map((step, index) =>
              <TransformationStep
                key={index}
                values={step}
                connectorValues={connectors[index]}
                last={index === steps.length - 1}
                colors={colors && colors[index]}
                horizontal={horizontal}
              />
            )}
          </Timeline>
        </div>
      </div>
    </Box>
  );
}

export default withWidth()(TransformationTunnel);
