import React from 'react';
import MaterialTable from 'material-table';
import { icons, localFR } from '../../utils/materialTable';

const defaultOptions = {
  addRowPosition: 'first',
  paging: true,
  pageSize: 10,
  pageSizeOptions: [5, 10, 20, 50],
  draggable: false,
  actionsColumnIndex: -1,
};

function EditableTable({ options, ...props }) {
  const mergedOptions = {...defaultOptions, ...options};
  return (
    <MaterialTable 
      icons={icons}
      localization={localFR}
      options={mergedOptions}
      {...props}
    />
  );
}

export default EditableTable;
