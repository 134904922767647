import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';

function TooltipIconButton({ title, icon, ...props }) {
  return (
    <Tooltip title={title}>
      <IconButton {...props}>
        {icon}
      </IconButton>
    </Tooltip>
  );
}

export default TooltipIconButton;
