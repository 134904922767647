import { Link, makeStyles } from "@material-ui/core";
import { green, orange, red, yellow } from "@material-ui/core/colors";
import { getRelation } from '../../../api/admin/relations';
import languageList from '../../../utils/languages.json'

export function computeMatchDetails({ details, baseDetails }, minimum) {
  let result = [];
  Object.entries(details).forEach(([name, value]) => {
    if (baseDetails[name]) {
      result.push({
        name,
        value: value / baseDetails[name]
      });
    }
  });
  result = result.sort((a, b) => b.value - a.value);
  if (minimum) {
    result = result.filter(item => item.value >= minimum);
  }
  return result;
}

const useStyles = makeStyles((theme) => ({
  fullmatch: {
    backgroundColor: green[400],
    color: theme.palette.getContrastText(green[400]),
  },
  partialmatchhigh: {
    backgroundColor: yellow[400],
    color: theme.palette.getContrastText(yellow[400]),
  },
  partialmatchlow: {
    backgroundColor: orange[400],
    color: theme.palette.getContrastText(orange[400]),
  },
  nomatch: {
    backgroundColor: red[400],
    color: theme.palette.getContrastText(red[400]),
  },
  fullmatch_text: {
    color: green[400],
  },
  partialmatchhigh_text: {
    color: yellow[400],
  },
  partialmatchlow_text: {
    color: orange[400],
  },
  nomatch_text: {
    color: red[400],
  }
}));

export function useMatchColorStyles() {
  const classes = useStyles();
  classes.coloredBackground = (value) => {
    if (typeof value !== 'number') {
      return null;
    }
    if (value >= 1) {
      return classes.fullmatch;
    } else if (value >= 0.5) {
      return classes.partialmatchhigh;
    } else if (value > 0) {
      return classes.partialmatchlow;
    }
    return classes.nomatch;
  };
  classes.coloredText = (value) => {
    if (typeof value !== 'number') {
      return null;
    }
    if (value >= 1) {
      return classes.fullmatch_text;
    } else if (value >= 0.5) {
      return classes.partialmatchhigh_text;
    } else if (value > 0) {
      return classes.partialmatchlow_text;
    }
    return classes.nomatch_text;
  };
  return classes;
}

function getValue(key, data) {
  if (key in data) {
    return data[key];
  }
  return data[`${key}s`];
}

export function formatData(key, data, t) {
  const value = getValue(key, data);

  if (!value && value !== 0) {
    return '';
  }

  const renderValue = (value, fct) => {
    if (value instanceof Array) {
      return value.map(item => fct(item));
    }
    return fct(value);
  };

  const renderAsyncValue = (value, fct) => {
    if (value instanceof Array) {
      const promises = [];
      value.forEach(item => promises.push(fct(item)));
      return Promise.all(promises);
    }
    return fct(value);
  };

  switch (key) {
    case 'expert': case 'proficient': case 'novice': case 'softs': case 'tools':
      return renderAsyncValue(value, v => getRelation('skills', v));
    case 'roles':
      return renderAsyncValue(value, v => getRelation(key, v));
    case 'sector': case 'place':
      return renderAsyncValue(value, v => getRelation(key + 's', v));
    case 'recruiter': case 'contract': case 'jobType':
      return renderValue(value, v => t(`details.${key}.${v}`));
    case 'degree':
      return value instanceof Array
        ? renderAsyncValue(value, v => getRelation('disciplines', v.discipline)
            .then(result => `${t(`details.degree.${v.degree}`)} ${result}`))
        : renderAsyncValue(data.disciplines, v => getRelation('disciplines', v))
            .then(result => `${t(`details.degree.${value}`)} ${result}`);
    case 'duration': 
      const result = renderValue(value, v =>
        t(v === 0 ? 'details.duration_week' : 'details.duration_month', { count: v })
      );
      return result instanceof Array ? result.join(' - ') : result;
    case 'experience':
      return value === 0 ? t('details.noexperience') : t('details.experience', { count: value });
    case 'languages':
      return renderValue(value, v => languageList.find(lang => lang.id === v)?.name);
    case 'lastJobs': 
      return value instanceof Array
        // Candidate
        ? Promise.all(
            value.map(v => Promise.all([
              v.role && getRelation('roles', v.role),
              v.sector && getRelation('sectors', v.sector),
              v.recruiter && Promise.resolve(t(`details.recruiter.${v.recruiter}`))
            ]).then(result => result.join(', ')))
          ).then(result => result.flatMap((line, index) => line ? <div key={index}>{line}</div> : []))
        // Recruiter
        : Promise.all([
            value.roles && renderAsyncValue(value.roles, roles => getRelation('roles', roles)),
            value.sectors && renderAsyncValue(value.sectors, sectors => getRelation('sectors', sectors)),
            value.recruiters && Promise.resolve(renderValue(value.recruiters, v => t(`details.recruiter.${v}`))),
          ]).then(result => result.flatMap((line, index) => line ? <div key={index}>{line.join(', ')}</div> : []));
    case 'salary': 
    case 'adr':
      return `${value} €`;
    case 'freeText':
      return <span style={{ whiteSpace: 'pre-line' }}>{value}</span>;
    case 'linkedIn': case 'website':
      return <Link href={value} target="_blank">{value}</Link>;
    default:
      break;
  }
  if (value instanceof Date) {
    return value.toLocaleDateString();
  }
  return value.toString();
}

function compareValues(candidateValue, recruiterValue) {
  const candidateIndexes = [];
  const recruiterIndexes = [];

  if (candidateValue instanceof Array) {
    if (recruiterValue instanceof Array) {
      candidateValue.forEach((value, cIndex) => {
        const rIndex = recruiterValue.indexOf(value);
        if (rIndex >= 0) {
          candidateIndexes.push(cIndex);
          recruiterIndexes.push(rIndex);
        }
      });
    } else {
      candidateValue.forEach((value, cIndex) => {
        if (value === recruiterValue) {
          candidateIndexes.push(cIndex);
          recruiterIndexes.push(0);
        }
      });
    }
  } else if (recruiterValue instanceof Array) {
    recruiterValue.forEach((value, rIndex) => {
      if (value === candidateValue) {
        candidateIndexes.push(0);
        recruiterIndexes.push(rIndex);
      }
    });
  } else if (candidateValue === recruiterValue) {
    candidateIndexes.push(0);
    recruiterIndexes.push(0);
  }
  return [candidateIndexes, recruiterIndexes];
}

const LINKED_VALUES = ['expert', 'proficient', 'novice'];

export function findCommonIndexes(key, candidate, recruiter) {
  const candidateValue = getValue(key, candidate);
  const recruiterValue = getValue(key, recruiter);

  if (LINKED_VALUES.includes(key)) {
    const linkedKeys = Array.from(LINKED_VALUES).filter(value => value !== key);

    const candidateLinkedValues = [
      ...candidateValue,
      ...linkedKeys.flatMap(linkedKey => getValue(linkedKey, candidate))
    ];
    const recruiterLinkedValues = [
      ...recruiterValue,
      ...linkedKeys.flatMap(linkedKey => getValue(linkedKey, recruiter))
    ];

    const [candidateIndexes, recruiterIndexes] = compareValues(candidateLinkedValues, recruiterLinkedValues);

    return [
      candidateIndexes.filter(index => index < candidateValue.length),
      recruiterIndexes.filter(index => index < recruiterValue.length)
    ];
  }
  return compareValues(candidateValue, recruiterValue);
}
