import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Menu, MenuItem, AppBar, Toolbar, Typography, IconButton, withWidth, Button, Divider } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import LogIn from './LogIn';
import { useProfile, useSession } from '../api/session';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useLocationHash } from '../utils/location';
import IrreversibleConfirm from './commons/IrreversibleConfirm';
import { activateProfile, deactivateProfile } from '../api/users';
import { useConfirm } from './commons/ConfirmDialog';

const useStyles = makeStyles((theme) => {
  const backgroundColor = theme.palette.toolbar || theme.palette.primary.main;

  return ({
    root: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: backgroundColor,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    titleContainer: {
      flexGrow: 1,
      padding: theme.spacing(0, 1),
    },
    title: {
      textDecoration: 'none',
    },
    avatar: {
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      backgroundColor: theme.palette.secondary.main,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    iconText: {
      marginRight: theme.spacing(1),
    },
    contrastText: {
      color: theme.palette.getContrastText(backgroundColor),
    },
    bolder: {
      fontWeight: 500,
    }
  });
});

function DeleteDialog({ open, onClose }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);
  const [error, setError] = React.useState(null);
  const history = useHistory();

  useEffect(() => {
    if (open) {
      setLoading(false);
      setCompleted(false);
      setError(null);
    }
  }, [open]);

  const handleDeleteAccount = () => {
    setError(null);
    setLoading(true);
    firebase.auth().currentUser.delete()
      .then(() => {
        setLoading(false);
        setCompleted(true);
        setTimeout(() => history.push('/'), 0);
      })
      .catch(error => {
        setLoading(false);
        if (error.code === 'auth/requires-recent-login') {
          setError(t(`firebaseError.${error.code}`));
        } else {
          setError(t('delete.unknownError'));
        }
      });
  };

  return (
    <IrreversibleConfirm
      open={open}
      onClose={onClose}
      onConfirm={handleDeleteAccount}
      title={t('delete.title')}
      subTitle={t('delete.subTitle')}
      message={t('delete.message')}
      keyword={t('delete.keyWord')}
      cancelBtn={t('delete.cancel')}
      confirmBtn={t('delete.confirm')}
      closeBtn={t('delete.close')}
      completeMessage={t('delete.succeed')}
      loading={loading}
      completed={completed}
      error={error}
    />
  );
}

function NavBar({ width }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { user, claims } = useSession();
  const { profile, synchronize } = useProfile();
  const { hash } = useLocationHash();
  const [open, setOpen] = React.useState(hash === 'login');
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const desktop = ['xl', 'lg', 'md'].includes(width);
  const [openModal, setOpenModal] = React.useState(false);
  const { ConfirmDialog, confirm } = useConfirm();
  const isAdmin = claims?.admin;

  const openMenu = (e) => {
    setMenuAnchor(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const handleLogOut = () => {
    closeMenu();
    firebase.auth().signOut()
      .then(() => setTimeout(() => history.push('/'), 0));
  };

  const handleOpenDelete = () => {
    closeMenu();
    setOpenModal(true);
  };

  const handleEnableDisable = profile?.type !== 'admin' ? () => {
    closeMenu();
    if (profile) {
      if (profile.disabled === true) {
        activateProfile(profile.id)
          .then(() => synchronize()); 
      } else {
        confirm({ title: t(`disable.${profile.type}Title`), text: t(`disable.${profile.type}Text`) }, confirmed => {
          if (confirmed) {
            deactivateProfile(profile.id)
              .then(() => synchronize());
          }
        });
      }
    }
  } : null;

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar variant="dense" id="top">
        <span className={classes.titleContainer}>
          <Logo variant="h6" className={clsx([classes.title, classes.contrastText])} component={RouterLink} to="/" />
        </span>
        { user ?
          <React.Fragment>
            <IconButton onClick={openMenu} size="small">
              <Avatar className={classes.avatar}>{user.displayName.substr(0, 1)}</Avatar>
            </IconButton>
            <Menu
              id="profil-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={closeMenu}
            >
              <MenuItem onClick={handleLogOut} className={classes.bolder}>{t('login.disconnect')}</MenuItem>
              <Divider variant="middle" />
              { handleEnableDisable && <MenuItem onClick={handleEnableDisable}>{t(profile?.disabled ? 'disable.enable' : 'disable.disable')}</MenuItem> }
              { !isAdmin && <MenuItem onClick={handleOpenDelete}><Typography color="error">{t('login.delete')}</Typography></MenuItem> }
            </Menu>
          </React.Fragment>
          :
          <React.Fragment>
            <Button edge="end" onClick={() => setOpen(true)}>
              {desktop ? <Typography variant="button" className={clsx([classes.iconText, classes.contrastText])}>{t('login.connect')}</Typography> : ''}
              <AccountCircle className={classes.contrastText} />
            </Button>
            <LogIn open={open} onClose={() => setOpen(false)} />
          </React.Fragment>
        }
        <ConfirmDialog cancel={t('disable.cancel')} ok={t('disable.confirm')} />
        <DeleteDialog
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  user: PropTypes.object,
};

export default withWidth()(NavBar);
