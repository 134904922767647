import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, TextField, FormControlLabel, Checkbox, Link, CircularProgress } from '@material-ui/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import analytics from '../api/analytics';
import ModalDialog from './commons/ModalDialog';
import AsyncButton from './commons/AsyncButton';
import RetrievePassword from './RetrievePassword';
import { useTranslation } from 'react-i18next';
import { normalizeEmailAddress } from '../utils/formUtils';
import env from '../env';

const useStyles = makeStyles((theme) => ({
  message: {
    margin: theme.spacing(2, 4, 3),
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2, 4, 3),
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  forgotLink: {
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    cursor: 'pointer',
  }
}));

function LogIn({ open, onClose }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const email = useRef('');
  const password = useRef('');
  const remember = useRef(false);
  const [ error, setError ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ openReinitialize, setOpenReinitialize ] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    firebase.auth().setPersistence(remember.current.checked ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
      .then(function () {
        if (firebase.auth().currentUser !== null) {
          return firebase.auth().signOut();
        }
        return true;
      })
      .then(function () {
        const emailNormalized = normalizeEmailAddress(email.current.value);
        return firebase.auth().signInWithEmailAndPassword(emailNormalized, password.current.value);
      })
      .then(function (credential) {
        const user = credential.user;
        if (user.emailVerified) {
          analytics('login', { method: 'email' });
          setError({});
          onClose();
        } else {
          setError({ confirm: true });
        }
      })
      .catch(function(error) {
        switch (error.code) {
          case 'auth/invalid-email':
          case 'auth/user-disabled':
          case 'auth/user-not-found':            
            setError({ mail: t(`firebaseError.${error.code}`) });
            break;
          case 'auth/wrong-password':
            setError({ password: t(`firebaseError.${error.code}`) });
            break;
          default:
            setError({ message: t('login.error.unknown') });
            break;
        }
      })
      .finally(function () {
        setLoading(false);
      });
  };

  const handleVerificationRequest = () => {
    setError({ loading: true });
    firebase.auth().currentUser.sendEmailVerification({ url: env.actionRedirectURL })
      .then(function () {
        setError({ message: t('login.error.confirmSent') });
      })
      .catch(function (error) {
        console.error(error.message);
        setError({ message: t('login.error.unknown') });
      });
  };

  const handleCloseReinitialize = () => {
    setOpenReinitialize(false);
    setError({});
    onClose();
  };

  return (
    <React.Fragment>
      <ModalDialog open={open && !openReinitialize} onClose={onClose}>
        <Typography component="h1" variant="h4" gutterBottom>
          {t('login.title')}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('login.email')}
            name="email"
            autoComplete="email"
            autoFocus
            inputRef={email}
            error={error.mail && error.mail.length > 0}
            helperText={error.mail}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t('login.password')}
            type="password"
            id="password"
            autoComplete="current-password"
            inputRef={password}
            error={error.password && error.password.length > 0}
            helperText={error.password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" inputRef={remember} />}
            label={t('login.remember')}
          />
          <div className={error.loading ? classes.loadingContainer : classes.message}>
          {
            error.confirm ?
              <div>
                <Typography color="error" variant="body2">{t('login.error.confirm')}</Typography>
                <Link color="error" variant="body2" className={classes.link} gutterBottom onClick={handleVerificationRequest}>{t('login.error.confirmAction')}</Link>
              </div>
            : error.loading ?
              <CircularProgress color="primary" size={24} />
            : error.message ?
              <Typography variant="body2">{error.message}</Typography>
            : ''
          }
          </div>
          <AsyncButton type="submit" loading={loading} fullWidth>{t('login.connect')}</AsyncButton>
          <Typography className={classes.forgotLink} variant="body2">
            <Link onClick={() => setOpenReinitialize(true)}>{t('login.forgotten')}</Link>
          </Typography>
        </form>
      </ModalDialog>
      <RetrievePassword open={openReinitialize} onClose={handleCloseReinitialize} />
    </React.Fragment>
  );
}

LogIn.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default LogIn;
