import React from 'react';
import { Typography } from '@material-ui/core';
import DOMPurify from 'dompurify';
import HandleBars from 'handlebars';

const FAKE_URL = 'http://localhost:3000/action/example-action-url';

const FAKE_ACTIONS = {
  link: FAKE_URL,
  actions: {
    validate: FAKE_URL,
    cancel: FAKE_URL,
    unsubscribe: FAKE_URL,
  },
  copyright: '@ Design Link ' + new Date().getFullYear()
};

export default function HTMLPreview({ html, user, data }) {
  const [content, setContent] = React.useState('');
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setError(null);
      let preview = html;
      if (user) {
        try {
          const template = HandleBars.compile(preview);
          if (template) {
            preview = template({ ...user, profile: data, ...FAKE_ACTIONS });
          }
        } catch (e) {
          let message = e.message.split('\n')[0];
          if (message.endsWith(':')) {
            message = message.substring(0, message.length - 1);
          }
          setError(message);
        }
      }
      setContent(DOMPurify.sanitize(preview));
    }, 700);

    return () => clearTimeout(timeout);
  }, [html, user, data]); 

  return (
    <React.Fragment>
      { error
      ? <Typography component="pre" variant="body2" color="error" gutterBottom>{error}</Typography>
      : <span dangerouslySetInnerHTML={{__html: content }} />
      }
    </React.Fragment>
  );
}
