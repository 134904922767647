import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

function RevealCascade({ Component, children, delay = 200, ...props }) {
  return (
    <React.Fragment>
      {children.map((child, index) =>
        <Component key={index} delay={delay * index} {...props}>
          {child}
        </Component>
      )}
    </React.Fragment>
  );
}

RevealCascade.propTypes = {
  Component: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  ...Fade.propTypes,
};

export default RevealCascade;
