import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, makeStyles, Typography } from '@material-ui/core';
import HistoryDialog from '../commons/HistoryDialog';
import EmailPreview from '../EmailPreview';
import FirebaseTable from './core/FirebaseTable';
import UserName from './UserName';
import { Delete, Refresh } from '@material-ui/icons';
import TooltipIconButton from '../commons/TooltipIconButton';
import { deleteEmail, retryEmail } from '../../api/admin/email';

const WHERE = ['delivery.state', 'in', ['ERROR', 'RETRY']];
const SORT = ['delivery.startTime', 'desc'];

const useStyles = makeStyles({
  errorMessage: {
    lineHeight: '12px',
  }
});

function EmailActions(rowData) {
  const { t } = useTranslation('admin');

  if (rowData?.delivery.state === 'ERROR') {
    const handleRetry = () => {
      retryEmail(rowData.id);
    };
    const handleDelete = () => {
      deleteEmail(rowData.id);
    };
    return (
      <Fragment>
        <TooltipIconButton title={t('action.retryEmail')} icon={<Refresh />} size="small" onClick={handleRetry} />
        <TooltipIconButton title={t('action.deleteEmail')} icon={<Delete />} size="small" onClick={handleDelete} />
      </Fragment>
    );
  }
  return null;
}

function EmailState(rowData) {
  const state = rowData.delivery.state;
  return (
    <Typography color={state === 'ERROR' ? "error" : "primary"}>{state}</Typography>
  );
}

function ErrorTable(props) {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const [emailId, setEmailId] = useState(null);
  const columnDef = useMemo(() => {
    const handleEmail = (rowData) => (event) => {
      event.preventDefault();
      setEmailId(rowData.id);
    };
    return [
      { title: t('table.displayName'), field: 'toUids', sort: false, width: 150,
        render: rowData => <UserName uid={rowData.toUids[0]} link /> },
      { title: t('table.email'), field: 'template.name', sort: false, width: 150,
        render: rowData => <Link href="#" onClick={handleEmail(rowData)}>{t(`email.${rowData.template?.name}`)}</Link> },
      { title: t('table.error'), field: 'delivery.error', sort: false, minWidth: 300,
        render: rowData => <Typography title={rowData.delivery.error} color="error" variant="caption" className={classes.errorMessage}>{rowData.delivery.error}</Typography> },
      { title: t('table.attempts'), field: 'delivery.attempts', sort: false, align: 'center', width: 100 },
      { title: t('table.state'), field: 'delivery.state', sort: false, align: 'center', width: 100, render: EmailState },
      { title: t('table.created'), field: 'delivery.startTime', sort: false, width: 130 },
      { title: t('table.updated'), field: 'delivery.endTime', sort: false, width: 130 },
      { title: t('table.actions'), render: EmailActions, width: 120 },
    ];
  }, [t, classes]);

  const handleClose = () => {
    setEmailId(null);
  };

  return (
    <Fragment>
      <FirebaseTable
        collection="mail"
        columns={columnDef}
        where={WHERE}
        sort={SORT}
        {...props}
      />
      <HistoryDialog
        fullWidth
        maxWidth="md"
        fullscreen="sm"
        name="email"
        open={Boolean(emailId)}
        onClose={handleClose}
      >
        <EmailPreview id={emailId} />
      </HistoryDialog>
    </Fragment>
  );
}

export default ErrorTable;
