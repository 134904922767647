import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import candidate from '../themes/candidate';
import recruiter from '../themes/recruiter';

const useStyles = makeStyles((theme) => {
  const fontFamily = '"Myriad Pro Bold", "Roboto", "Helvetica", "Arial", sans-serif';
  let before = candidate.palette.primary.main;
  let after = recruiter.palette.primary.main;

  if (theme.palette.primary.main === before
    || theme.palette.primary.main === after) {
    before = theme.palette.common.white;
    after = theme.palette.common.white;
  }

  return ({
    design: {
      fontFamily,
      marginRight: 4,
    },
    link: {
      fontFamily,
      '&::before': {
        fontFamily,
        content: '"["',
        color: before,
        marginRight: '2px',
      },
      '&::after': {
        fontFamily,
        content: '"]"',
        color: after,
        marginLeft: '2px',
      }
    }
  });
});

function Logo({ text = "Design Link", ...props}) {
  const classes = useStyles();
  const [design, link] = text.split(' ');

  return (
    <Typography {...props}>
      <span className={classes.design}>{design}</span><span className={classes.link}>{link}</span>
    </Typography>
  );
}

export default Logo;
