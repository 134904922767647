import { Box, LinearProgress, makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import clsx from 'clsx';
import React, { useMemo } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    lineHeight: '24px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  percentText: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    zIndex: 100,
    cursor: 'default',
  }
}));

const ChartTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.8)',
  }
}))(Tooltip);

const ProgressChart = withStyles(theme => ({
  root: {
    height: '30px',
    width: '100%',
    margin: theme.spacing(2)
  },
  colorPrimary: {
    backgroundColor: grey[100],
  },
  bar: {
    backgroundColor: blue[500],
  },
}))(LinearProgress);

function Ratio({ className, title, document, controller, name }) {
  const classes = useStyles();
  const { percent, value, max } = useMemo(() => {
    const data = controller.getChartConfig(name);
    if (document && data) {
      let value = controller.getFieldValue(document, data.value);
      const max = controller.getFieldValue(document, data.max);
      if (data.filter_value) {
        value = data.filter_value(value);
      }
      if (value !== undefined && max !== undefined) {
        return {
          percent: Math.round(value * 100 / max),
          value,
          max
        };
      }
    }
    return { percent: 0, value: 0, max: 0 };
  }, [document, controller, name]);

  return (
    <Box className={clsx([classes.root, className])} width={300}>
      {title && <Typography className={classes.title} variant="overline" align="center" gutterBottom>{title}</Typography>}
      <ChartTooltip title={`${value} / ${max}`} arrow>
        <div className={classes.content}>
          <ProgressChart variant="determinate" value={percent} />
          <Typography className={classes.percentText} variant="body2" color="textSecondary">{`${percent} %`}</Typography>
        </div>
      </ChartTooltip>
    </Box>
  );
}

export default Ratio;
