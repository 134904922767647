import React, { Fragment, useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, CardHeader, CircularProgress, Fab, makeStyles, Typography } from '@material-ui/core';
import { getDocument } from '../api/document';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { Add, Delete, Edit, Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';
import TooltipIconButton from './commons/TooltipIconButton';
import UserMatches from './UserSheet/UserMatches';

const USE_CACHE = { cache: true };

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    position: 'relative',
    width: '100%',
    margin: theme.spacing(2, 2, 0),
  },
  cardDisabled: {
    backgroundColor: theme.palette.grey[300],
  },
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflowX: 'auto',
    padding: theme.spacing(0, 2, 1),
  },
  jobDetails: {
    flex: 1,
    minWidth: 250,
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
  jobMatches: {
    flex: 2,
    minWidth: 250,
    margin: theme.spacing(1, 0),
  },
  bold: {
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  button: {
    marginLeft: 'auto'
  },
  add: {
    margin: theme.spacing(4),
  },
  backdrop: {
    position: 'absolute',
    zIndex: 1,
  }
}));

const getTextOptions = (value) =>
  value instanceof Date ? { date: value.toLocaleDateString() } :
  !isNaN(value) ? { count: value, context: `${value}` } :
  { text: value } ;

const getJobSubtitle = (t, { contract, jobType, place }) => {
  let subtitle = t(`contract-short.${contract}`);
  if (!place && jobType === 'remote') {
    subtitle += ` - ${t('jobType.remote')}`;
  }
  return subtitle;
};

function JobCard({ profile, data, onShow, onEnableDisable, onDelete }) {
  const { id, roles, contract, disabled, place } = data;
  const classes = useStyles();
  const { t } = useTranslation();
  const [title, setTitle] = useState(null);
  const [subtitle, setSubTitle] = useState(getJobSubtitle(t, data));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (roles) {
      Promise.all(roles.map(role => getDocument(role, 'roles', null, USE_CACHE)))
        .then(results => setTitle(results.map(role => role.get('name')).join(', ')));
    }
    if (place) {
      getDocument(place, 'places', null, USE_CACHE)
        .then(result => setSubTitle(getJobSubtitle(t, data) + ` - ${result.get('name')}`));
    }
  }, [roles, place, t, data]);

  const handleEnableDisable = (enable) => () => {
    if (onEnableDisable) {
      setLoading(true);
      onEnableDisable(data, enable)
        .finally(() => setLoading(false));
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      setLoading(true);
      onDelete(data).finally(() => setLoading(false));
    }
  };

  function renderData(name, className) {
    const value = data[name];
    if (value !== undefined && value !== '') {
      return (
        <Typography variant="body2" className={className}>
          {t(`job.${name}`, getTextOptions(value))}
        </Typography>
      );
    }
    return null;
  }

  function renderEnableDisable() {
    if (profile?.disabled) {
      return null;
    }
    return disabled
      ? <TooltipIconButton title={t('job.enable')} icon={<Visibility />} onClick={handleEnableDisable(true)} />
      : <TooltipIconButton title={t('job.disable')} icon={<VisibilityOff />} onClick={handleEnableDisable(false)} />
    ;
  }
  
  return (
    <Card id={id} className={clsx([classes.card, (disabled || profile?.disabled) && classes.cardDisabled])}>
      <CardHeader
        title={title || <Skeleton width={300} />}
        subheader={subtitle}
        action={
          <Fragment>
            {onShow && <TooltipIconButton title={t('job.edit')} icon={<Edit />} onClick={onShow.bind(null, data)} />}
            {renderEnableDisable()}
            <TooltipIconButton title={t('job.delete')} icon={<Delete />} onClick={handleDelete} />
          </Fragment>
        }
      />
      <CardContent className={classes.cardContent}>
        <div className={classes.jobDetails}>
          {renderData('company', classes.bold)}
          {renderData('start')}
          {contract !== 'cdi' && renderData('duration')}
          {renderData('experience')}
          {renderData('salary')}
          {renderData('adr')}
        </div>
        <UserMatches profile={profile} jobId={id} component="div" disabled={profile?.disabled || disabled} className={classes.jobMatches} />
      </CardContent>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
}

function AddButton({ onClick, className }) {
  return (
    <Fab color="primary" onClick={onClick} className={className}>
      <Add />
    </Fab>
  );
}

function JobList({ profile, onShow, onNew, onEnableDisable, onDelete, max }) {
  const classes = useStyles();
  const jobEntries = profile.jobs ? Object.entries(profile.jobs) : [];
  return (
    <div className={classes.root}>
      {jobEntries.map(([id, job]) => (
        <JobCard
          key={id}
          profile={profile}
          data={job}
          onShow={onShow}
          onEnableDisable={onEnableDisable}
          onDelete={onDelete}
        />
      ))}
      {!profile.disabled && (!max || jobEntries.length < max) && <AddButton onClick={onNew} className={classes.add} />}
    </div>
  );
}

export default JobList;
