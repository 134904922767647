import { amber, blue, brown, deepPurple, green, indigo, lime, orange, pink, red, teal, yellow } from "@material-ui/core/colors";

const statistics = {
  charts: {
    users: {
      labels: ['candidates', 'recruiters'],
      datasets: [
        ['candidates_active', 'recruiters_active'],
        ['candidates_disabled', 'recruiters_disabled'],
      ],
      colors: [
        [blue[500], red[500]],
        [blue[200], red[200]]
      ],
      defaultType: 'horizontalBar',
      types: ['horizontalBar', 'bar'],
      options: { stacked: true }
    },
    jobs: {
      datasets: [['jobs_active', 'jobs_disabled']],
      colors: [[blue[500], blue[200]]],
      defaultType: 'doughnut'
    },
    matches_tunnel: {
      type: 'tunnel',
      values: [
        ['matches_new'],
        ['matches_pending', 'matches_declined'],
        ['matches_discussion'],
        ['matches_succeeded', 'matches_failed']
      ],
      connectors: [
        ['matches_time_validated', 'matches_time_declined'],
        ['matches_time_discussion'],
        ['matches_time_succeeded', 'matches_time_failed']
      ],
      colors: {
        matches_new: blue[200],
        matches_pending: blue[500],
        matches_declined: red[500],
        matches_discussion: blue[900],
        matches_succeeded: green[500],
        matches_failed: red[900],
        matches_canceled: red[500],
      }
    },
    matches: {
      datasets: [[
        'matches_new',
        'matches_pending',
        'matches_discussion',
        'matches_succeeded',
        'matches_failed',
        // 'matches_canceled'
      ]],
      colors: [[blue[200], blue[500], blue[900], green[500], red[500], red[900]]],
    },
    candidates_contracts: ['candidates_contracts'],
    candidates_experience: {
      datasets: ['candidates_experience'],
      defaultType: 'bar',
      sort: false,
    },
    candidates_roles: {
      datasets: ['candidates_roles'],
      limit: 5,
      defaultType: 'horizontalBar',
      collection: 'roles',
    },
    candidates_places: {
      datasets: ['candidates_places'],
      limit: 10,
      defaultType: 'bar',
      collection: 'places',
    },
    candidates_sectors: {
      datasets: ['candidates_sectors'],
      limit: 5,
      collection: 'sectors',
    },
    candidates_inpost_ratio: {
      value: 'candidates_inpost',
      max: 'candidates',
      type: 'ratio',
    },
    recruiters_contracts: ['recruiters_contracts'],
    recruiters_experience: {
      datasets: ['recruiters_experience'],
      defaultType: 'bar',
      sort: false,
    },
    recruiters_roles: {
      datasets: ['recruiters_roles'],
      limit: 5,
      defaultType: 'horizontalBar',
      collection: 'roles',
    },
    recruiters_places: {
      datasets: ['recruiters_places'],
      limit: 10,
      defaultType: 'bar',
      collection: 'places',
    },
    recruiters_sectors: {
      datasets: ['recruiters_sectors'],
      limit: 5,
      collection: 'sectors',
    },
    recruiters_multijobs_ratio: {
      value: 'recruiter_jobs',
      max: 'recruiters',
      type: 'ratio',
      filter_value: (value) => Object.entries(value)
          .reduce((result, [jobs, count]) => result + (jobs > 1 ? count : 0), 0),
    }
  },
  defaultColors: [
    blue[500],
    red[500],
    green[500],
    deepPurple[500],
    teal[500],
    orange[500],
    lime[500],
    amber[500],
    brown[500],
    pink[500],
    indigo[500],
    yellow[500],
  ],
};

export default statistics;
