import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, IconButton, Typography, Button, makeStyles } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { connectToForm } from '../../contexts/FormContext';
import ModalDialog from '../commons/ModalDialog';
import Tags from '../commons/Tags';

const useStyles = makeStyles((theme) => ({
  container: {
    marginRight: "-30px",
  },
  item: {
    marginTop: theme.spacing(2),
  }
}));

function valueToData(value) {
  if (value) {
    return value.map(item => ({ id: item, name: item }));
  }
  return [];
}

const ProposeInput = connectToForm(React.memo(({ id, title, tooltip, name, value, dispatch }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOpen = () => {
    window.location.hash = `#${id}`;
  };

  const handleClose = () => {
    window.history.back();
  };

  const handleChange = (value) => {
    dispatch({ type: 'change', name, value });
  };

  return (
    <span className={classes.container}>
      <Tooltip title={tooltip}>
        <IconButton color="secondary" size="small" onClick={handleOpen}>
          <HelpOutline />
        </IconButton>
      </Tooltip>
      <ModalDialog hash={id} onClose={handleClose}>
        <Typography variant="h5" gutterBottom>{title}</Typography>
        <Tags
          id={id}
          value={value || []}
          onChange={handleChange}
          data={valueToData(value)}
          freeSolo
          color="secondary"
          className={classes.item}
          limit={5}
          autoSelect
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          className={classes.item}
        >{t('navigation.close')}</Button>
      </ModalDialog>
    </span>
  );
}));

ProposeInput.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ProposeInput;
