import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserName from '../UserName';
import { updateMatch, deleteMatch, MatchStatus } from '../../../api/admin/match';
import { CircularProgress, IconButton, makeStyles, Paper, Popper, Tooltip } from '@material-ui/core';
import FirebaseTable from '../core/FirebaseTable';
import { useSnackbar } from 'notistack';
import { Clear, Delete, Done, Pageview, TimerOff } from '@material-ui/icons';
import MatchRatio from './MatchRatio';
import MatchDetails from './MatchDetails';
import TabPanel from '../TabPanel';
import MatchEmails from './MatchEmails';
import HistoryDialog from '../../commons/HistoryDialog';
import { useDateFilter } from '../core/DateFilter';
import TooltipIconButton from '../../commons/TooltipIconButton';

const DEFAULT_SORT = ['created', 'desc'];

const useStyles = makeStyles((theme) => ({
  new: {
    color: theme.palette.info.light
  },
  pending: {
    color: theme.palette.info.main
  },
  discussion: {
    color: theme.palette.info.dark
  },
  succeeded: {
    color: theme.palette.success.main
  },
  canceled: {
    color: theme.palette.error.light
  },
  declined: {
    color: theme.palette.error.main
  },
  failed: {
    color: theme.palette.error.dark
  },
  matchCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  }
}));

function getStatusNames(t) {
  const names = {};
  Object.keys(MatchStatus).forEach(key => {
    names[key] = t(`match.${key}`);
  });
  return names;
}

function Symbol({ value, tooltip, expired }) {
  const classes = useStyles();

  const renderContent = () => {
    if (value === true) {
      return <span className={classes.succeeded}> ✔</span>;
    } else if (value === false && expired === true) {
      return <span className={classes.declined}> <TimerOff fontSize="inherit" /></span>
    } else if (value === false) {
      return <span className={classes.declined}> ✖</span>;
    }
    return <span> ?</span>;
  };

  return (
    <Tooltip title={tooltip}>
      {renderContent()}
    </Tooltip>
  );
}

const SHOW_SUCCEED = [MatchStatus.discussion, MatchStatus.succeeded, MatchStatus.failed];

function Status({ data }) {
  const classes = useStyles();
  const { t } = useTranslation('admin');

  const renderSymbols = () => {
    const responses = SHOW_SUCCEED.includes(data.status) ? data.succeed : data.validate;
    if (responses) {
      const { candidate, recruiter } = responses;
      return (
        <>
          <Symbol value={candidate} tooltip={t('candidate')} expired={data.expired?.candidate} />
          <Symbol value={recruiter} tooltip={t('recruiter')} expired={data.expired?.recruiter} />
        </>
      );
    }
  };

  return (
    <span>
      <span className={classes[data.status]}>{t(`match.${data.status}`)}</span>
      { renderSymbols() }
    </span>
  );
}

function MatchActions(rowData) {
  const { t } = useTranslation('admin');
  const { enqueueSnackbar } = useSnackbar();

  if (!rowData) { return null; }

  if (rowData.metadata?.hasPendingWrites) {
    return <CircularProgress size={24} />;
  }

  const handleError = (error) => {
    enqueueSnackbar(error.message, { variant: 'error' });
    throw error;
  };

  const handleDelete = () => {
    return new Promise((resolve, reject) => {
      if (window.confirm(t('importantAction'))) {
        return deleteMatch(rowData.id)
          .then(resolve)
          .catch(handleError);
      }
      resolve();
    });
  };

  const handleChangeStatus = (newStatus, validate) => () => {
    updateMatch(rowData.id, newStatus)
      .then(() => validate
        ? enqueueSnackbar(t('action.matchValidated'), { variant: 'success' })
        : enqueueSnackbar(t('action.matchCanceled'), { variant: 'info' })
      )
      .catch(handleError);
  };

  return <>
    { rowData.status === 'new' &&
      <Fragment>
        <TooltipIconButton title={t('action.validate')} icon={<Done />} size="small" onClick={handleChangeStatus(MatchStatus.validated, true)} />
        <TooltipIconButton title={t('action.cancel')} icon={<Clear />} size="small" onClick={handleChangeStatus(MatchStatus.canceled, false)} />
      </Fragment>
    }
    { rowData.status === 'discussion' &&
      <Fragment>
        <TooltipIconButton title={t('match.succeeded')} icon={<Done />} size="small" onClick={handleChangeStatus(MatchStatus.succeeded, true)} />
        <TooltipIconButton title={t('match.failed')} icon={<Clear />} size="small" onClick={handleChangeStatus(MatchStatus.failed, false)} />
      </Fragment>
    }
    <TooltipIconButton title={t('action.clear')} icon={<Delete />} size="small" onClick={handleDelete} />
  </>;
}

function MatchTable({ search, state, columns, ...props }) {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const [popover, setPopover] = useState(null);
  const [details, setDetails] = useState(null);
  const { DateFilter, openFilter, dateFilters, isActiveFilter } = useDateFilter();
  const where = useMemo(() => state ? [['status', '==', state], ...(dateFilters || [])] : dateFilters, [state, dateFilters]);

  const openMatchDetails = useCallback((rowData) => {
    setDetails(rowData);
  }, []);

  const closeMatchDetails = () => {
    setDetails(null);
  };

  const renderMatching = useCallback(rowData => {
    const handlePopover = (newPopover) => {
      setPopover(newPopover);
    };
    return (
      <div className={classes.matchCell}>
        <IconButton size="small" onClick={() => openMatchDetails(rowData)}><Pageview /></IconButton>
        <MatchRatio details={rowData.context} onPopover={handlePopover} />
        <MatchRatio details={rowData.profile} onPopover={handlePopover} />
      </div>
    );
  }, [openMatchDetails, classes.matchCell]);

  const columnDef = useMemo(() => {
    let def = [
      { title: t('table.matching'), width: 140, align: 'center', render: renderMatching },
      { title: t('table.candidate'), field: 'candidate', sort: false, minWidth: 150,
        render: rowData => <UserName uid={rowData.candidate} link /> },
      { title: t('table.recruiter'), field: 'recruiter', sort: false, minWidth: 150,
        render: rowData => <UserName uid={rowData.recruiter} options={{ job: rowData.job }} link /> },
      { title: t('match.status'), field: 'status', width: 130, lookup: getStatusNames(t),
        render: rowData => <Status data={rowData} /> },
      { title: t('table.created'), field: 'created', width: 130, filter: openFilter, active: isActiveFilter('created') },
      { title: t('table.updated'), field: 'updated', width: 150, filter: openFilter, active: isActiveFilter('updated') },
      { title: t('table.actions'), render: MatchActions, width: 120 },
    ];
    
    if (columns) {
      def = def.filter(colDef => !colDef.field || columns.includes(colDef.field.split('.')[0]));
    }
    return def;
  }, [t, columns, renderMatching, openFilter, isActiveFilter]);

  return <>
    <FirebaseTable
      collection="match"
      columns={columnDef}
      search={search}
      where={where}
      sort={DEFAULT_SORT}
      onDoubleClick={openMatchDetails}
      {...props}
    />
    <Popper
      open={Boolean(popover)}
      anchorEl={popover?.element}
      placement="bottom-start"
    >
      <Paper>
        {popover?.content}
      </Paper>
    </Popper>
    <HistoryDialog
      name="match-details"
      open={Boolean(details)}
      onClose={closeMatchDetails}
      maxWidth="md"
      fullscreen="sm"
      fullWidth
    >
      <TabPanel sticky tabs={[
        { name: 'details', label: 'details.details', content: <MatchDetails match={details} /> },
        { name: 'emails', label: 'details.emails', disabled: !details?.emails, content:
          <MatchEmails
            emails={details?.emails}
            candidateId={details?.candidate}
            recruiterId={details?.recruiter}
          />
        },
      ]} />
    </HistoryDialog>
    <DateFilter />
  </>;
}

export default MatchTable;
