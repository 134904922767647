import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Tabs, Tab, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  tabBar: {
    marginBottom: theme.spacing(2),
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
  },
  select: {
    paddingBottom: theme.spacing(2),
  }
}));

function TabContent({ name, current, children, ...other }) {
  return (
    <div
      hidden={name !== current}
      id={`tabcontent-${name}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabContent.propTypes = {
  name: PropTypes.string.isRequired,
  current: PropTypes.string.isRequired,
};


function TabPanel({ tabs, sticky }) {
  const classes = useStyles();
  const { t } = useTranslation('admin');
  const [current, setCurrent] = React.useState(tabs[0].name);

  const handleChange = (event, newValue) => {
    setCurrent(newValue);
  };
  
  return (
    <div>
      <Paper className={clsx([classes.tabBar, sticky && classes.sticky])}>
        <Tabs value={current} onChange={handleChange} variant="fullWidth" indicatorColor="primary">
          { tabs.map(tab =>
            <Tab
              key={tab.name}
              id={tab.name}
              value={tab.name}
              label={t(tab.label)}
              disabled={tab.disabled}
            />
          )}
        </Tabs>
      </Paper>
      { tabs.map(tab => <TabContent key={tab.name} name={tab.name} current={current}>{tab.content}</TabContent>) }
    </div>
  );
}

TabPanel.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default TabPanel;
