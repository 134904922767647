import React, { forwardRef } from 'react';
import { AddBox, Check, Clear, ChevronRight, Edit, SaveAlt, FilterList, FirstPage, LastPage, ChevronLeft, Search, ArrowDownward, Remove, ViewColumn, Delete } from '@material-ui/icons';

export const icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const localFR = {
  body: {
    emptyDataSourceMessage: 'Aucune donnée',
    addTooltip: 'Ajouter',
    deleteTooltip: 'Supprimer',
    editTooltip: 'Editer',
    filterRow: {
      filterTooltip: 'Filtrer',
    },
    editRow: {
      deleteText: 'Confirmer la suppression ?',
      cancelTooltip: 'Annuler',
      saveTooltip: 'Accepter',
    },
  },
  grouping: {
    placeholder: 'Drag headers ...',
  },
  header: {
    actions: 'Actions',
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} sur {count}',
    labelRowsSelect: 'lignes',
    labelRowsPerPage: 'Lignes par page:',
    firstAriaLabel: 'Première page',
    firstTooltip: 'Première page',
    previousAriaLabel: 'Page précédente',
    previousTooltip: 'Page précédente',
    nextAriaLabel: 'Page suivante',
    nextTooltip: 'Page suivante',
    lastAriaLabel: 'Dernière page',
    lastTooltip: 'Dernière page',
  },
  toolbar: {
    addRemoveColumns: 'Ajouter ou retirer des colonnes',
    nRowsSelected: '{0} ligne(s) selectionnée(s)',
    showColumnsTitle: 'Afficher les colonnes',
    showColumnsAriaLabel: 'Afficher les colonnes',
    exportTitle: 'Exporter',
    exportAriaLabel: 'Exporter',
    exportName: 'Exporter en tant que CSV',
    searchTooltip: 'Rechercher',
    searchPlaceholder: 'Rechercher',
  }
};
