import firebase from 'firebase/app';
import 'firebase/firestore';

const cache = {};

export function getRelation(collection, name) {
  if (!name) {
    return Promise.reject(`Missing name for collection ${collection}`);
  }
  const cacheId = `${collection}-${name}`;
  if (cacheId in cache) {
    return Promise.resolve(cache[cacheId]);
  }
  return firebase.firestore().collection(collection).doc(name).get().then(snap => {
    const value = snap.exists ? snap.get('name') : null;
    cache[cacheId] = value;
    return value;
  });
}
