import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  text: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  icon: {
    marginLeft: '5px',
  },
  button: {
    marginTop: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
}));

function NotFound() {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <Typography variant="h2" gutterBottom>Oups !</Typography>
      <Typography color="textSecondary" className={classes.text}>
        {'Il semble que cette page n\'existe pas'}
        <SentimentDissatisfiedIcon fontSize="small" className={classes.icon}/>
      </Typography>
      <div>
        <Button variant="outlined" color="primary" component={RouterLink} to="/" className={classes.button}>Retourner à l'accueil</Button>
      </div>
    </Paper>
  );
}

export default NotFound;
