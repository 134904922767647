import React from 'react';
import PropType from 'prop-types';
import { Bar } from 'react-chartjs-2';

const BAR_OPTIONS = {
  maintainAspectRatio: false,
  legend: {
    display: false,
    labels: {
      boxWidth: 15,
    },
  },
  tooltips: {
    mode: 'nearest',
    intersect: false,
    callbacks: {
      labelColor: (item, chart) => {
        // User border color has box color for tooltips
        const color = chart.data.datasets[item.datasetIndex].borderColor;
        return {
          borderColor: color,
          backgroundColor: color,
        };
      },
    }
  },
  scales: {
    xAxes: [{
      ticks: {
        callback: value =>
          new Date(value).toLocaleDateString('fr-FR', { day: 'numeric', month: 'short' }),
      },
    }],
  },
  plugins: {
    labels: {
      render: 'value',
      overlap: false,
    },
  },
};

function HistoryBar({ data, ...props }) {
  return (
    <Bar
      data={data}
      options={BAR_OPTIONS}
      {...props}
    />
  );
}

HistoryBar.propTypes = {
  data: PropType.object.isRequired,
};

export default HistoryBar;
