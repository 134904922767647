import React from 'react';
import { CircularProgress, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core';
import { Clear, Search } from '@material-ui/icons';

function SearchInput({ label, onSearch, loading, helperText }) {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClear = () => {
    setValue('');
    onSearch && onSearch('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch && onSearch(value);
  };

  return (
    <FormControl fullWidth variant="outlined">
      <form onSubmit={handleSubmit} noValidate>
        <InputLabel htmlFor="search">{label}</InputLabel>
        <OutlinedInput
          id="search"
          autoComplete="search"
          margin="dense"
          value={value}
          onChange={handleChange}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start" type="submit">
                { loading
                  ? <CircularProgress size={20} />
                  : <Search />
                }
              </IconButton>
            </InputAdornment>
          }
          endAdornment={ value &&
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClear} size="small">
                <Clear />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={80}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </form>
    </FormControl>
  );
}

export default SearchInput;
