import firebase from 'firebase/app';
import 'firebase/firestore';

export function retryEmail(id) {
  return firebase.firestore().collection('mail').doc(id).update({ 'delivery.state': 'RETRY' });
}

export function deleteEmail(id) {
  return firebase.firestore().collection('mail').doc(id).delete();
}
